import React from 'react';
import AboutImages from '../../Assets/Store/shop.png';
import '../../Styles/footerpolicy.css';

const PrivacyPolicydetails = () => {
  return (
    <>
      <div className="home-page">
        <div className="vectorbanner">
          <div className="qvrow">
            <div className="col-qv-12">
              <div className="vectorbannerinnerR">
                <h3>Privacy Policy</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="home-page-all-sections">
          <div className="pricacy_div">
            <div>This Privacy Policy was last modified on April 6, 2022.</div>

            <p>
              This website (Quickvee.com) is operated by Merchantech, Inc.
              (“Merchantech, Quickvee, “we,” “us,” or “our”) has created this
              privacy policy (“Privacy Policy”) to advise you on how we share
              information you provide to us and how it’s used
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of Personal Information we receive from users.
            </p>
            <p>
              We use your personal information for providing and improving our
              site and apps. By using the App and Site, you agree to the
              collection and use of information in accordance with this policy.
            </p>
          </div>
          <div className="pricacy_div">
            <h1>Information Collection and Use</h1>
            <p>
              While using our site and apps, we may ask you to provide us with
              certain personally identifiable information that can be used to
              contact or identify you. Personally identifiable information may
              include, but is not limited to, your name, email address, postal
              address and phone number ("Personal Information").
            </p>
            <p>
              We may share this information with trusted partners, to help
              ensure proper marketing activities, and statistical analysis.
              Merchantech reserves the right to disclose such information, if
              required pursuant to a statute, a subpoena or other court order.
              In the event that Merchantech is required to disclose this
              information by law enforcement, Merchantech will use commercially
              reasonable efforts to provide you with written notice of the
              information requested, including the name of the party requesting
              the info ( if permissible by law), and provide the opportunity to
              challenge the disclosure. With respect to demographic information
              (gender, date of birth, etc.) and social media profile
              information, will be used for security purposes. This is to ensure
              the identity of the user.
            </p>
          </div>

          <div className="pricacy_div">
            <h1>The Information we collect may include the following:</h1>
            <ol>
              <li>
                <b>
                  Your name and the name of your company (Contact Information)
                </b>
                <div>
                  When you sign up as a registered user you may be required to
                  provide us with personal information about yourself, such as
                  your name, address, phone number, and e-mail address
                  (collectively, the “Contact Information”). We do not collect
                  any additional information other than what you voluntarily
                  provide.
                </div>
              </li>
              <li>
                <b>Account Information</b>
                <p>
                  If you create an account on any of our websites or Services (a
                  “User Account”), you may submit personal information such as
                  your full name, e-mail address, home address, phone number,
                  zip or postal code, gender, a profile bio, and birth date.
                  User’s may maybe required to submit their (i) means of age
                  verification (e.g., driver’s license, identification card, or
                  passport pics) in order to verify legal age to purchase age
                  restricted products. Your driver’s license, identification
                  card, or passport will be transmitted to the merchant in order
                  to verify you’re of legal age to purchase any age restricted
                  item.
                </p>
              </li>
              <li>
                <b>Transactional Information</b>
                <p>
                  If you initiate a transaction through any of our Websites or
                  Services, whether the transaction is an order placed with a
                  participating vendor (“Retailer”) or order placed directly
                  with us, you may submit to us personal information such as
                  your name, date of birth, telephone number, license plate
                  number, billing address, shipping address, delivery address,
                  e-mail address, and identification cards (Driver’s License,
                  Identification Card, Passport pics). This can include order
                  history. Additionally, some users may submit photographs of
                  themselves or signatures in order to process an order.
                </p>
              </li>
              <li>
                <b>Delivery and billing addresses</b>
                <p>
                  In order to submit orders through our Platform, you will be
                  required to provide billing and contact information. Such
                  information may include a debit card number, credit card
                  number, billing address, expiration date, security code and
                  similar information (collectively, the “Billing Information”).
                </p>
                <p>
                  Merchantech uses Google Maps and Google Maps API for our
                  online ordering system. By using Apprication’s website, iOS or
                  Android apps you are bound to the Google Maps API’s Terms of
                  Service. You can access Google Maps API Terms of service at{' '}
                  <a
                    href="https://developers.google.com/maps/terms-20180207#3-privacy-and-personal-information"
                    target="_blank"
                  >
                    Google Maps Platform
                  </a>
                </p>
              </li>
              <li>
                <b>Your order history</b>
                <p>
                  To provide you our services; We must share your name, phone
                  number, meal order, and, if you are ordering for delivery,
                  your physical address with the restaurants from which you
                  order and with any third parties that are involved in, or
                  provide services in connection with an order; such as delivery
                  services, licensees of our Platforms, and similar parties.
                  Such parties will use such information in accordance with the
                  terms of their privacy policies.
                </p>
              </li>
              <li>
                <b>Product Pictures</b>
                <p>
                  In order to maintain up-to-date inventory, Quickvee allows you
                  add, remove, and update images for products. As a result, we
                  need access to your photos and camera. The pictures and photos
                  uploaded to our website will be used as image for the
                  associated product.
                </p>
              </li>
            </ol>
          </div>

          {/* logic data  */}

          <div className="pricacy_div">
            <h1>Log Data</h1>

            <p>
              Like many site operators, we collect information that your browser
              sends whenever you visit our site ("Log Data"). This Log Data may
              include information such as your computer's Internet Protocol
              ("IP") address, browser type, browser version, the pages visited
              in our site, the time and date of your visit, the time spent on
              those pages and other statistics.
            </p>
          </div>

          {/*  Google Login*/}

          <div className="pricacy_div">
            <h1>Google Login</h1>
            <p>
              If you log in with your Google account, Apprication will obtain
              information from the Google account. It's up to you to check what
              your privacy settings on your Google account allow you to share.
            </p>
          </div>

          {/* Cookies */}
          <div className="pricacy_div">
            <h1>Cookies</h1>
            <p>
              Cookies are files with a small amount of data, which may include
              an anonymous unique identifier. Cookies are sent to your browser
              from a web site and stored on your computer's hard drive.
            </p>
            <p>
              Like many sites, we use "cookies" to collect information. You can
              instruct your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, if you do not accept cookies, you
              may not be able to use some portions of our site.
            </p>
          </div>

          {/*  do not track disclosure block*/}

          <div className="pricacy_div">
            <h1>Do Not Track Disclosure</h1>
            <p>
              Merchantech does not track users or collect personal information
              about a user’s online activities over time and across third-party
              websites. Merchantech does not allow third parties to collect
              personal information about the users across websites and over
              time; therefore Merchantech does not respond to Do Not Track (DNT)
              signals.
            </p>
            <p>
              Do Not Track (DNT) is a preference you can set on your web browser
              to let the websites you visit know that you do not want to be
              tracked. You can disable or enable this setting by visiting the
              Preferences or Settings menu of your web browser.
            </p>
          </div>

          {/*Communications  */}

          <div className="pricacy_div">
            <h1>Communications</h1>
            <p>
              We may use your personal information to contact you with
              newsletters, marketing or promotional materials and/or other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe instructions provided in emails.
            </p>
          </div>

          {/* Compliance with Laws */}

          <div className="pricacy_div">
            <h1>Compliance with Laws</h1>
            <p>
              Merchantech will disclose your personal information where required
              to do so by law or subpoena, or if we believe that such action is
              necessary to comply with the law and the reasonable requests of
              law enforcement, or to protect the security or integrity of our
              site.
            </p>
          </div>

          {/* business transcetion */}

          <div className="pricacy_div">
            <h1>Business Transaction</h1>
            <p>
              If Merchantech is involved in a merger, acquisition or asset sale,
              your personal information may be transferred. We will provide
              notice before your personal information is transferred or becomes
              subject to a different Privacy Policy.
            </p>
          </div>

          {/* Security */}

          <div className="pricacy_div">
            <h1>Security</h1>
            <p>
              The security of your personal information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage, is 100% secure. While we strive to
              use commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </p>
          </div>

          <div className="pricacy_div">
            <h1>Links to Other Sites</h1>
            <p>
              Our site may contain links to other sites that are not operated by
              us. If you click on a third-party link, you will be directed to
              that third party's site. We strongly advise you to review the
              privacy policy of every site you visit.
            </p>
          </div>

          <div className="pricacy_div">
            <h1>How to Access or Correct Your Information</h1>
            <p>
              You can request a copy of your PII by sending an email to
              info@apprication.com, or in writing to 230 S. Sterling Drive Suite
              260 Mountain House, CA 95391. You can correct factual errors in
              your personally identifiable information by updating your profile
              page on the website, or by sending us a request that credibly
              shows that our records are incorrect. To protect your privacy and
              security, we will also take reasonable steps to verify your
              identity before granting access to your personally identifiable
              information, or permitting you to make corrections. We reserve the
              right to refuse access to any user.
            </p>
          </div>

          <div className="pricacy_div">
            <h1>Security</h1>
            <p>
              The security of your personal information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage, is 100% secure. While we strive to
              use commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </p>
          </div>

          <div className="pricacy_div">
            <h1>Privacy Notice for California Residents</h1>
            <em>
              <b>In Short: </b> Yes, if you are a resident of California, you
              are granted specific rights regarding access to your personal
              information.
            </em>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
            </p>
          </div>

          {/* table */}
          <div className="pricacy_div table-responsive">
            <table>
              <tbody>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
                <tr>
                  <td>A. Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, and account name
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </td>
                  <td>
                    Name, contact information, education, employment, employment
                    history, and financial information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>
                    C. Protected classification characteristics under California
                    or federal law
                  </td>
                  <td>Gender and date of birth</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>D. Commercial information</td>
                  <td>
                    Transaction information, purchase history, financial
                    details, and payment information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems, and advertisements
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </td>
                  <td>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you our
                    services at a business level or job title, work history, and
                    professional qualifications if you apply for a job with us
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>NO</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from other personal information</td>
                  <td>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual’s preferences and
                    characteristics
                  </td>
                  <td>NO</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            California. We will make sure the data is not publicly displayed on
            the Services, but please be aware that the data may not be
            completely or comprehensively removed from all our systems (e.g.,
            backups, etc.).
          </p>

          {/* ccpa privacy noticed */}

          <div className="pricacy_div">
            <h1>CCPA Privacy Notice</h1>
            <p>The California Code of Regulations defines a "resident" as:</p>

            <p>
              (1)every individual who is in the State of California for other
              than a temporary or transitory purpose
              <br />
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </p>
            <p>All other individuals are defined as "non-residents."</p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <p>
              <b>What categories of personal information do we collect?</b>
            </p>

            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <p>
              We may also collect other personal information outside of these
              categories instances where you interact with us in person, online,
              or by phone or mail in the context of:
            </p>
            <ul>
              <li>Receiving help through our customer support channels.</li>
              <li>Participation in customer surveys or contests.</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p>
              <b>How do we use and share your personal information?</b>
            </p>
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </p>
            <p>
              You may contact us by email at{' '}
              <a href="mailto:info@apprication.com">info@apprication.com</a>, or
              by referring to the contact details at the bottom of this
              document.
            </p>

            <p>
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.
            </p>

            <p>
              <b>Will your information be shared with anyone else?</b>
            </p>

            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </p>
            <p>
              Apprication LLC has not disclosed or sold any personal information
              to third parties for a business or commercial purpose in the
              preceding twelve (12) months. Apprication LLC will not sell
              personal information in the future belonging to website visitors,
              users, and other consumers.
            </p>

            <p>
              <b>Your rights with respect to your personal data</b>
            </p>
            <p>Right to request deletion of the data — Request to delete</p>

            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <p>Right to be informed — Request to know</p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <p>
              <ul>
                <li>Whether we collect and use your personal information.</li>
                <li>The categories of personal information that we collect.</li>
                <li>
                  The purposes for which the collected personal information is
                  used.
                </li>
                <li>
                  Whether we sell your personal information to third parties.
                </li>
                <li>
                  The categories of personal information that we sold or
                  disclosed for a business purpose.
                </li>
                <li>
                  The categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose.
                </li>
                <li>
                  The business or commercial purpose for collecting or selling
                  personal information.
                </li>
              </ul>
            </p>
            <p>
              {' '}
              To exercise these rights, you can contact us by email at{' '}
              <a href="mailto:info@apprication.com" target="_blank">
                info@apprication.com
              </a>
              , or by referring to the contact details at the bottom of this
              document. If you have a complaint about how we handle your data,
              we would like to hear from you.
            </p>
          </div>
          {/*  */}
          <div className="pricacy_div">
            <h1>Changes to This Privacy Policy</h1>
            <p>
              Apprication LLC may update this privacy policy from time to time.
              We will notify you of any changes by posting the new privacy
              policy on the site. You are advised to review the privacy policy
              periodically for any changes.
            </p>
          </div>

          {/* contact */}

          <div className="pricacy_div">
            <h1>Contact Us</h1>
            <p>
              If you have any questions about this privacy policy, please
              contact us at{' '}
              <a href="mailto:info@apprication.com" target="_blank">
                info@apprication.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicydetails;
