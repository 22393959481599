import './Styles/App.css';
import './Styles/Product.css';
import './Styles/media.css';
import './Styles/custom.css';
import Main from './Main';
import LogoutTimer from '../src/Components/ReusableComponent/LogoutTimer';
import React, { useEffect } from 'react';
import './Styles/customSingleStore.css';
import './Styles/customPaymentCart.css';
import { useLocation, useNavigate } from 'react-router-dom';
function App() {
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   const url = location.pathname;
  //   const bool = location.pathname.includes('Findstore');
  //   if (bool) {
  //     const modifiedUrl = url.replace('/Findstore', '');
  //     navigate(modifiedUrl);
  //   }
  //   console.log('location: ', location.pathname.includes('Findstore'));
  // }, [location]);

  return (
    <>
      <LogoutTimer />
      <Main />
    </>
  );
}

export default App;
