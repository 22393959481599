import React, { useEffect, useState } from 'react';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../redux/features/Authentication/Customer/authUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import UseCookieStorage from '../../Constants/UseCookieStorage';

const LogoutTimer = () => {
  const dispatch = useDispatch();
  const authUserData = useSelector((state) => state.authUser);

  const localUserData = Cookies.get('userLoginData');
  const isGuest = Cookies.get('is_guest');
  const [logoutTime, setLogoutTime] = useState(
    new Date().getTime() + 2 * 60 * 60 * 1000,
  ); // Set logout time to 10 hours from now

  useEffect(() => {
    const handleUserActivity = (e) => {
      // Reset the logout time on user activity
      const extendSessionTime = new Date().getTime() + 2 * 60 * 60 * 1000;
      setLogoutTime(extendSessionTime);
      if (localUserData && !isGuest) {
        // user login`;
        if (
          e?.target?.id !== 'Logout-btn' &&
          e instanceof PointerEvent === true
        ) {
          UseCookieStorage(
            'userLoginData',
            localUserData,
            new Date(extendSessionTime),
          );
        }
      } else if (localUserData && isGuest === 'yes') {
        // guest login
        if (
          e?.target?.id !== 'Logout-btn' &&
          e instanceof PointerEvent === true
        ) {
          UseCookieStorage('is_guest', 'yes', new Date(extendSessionTime));
          UseCookieStorage(
            'userLoginData',
            localUserData,
            new Date(extendSessionTime),
          );
        }
      }
    };

    const checkLogoutTime = () => {
      const currentTime = new Date().getTime();
      // Check if the current time has exceeded the logout time
      if (currentTime > logoutTime) {
        localStorage.removeItem('userLoginData');
        // TODO: remove cookie here.
        Cookies.remove('userLoginData');
        Cookies.remove('is_guest');
        localStorage.clear();
        dispatch(setAuthUser([]));
        dispatch(setAuthentication(false));
        dispatch(setGuestLogin(''));
        setLogoutTime('');
        // Perform logout logic here (e.g., redirect to logout page or dispatch logout action)
        // console.log('User logged out due to inactivity.');
        // Example: Redirect to a logout page
        window.location.href = '/';
      }
    };

    // Attach event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    // Set up a timer to check the logout time periodically
    const timerId = setInterval(checkLogoutTime, 1000); // Check every second

    // Cleanup: Remove event listeners and clear the timer on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      clearInterval(timerId);
    };
  }, [logoutTime]);

  useEffect(() => {
    // if user data from cookie is empty and is authenticated
    if (!localUserData && authUserData?.isAuthenticated) {
      localStorage.removeItem('userLoginData');
      // remove cookie.
      Cookies.remove('userLoginData');
      Cookies.remove('is_guest');
      localStorage.clear();
      dispatch(setAuthUser([]));
      dispatch(setAuthentication(false));
      dispatch(setGuestLogin(''));
      setLogoutTime('');
      // Perform logout logic here (e.g., redirect to logout page or dispatch logout action)
      // console.log('User logged out due to cookie expire.');
      // Example: Redirect to a logout page
      if (authUserData?.isAuthenticated) {
        window.location.href = '/';
      }
    }
  }, [window.location.href]);

  return <div></div>;
};

export default LogoutTimer;
