
import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  addresses: [],
  // You can define other initial state properties here
};

// Create slice
const deliveryAddressSlice = createSlice({
  name: 'deliveryAddress',
  initialState,
  reducers: {
    // Define reducer functions here
    addAddress(state, action) {
      // If the payload is an empty array, clear the addresses
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        state.addresses = [];
      } else {
        // Otherwise, add the new address(es) to the existing addresses array
        state.addresses.push(...action.payload);
      }
    }
    // You can define other reducer functions here
  },
});

// Export actions and reducer
export const { addAddress} = deliveryAddressSlice.actions;
export default deliveryAddressSlice.reducer;
