import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import InputAdornment from '@mui/material/InputAdornment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CouponTipSection from './CouponTipSection';
import { useSelector, useDispatch } from 'react-redux';
import BillingInfoLogic from './BillingInfoLogic';
import { useNavigate } from 'react-router-dom';
import RemoveIcon from '../../../Assets/Payment/removepoints.svg';
import { createTheme } from '@mui/material';
import dayjs from 'dayjs';
import ImageCropModal from '../../Account/AccountInfo/ImageCropModal';
import { BASE_URL_SANDBOX } from '../../../Constants/Config';
import CouponTipSectionLogic from './CouponTipSectionLogic';
import ScreenLoader from '../../../Assets/screenLoader/Quickvee_loader_.gif';
import Loaderfile from '../Loader/loaderfile';

const BillingInfo = ({
  setVisible,
  visible,
  merchantId,
  setisVisited,
  orderMethod,
}) => {
  const inputRefs = useRef({});
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [accountData, setAccountData] = useState('');
  const authUserData = useSelector((state) => state?.authUser?.UserData);
  const { isAuthenticated } = useSelector((state) => state.authUser);
  const { isGuest } = useSelector((state) => state.authUser);
  const accountInfoDataState = useSelector((state) => state.accountInfo);
  const inputFileRef = useRef(null);
  const [inputFileClass, setInputFileClass] = useState(
    'file-input-not-display',
  );
  const [selectedImage, setSelectedImage] = useState();
  const [oldSelectedImage, setOldSelectedImage] = useState();
  const [imageName, setImageName] = useState('');
  const [selectedImageBase64, setSelectedImageBase64] = useState('');
  const [imageExists, setImageExists] = useState(false);
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);
  const yesterday = dayjs().subtract(1, 'day');

  const handleDateOfBirth = (e) => {
    setValues((prevState) => ({
      ...prevState,
      DOB: e ? dayjs(new Date(e)) : '',
      errors: {
        DOB: '',
      },
    }));
  };

  const handleExpireDate = (e) => {
    setValues((prevState) => ({
      ...prevState,
      expiredate: e ? dayjs(new Date(e)) : '',
      errors: {
        expiredate: '',
      },
    }));
  };

  const handleInputCHange = (e) => {
    handleFileInput(e);

    inputFileRef.current.value = '';
  };

  const handleFileInput = (e) => {
    setSelectedImage(null);

    setInputFileClass('file-input-not-display');
    const inputValue = e.target.files[0];

    // const newInputClass = inputValue ? 'file-input-not-display ' : 'my-custom-class-for-file-input';
    // setInputFileClass(newInputClass);
    if (inputValue !== undefined && inputValue !== null) {
      setSelectedImage(inputValue);
    }
  };
  const handleSelectImage = () => {
    setSelectedImage(null);
    let c = document.getElementById('image-input');
    c.click();
  };
  const {
    BillingInfoInput,
    handleSubmitBillingInfo,
    values,
    submitmessage,
    setValues,
    loading,
  } = BillingInfoLogic({
    accountData,
    userData,
    selectedImageBase64,
    setVisible,
    setisVisited,
    merchantId,
    orderMethod,
    setSelectedImageBase64,
  });

  function checkImage(url) {
    // console.log('url: ', url);
    let image = new Image();
    image.onload = function () {
      if (this.width > 0) {
        // console.log('image exists');
        setImageExists(() => true);
      }
    };
    image.onerror = function () {
      // console.log("image doesn't exist");
      setImageExists(() => false);
    };
    image.src = url;
  }

  // here
  useEffect(() => {
    const bool =
      Boolean(values.myfile === '') &&
      Boolean(accountData) &&
      Boolean(accountData.i_card_front_img) &&
      Boolean(!selectedImageBase64) &&
      Boolean(isGuest !== 'yes') &&
      Boolean(accountData.image_url);

    if (accountData && accountData.image_url) {
      checkImage(accountData.image_url);
    }

    if (bool && imageExists) {
      // console.log('setting image myfile...');
      setValues((prev) => ({ ...prev, myfile: accountData?.i_card_front_img }));
    }
  }, [values, accountData, selectedImageBase64, imageExists]);

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      myfile: imageName,
      errors: {
        myfile: '',
      },
    }));
  }, [imageName]);

  useEffect(() => {
    if (authUserData && authUserData.id) {
      setUserData(authUserData);
    }
  }, [authUserData]);

  const handleUpdateBillingInfo = (e) => {
    // console.log('updateBillingInfo', e);
    handleSubmitBillingInfo(e);
  };

  useEffect(() => {
    if (
      !accountInfoDataState.loading &&
      accountInfoDataState &&
      accountInfoDataState.accountInfoData
    ) {
      setAccountData(accountInfoDataState.accountInfoData.message);
    }
  }, [
    accountInfoDataState,
    accountInfoDataState.loading,
    accountInfoDataState.accountInfoData,
  ]);
  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  // //for croppedImage
  const handleImageCropped = (croppedImage) => {
    // Update state with the cropped image
    setSelectedImage(croppedImage);
  };

  return (
    <>
      {loading ? (
        <div className="loaderarea">
          <Loaderfile />
        </div>
      ) : (
        <div className="payment-stepper-cart">
          {!loading ? (
            <>
              <div className="payment-stepper-customer-detail-section">
                <form className="payment-stepper-single-billing-info-detail">
                  <div className="payment-billing-customer-form">
                    <FormControl
                      className="payment-billing-customer-form"
                      autoComplete="off"
                    >
                      <h1>Basic Info</h1>
                      <style>
                        {`
                          .input-field{
                            width: 100%;
                          }
                      
                          .date_container .MuiStack-root {
                            overflow:unset;
                            position:relative;
                            top:-8px;
                          }
                        `}
                      </style>
                      <div className="row">
                        <div
                          className="col-xs-12 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <TextField
                              autoComplete="off"
                              label="First Name"
                              variant="outlined"
                              className="input-field"
                              name="firstname"
                              onChange={BillingInfoInput}
                              value={values.firstname}
                            />
                          </div>

                          <span className="input-error">
                            {values.errors.firstname}
                          </span>
                        </div>

                        <div
                          className="col-xs-12 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            className="input-field"
                            autoComplete="off"
                            name="lastname"
                            onChange={BillingInfoInput}
                            value={values.lastname}
                          />
                          <span className="input-error">
                            {values.errors.lastname}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <TextField
                              label="Phone Number"
                              variant="outlined"
                              className="input-field"
                              autoComplete="off"
                              name="phone"
                              onChange={BillingInfoInput}
                              value={values.phone}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          </div>
                          <span className="input-error">
                            {values.errors.phone}
                          </span>
                        </div>
                      </div>
                    </FormControl>
                  </div>

                  <div className="payment-billing-customer-form">
                    <FormControl
                      className="payment-billing-customer-form"
                      autoComplete="off"
                    >
                      <h1>ID Verification</h1>
                      <div className="id-verigfication-description">
                        <p>
                          Your ID is used to verify your age. The merchant will
                          verify that you are of the legal age to purchase any
                          age restricted products.
                        </p>
                      </div>
                      <div className="row">
                        <div
                          className="col-xs-12 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <FormControl
                              className="form-select-formcontrol"
                              fullWidth
                            >
                              <InputLabel id="demo-simple-select-label">
                                ID Type
                              </InputLabel>
                              <Select
                                className="select-OnFocus"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Id Type"
                                name="idproof"
                                onChange={BillingInfoInput}
                                value={values.idproof}
                              >
                                <MenuItem value="identification_card">
                                  Identification Card
                                </MenuItem>
                                <MenuItem value="passport">Passport</MenuItem>
                                <MenuItem value="driving_license">
                                  Driver's License
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <span className="input-error">
                            {values.errors.idproof}
                          </span>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <TextField
                              label="ID Number"
                              variant="outlined"
                              className="input-field"
                              autoComplete="off"
                              name="idnumber"
                              onChange={BillingInfoInput}
                              value={values.idnumber}
                            />
                          </div>
                          <span className="input-error">
                            {values.errors.idnumber}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-6 col-xs-6 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                fullWidth
                                components={['DatePicker']}
                              >
                                <MobileDatePicker
                                  value={
                                    values.expiredate &&
                                    dayjs(values.expiredate)
                                  }
                                  format={'MM/DD/YYYY'}
                                  label="Expiration Date"
                                  renderInput={(props) => (
                                    <TextField
                                      {...props}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton edge="end">
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    ></TextField>
                                  )}
                                  slotProps={{
                                    textField: {
                                      variant: 'outlined',
                                    },
                                  }}
                                  className="custom-date-picker-width"
                                  onChange={(e) => {
                                    handleExpireDate(e);
                                  }}
                                  name="expiredate"
                                  disablePast
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                          <span className="input-error">
                            {values.errors.expiredate}
                          </span>
                        </div>
                        <div
                          className="col-6 col-xs-6 col-sm-6 col-md-6"
                          style={{ position: 'relative', marginBottom: '24px' }}
                        >
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']}>
                                {values && values.DOB ? (
                                  <MobileDatePicker
                                    value={values.DOB && dayjs(values.DOB)}
                                    format={'MM/DD/YYYY'}
                                    label="Date of Birth"
                                    slotProps={{
                                      textField: { variant: 'outlined' },
                                    }}
                                    className="custom-date-picker-width"
                                    onChange={(e) => handleDateOfBirth(e)}
                                    name="DOB"
                                    disableFuture
                                  />
                                ) : (
                                  <MobileDatePicker
                                    format={'MM/DD/YYYY'}
                                    label="Date of Birth"
                                    slotProps={{
                                      textField: { variant: 'outlined' },
                                    }}
                                    className="custom-date-picker-width"
                                    onChange={(e) => handleDateOfBirth(e)}
                                    name="DOB"
                                    disableFuture
                                  />
                                )}
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                          <span className="input-error">
                            {values.errors.DOB}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                          <div
                            id="modal-modal-description"
                            className="modal-forms new__input"
                          >
                            <div className="custom-file-upload-input">
                              <div className="image-input-quickvee">
                                <input
                                  type="file"
                                  className={` ${inputFileClass}`}
                                  id="image-input"
                                  onChange={(e) => handleInputCHange(e)}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  ref={inputFileRef}
                                />

                                {accountData &&
                                accountData.i_card_front_img &&
                                accountData.image_url &&
                                imageExists &&
                                isGuest !== 'yes' ? (
                                  <label className="my-file-custom-input-for-image">
                                    {isGuest !== 'yes'
                                      ? accountData &&
                                        accountData.i_card_front_img &&
                                        accountData?.image_url &&
                                        imageExists
                                        ? accountData.i_card_front_img.length >
                                          47
                                          ? `${accountData.i_card_front_img.slice(
                                              0,
                                              32,
                                            )}...` // Slice the text to the first 47 characters and add ellipsis
                                          : accountData.i_card_front_img // Display the full text if it's shorter than 47 characters
                                        : ''
                                      : ''}{' '}
                                  </label>
                                ) : (
                                  <label className="my-file-custom-input-for-image">
                                    {' '}
                                    {accountData &&
                                    accountData.i_card_front_img &&
                                    isGuest !== 'yes' &&
                                    accountData?.image_url &&
                                    imageExists
                                      ? accountData &&
                                        accountData.i_card_front_img // Condition 2: Old image
                                      : 'Upload Document Here'}{' '}
                                  </label>
                                )}
                                <ImageCropModal
                                  userData={accountData}
                                  handleSelectImage={handleSelectImage}
                                  selectedImage={selectedImage}
                                  setSelectedImage={setSelectedImage}
                                  setOldSelectedImage={setOldSelectedImage}
                                  setImageName={setImageName}
                                  setSelectedImageBase64={
                                    setSelectedImageBase64
                                  }
                                  payment={'payment'}
                                  onImageCropped={handleImageCropped} // for croppedImage
                                />
                              </div>

                              {/* for non guest users */}
                              {isGuest !== 'yes' &&
                              ((accountData &&
                                accountData.image_url &&
                                imageExists) ||
                                selectedImage) ? (
                                <center>
                                  <img
                                    src={
                                      selectedImage
                                        ? URL.createObjectURL(selectedImage)
                                        : oldSelectedImage
                                        ? URL.createObjectURL(oldSelectedImage)
                                        : accountData.image_url && imageExists
                                        ? accountData.image_url
                                        : ''
                                    }
                                    alt="Selected"
                                    style={{
                                      width: '100%',
                                      maxWidth: '250px',
                                      height: '150px',
                                      objectFit: 'cover',
                                      margin: '12px 0px',
                                    }}
                                  />
                                </center>
                              ) : null}

                              {/* only for guest users */}
                              {isGuest === 'yes' &&
                              (selectedImageBase64 ||
                                (accountData &&
                                  accountData.image_url &&
                                  imageExists)) ? (
                                <center>
                                  <img
                                    src={
                                      oldSelectedImage
                                        ? URL.createObjectURL(oldSelectedImage)
                                        : accountData &&
                                          accountData.image_url &&
                                          imageExists
                                        ? accountData.image_url
                                        : ''
                                    }
                                    alt="Selected"
                                    style={{
                                      width: '250px',
                                      height: '150px',
                                      objectFit: 'cover',
                                      margin: '12px 0px',
                                    }}
                                  />
                                </center>
                              ) : null}
                              <span className="input-error image_input_error">
                                {values.errors.myfile}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormControl>
                  </div>
                </form>
              </div>
              <div className="payment-stepper-checkout-item">
                <CouponTipSection
                  setVisible={setVisible}
                  visible={visible}
                  userData={isAuthenticated}
                  handleUpdateBillingInfo={handleUpdateBillingInfo}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default BillingInfo;
