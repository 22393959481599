import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_VARIENTS_DETAIL,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  variantData: null,
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchVariantData = createAsyncThunk(
  'variant/fetchVariantData',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_VARIENTS_DETAIL,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      if (response) {
        return response.data.result;
      }
    } catch (error) {
      return new Error('Something went wrong');
    }
  },
);

const variantSlice = createSlice({
  name: 'variant',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchVariantData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchVariantData.fulfilled, (state, action) => {
      state.loading = false;
      state.variantData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchVariantData.rejected, (state, action) => {
      state.loading = false;
      state.variantData = {};
      state.error = action.error.message;
    });
  },
});

export default variantSlice.reducer;
