import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_SINGLE_CATEGORY_SEARCH_DATA,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  searchCategoryData: {},
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchSearchCategoryData = createAsyncThunk(
  'searchCategory/fetchSearchCategoryData',
  async (data) => {
    const response = await axios.post(
      BASE_URL_SANDBOX + GET_SINGLE_CATEGORY_SEARCH_DATA,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    if (response.data.status === 200) {
      return response.data.message;

    }
  },
);

const searchCategorySlice = createSlice({
  name: 'searchCategory',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSearchCategoryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchCategoryData.fulfilled, (state, action) => {
      state.loading = false;
      state.searchCategoryData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchSearchCategoryData.rejected, (state, action) => {
      state.loading = false;
      state.searchCategoryData = {};
      state.error = action.error.message;
    });
  },
});

export default searchCategorySlice.reducer;
