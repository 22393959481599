import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { BASE_URL_SANDBOX,  GET_ALL_LOYALTY_POINT_HISTORY } from "../../../Constants/Config";

const initialState = {
    loading: false,
    loyaltyPointData: [],
    successMessage: "",
    error: '',
}

// Generate pening , fulfilled and rejected action type
export const fetchLoyaltyPointData = createAsyncThunk('loyaltyPoint/fetchLoyaltyPointData.', async (data) => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX +  GET_ALL_LOYALTY_POINT_HISTORY, data, { headers: { "Content-Type": "multipart/form-data" } })
        if (response.data.status === true) {
           return response.data
        } else{
            return []
        }
    } catch (error) {
        
        throw new Error(error.response.data.message);
    }
})

const loyaltyPointSlice = createSlice({
    name: 'loyaltyPoint',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchLoyaltyPointData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchLoyaltyPointData.fulfilled, (state, action) => {
            state.loading = false;
            state.loyaltyPointData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchLoyaltyPointData.rejected, (state, action) => {
            state.loading = false;
            state.loyaltyPointData = [];
            state.error = action.error.message;
        })

    }
})


export default loyaltyPointSlice.reducer