import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_HIGHEST_SELLING_PRODUCTS,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  highestSellingProductsData: [],
  error: '',
};

export const fetchHighestSellingProducts = createAsyncThunk(
  'highestSellingProducts/fetchHighestSellingProducts',
  async (data) => {
    const response = await axios.post(
      BASE_URL_SANDBOX + GET_HIGHEST_SELLING_PRODUCTS,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    if (response.data.status === 200) {
      return response.data.result.highest_selling_products_data;
    }
  },
);

const highestSellingProductsSlice = createSlice({
  name: 'highestSellingProducts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchHighestSellingProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchHighestSellingProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.highestSellingProductsData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchHighestSellingProducts.rejected, (state, action) => {
      state.loading = false;
      state.highestSellingProductsData = [];
      state.error = action.error.message;
    });
  },
});

export default highestSellingProductsSlice.reducer;
