import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import DeliveryAddressIcon from '../../../Assets/Payment/DeliveryAddress.svg';

const DeliveryAddressModal = ({
  selectedDeliveryAdderess,
  orderMethod,
  handlePlaceOrder,
  orderPlaceApiResponse,
  paymentType,
  disablePayButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = async () => {
    if (!selectedDeliveryAdderess && orderMethod === 'delivery') {
      setOpen(true);
    } else {
      await handlePlaceOrder();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      orderPlaceApiResponse?.status === false &&
      paymentType === 'newdebitcard'
    ) {
      setOpen(true);
    }
  }, [orderPlaceApiResponse]);

  return (
    <>
      <Button
        className="payment-checkout-with-login"
        disabled={disablePayButton}
        onClick={handleClickOpen}
      >
        Pay
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="delivery-amount-modal-cart"
      >
        <div className="delivery-address-not-selected">
          <div className="not-select-empty-delivery-content">
            <img src={DeliveryAddressIcon} alt="No-products" />
            {orderPlaceApiResponse?.status === false ? (
              <h1> {orderPlaceApiResponse?.reason}</h1>
            ) : (
              <h1> Please Select Delivery Address</h1>
            )}
            {/* <p>Please Select Delivery Address</p> */}
          </div>
        </div>

        {/* <p className='delivery-amount-modal-cart-text'>Please Select Delivery Address</p> */}

        <button
          onClick={handleClose}
          className="delivery-amount-button-cart-ok"
        >
          Ok
        </button>
      </Dialog>
    </>
  );
};

export default DeliveryAddressModal;
