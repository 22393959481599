import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isAuthenticated: false,
  UserData: null,
  isGuest: '',
};

const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.UserData = action.payload;
    },
    updateAuthUser: (state, action) => {
      state.UserData = { ...state.UserData, ...action.payload };
    },
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setGuestLogin: (state, { payload }) => {
      state.isGuest = payload;
    },
  },
});

export const { setAuthUser, setAuthentication, updateAuthUser, setGuestLogin } =
  authUserSlice.actions;

export default authUserSlice.reducer;
