// custom-hook for set cookie
// set login info in cookies

import Cookies from 'js-cookie';

const UseCookieStorage = (key, data, expireTime) => {
  const time = 2; // 1 = 60 mins, 0.5 = 30 mins, 0.083 = 5 mins...
  const extendSessionTime = new Date().getTime() + time * 60 * 60 * 1000;
  Cookies.set(key, data, { expires: new Date(extendSessionTime) });
};

export default UseCookieStorage;
