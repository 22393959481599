import { FormControl, Button, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import QButton from '../Main/QButton';
import GoogleIcon from '../../../Assets/LoginScreen/googleLogo.svg';
import LoginCustomerFormLogic from './LoginCustomerFormLogic';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

const LoginCustomerForm = () => {
  const {
    handleKeyDown,
    handleloginInput,
    values,
    handleUserLogin,
    submitmessage,
    openAlert,
    setOpenAlert,
    scrollRef,
    setsubmitmessage,
    loginWithGoogle,
  } = LoginCustomerFormLogic();
  const [show, setShow] = useState(false);

  const location = useLocation();
  const mymerchantId = location.state?.merchantId || '';
  const orderMethod = location.state?.orderMethod || '';
  const page = location.state?.page || '';

  const inputRefs = useRef({});

  const goToTop = () => {
    setOpenAlert(false);
    setsubmitmessage('');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // if (submitmessage) {
  //   setTimeout(() => {
  //     goToTop();
  //   }, 10000);
  // }

  const handleTouchStart = () => {
    setShow(true);
  };

  const handleTouchEnd = () => {
    setShow(false);
  };
  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  return (
    <>
      {submitmessage && (
        <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
          ref={scrollRef}
        >
          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  className="error-close-icon"
                  aria-label="close"
                  color="error"
                  size="small"
                  onClick={goToTop}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 4 }}
            >
              {submitmessage}
            </Alert>
          </Collapse>
        </Box>
      )}
      <form className="login-customer-form">
        <FormControl className="login-customer-form" autoComplete="off">
          <h1>Customer Login</h1>
          <span className="sub-heading-from">Welcome Back, Login to Shop</span>
          <div
            style={{
              // display: 'flex',
              // justifyContent: 'center',
              width: '300px',
            }}
          >
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    className="input-field"
                    // size="small"
                    variant="outlined"
                    name="username"
                    value={values.username}
                    onChange={handleloginInput}
                    // inputProps={{
                    //   'data-field': 'username',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['username'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.username,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.username}</span>
              </div>
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    className="input-field"
                    label="Password"
                    variant="outlined"
                    // size="small"
                    autoComplete="off"
                    type={show === true ? 'text' : 'password'}
                    onChange={handleloginInput}
                    name="password"
                    value={values.password}
                    onKeyDown={handleKeyDown}
                    // inputProps={{
                    //   'data-field': 'password',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['password'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.password,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.password}</span>
                <span
                  className="show-hide-button"
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  onMouseDown={handleTouchStart}
                  onMouseUp={handleTouchEnd}
                >
                  {' '}
                  {show === true && values.password.length > 0
                    ? 'Hide'
                    : 'Show'}{' '}
                </span>
              </div>
              {/* </div>
          <div className="input-outer-div"></div>
          <div className="input-outer-div">
            */}
            </div>
            <Link
              className="forgot-password"
              underline="always"
              to="/forgot-password"
            >
              Forgot Password ?
            </Link>
            <QButton onClickHandle={handleUserLogin} name={'Login'} />
          </div>
        </FormControl>

        {/* sign in section */}
        <div className="login-customer-form">
          <span className="sign-in-section-head">
            <span>
              <hr />
            </span>
            <p> Or Sign in with </p>
            <span>
              <hr />
            </span>
          </span>

          <Button
            variant="contained"
            onClick={() => loginWithGoogle()}
            className="customer-login-with-google"
          >
            Log In with Google{' '}
            <img alignright="true" alt="Login with Google" src={GoogleIcon} />
          </Button>

          <p className="login-customer-reduirection">
            Don't have an account?
            <Link
              to="/register"
              state={{
                merchantId: mymerchantId,
                orderMethod: orderMethod,
                page: page,
              }}
              className="login-customer-reduirection-link"
            >
              Register Now
            </Link>
          </p>
          <p className="login-customer-reduirection">
            Are you a Merchant?
            <Link
              className="login-customer-reduirection-link"
              target="_blank"
              to="https://www.quickvee.com/merchants/login"
            >
              {' '}
              Login{' '}
            </Link>
          </p>
        </div>

        {/* <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
          ref={scrollRef}
        >
          {submitmessage && (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={goToTop}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          )}
        </Box> */}
      </form>
    </>
  );
};

export default LoginCustomerForm;
