import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleProduct from '../Products/SingleProduct';
import { fetchWishlistData } from '../../../redux/features/Wishlist/wishlistSlice';
import { Link, useLocation } from 'react-router-dom';
import EmptyWislist from '../../../Assets/Store/emptyWishlist.svg';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const SingleStoreWishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const wishlistStateData = useSelector((state) => state.wishlist);
  const authUserData = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const merchantId = location.pathname.replace('/wishlist/', '');
  const orderMethod = searchParams.get('orderMethod');
  const isMobile = useMediaQuery('(max-width:768px)');

  const theme = createTheme({
    breakpoints: {
      values: {
        cxxs: 0,
        xs: 600,
        sm: 856,
        md: 1200,
        lg: 1500,
        xl: 1700,
        xxl: 1920,
      },
    },
  });
  const datawishlist = {
    id:
      authUserData.isAuthenticated &&
      authUserData.UserData &&
      authUserData.UserData.id
        ? authUserData.UserData.id
        : '',
    merchant_id: merchantId ? merchantId : '',
  };

  useEffect(() => {
    if (
      authUserData.isAuthenticated &&
      authUserData.UserData !== null &&
      authUserData.UserData.id &&
      merchantId
    ) {
      dispatch(fetchWishlistData(datawishlist));
    }
  }, [authUserData, authUserData.isAuthenticated]);

  useEffect(() => {
    if (!wishlistStateData.loading) {
      setWishlist(wishlistStateData.wishlistData);
    }
  }, [wishlist, wishlistStateData.loading, wishlistStateData.wishlistData]);

  return (
    <>
      {!authUserData.isAuthenticated ? (
        <div className="single-store-empty-wishlist">
          <div className="empty-wishlist-content">
            <img src={EmptyWislist} alt="No-products" />
            <h1>PLEASE LOG IN</h1>
            <p>Login to view items in your wishlist.</p>{' '}
            <Button className="continue-shopping-on-wishlist">
              {' '}
              <Link to="/customer-login" state={{ merchantId: merchantId }}>
                {' '}
                Login{' '}
              </Link>{' '}
            </Button>
          </div>
        </div>
      ) : !wishlistStateData.error &&
        wishlist &&
        Array.isArray(wishlist) &&
        wishlist.length >= 1 ? (
        <>
          <Grid container className="single-store-wishlist">
            {wishlist.map((product) => {
              return (
                product !== null && (
                  <Grid
                    item
                    theme={theme}
                    cxxs={6}
                    xs={isMobile ? 4 : 6} // 600px
                    sm={4} //806px
                    md={3} // 1200px
                    lg={2.4} // 1500px
                    xl={2} //1700px
                    xxl={1.7} //1920px
                    className="product-in-category"
                  >
                    <SingleProduct
                      key={product && product.id}
                      product={product}
                      categoryId={product && product.cotegory}
                      Wishlist={true}
                      merchantId={product && product.merchant_id}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        </>
      ) : (
        <div className="single-store-empty-wishlist">
          <div className="empty-wishlist-content">
            <img src={EmptyWislist} alt="No-products" />
            <h1>YOUR WISHLIST IS EMPTY</h1>
            <p>
              Add items that you like to your wishlist. Review them anytime and
              easily move them to the bag.
            </p>{' '}
            <Link to={`/merchant/${merchantId}?orderMethod=${orderMethod}`}>
              {' '}
              <Button className="continue-shopping-on-wishlist">
                {' '}
                Continue Shopping{' '}
              </Button>{' '}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleStoreWishlist;
