import { FormControl, Button, TextField, Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import QButton from '../Main/QButton';
import GoogleIcon from '../../../Assets/LoginScreen/googleLogo.svg';
import RegisterCustomerFormLogic from './RegisterCustomerFormLogic';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from 'react-google-recaptcha';

const RegisterCustomerForm = () => {
  const {
    handleCustomerRegisterInput,
    values,
    handleCustomerRegister,
    submitmessage,
    openAlert,
    setOpenAlert,
    scrollRef,
    setValues,
    validateNumberInput,
    setsubmitmessage,
  } = RegisterCustomerFormLogic();
  // const regiterwithcheckout = sessionStorage.getItem("checkoutwithlogin"); /
  // const jsonString = JSON.stringify(regiterwithcheckout);
  // console.log(jsonString); // Output the JSON string

  // var regiterwithcheckout = JSON.parse(sessionStorage.getItem('checkoutwithlogin'));
  var regiterwithcheckout = sessionStorage.getItem('checkoutwithlogin');
  //console.log("regiterwithcheckout: ", JSON.parse(regiterwithcheckout));
  const jsonString = JSON.parse(regiterwithcheckout);

  //console.log(jsonString);

  const handleRecaptcha = (value) => {
    setValues((prevState) => ({
      ...prevState,
      recaptcha: value ? true : false,
      errors: {
        firstname: values.errors.firstname,
        lastname: values.errors.lastname,
        username: values.errors.username,
        password: values.errors.password,
        confirmpassword: values.errors.confirmpassword,
        phone: values.errors.phone,
        recaptcha: '',
      },
    }));

    // console.log('Captcha value:', value);
  };
  const Navigate = useNavigate();

  const goToTop = () => {
    setOpenAlert(false);
    setsubmitmessage('');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // if (submitmessage) {
  //   setTimeout(() => {
  //     goToTop();
  //   }, 10000);
  // }
  const [show, setShow] = useState(false);
  const [reshow, setReShow] = useState(false);
  const inputRefs = useRef({});
  if (values.password !== values.confirmpassword) {
    values.errors.confirmpassword = 'Confirm Password not matching';
  }

  const handleTouchStart = () => {
    setShow(true);
  };

  const handleTouchEnd = () => {
    setShow(false);
  };

  const handleTouchStartRe = () => {
    setReShow(true);
  };

  const handleTouchEndRe = () => {
    setReShow(false);
  };

  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  //console.log(handleCustomerRegister);
  return (
    <>
      {submitmessage && (
        <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
          ref={scrollRef}
        >
          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  className="error-close-icon"
                  aria-label="close"
                  color="error"
                  size="small"
                  onClick={goToTop}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 4 }}
            >
              {submitmessage}
            </Alert>
          </Collapse>
        </Box>
      )}

      <form className="login-customer-form">
        <FormControl className="login-customer-form" autoComplete="off">
          <h1>Customer Register</h1>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '  margin: 10px 0 25px 0;',
            }}
          >
            <span className="sub-heading-from">
              Discover shopping delights!
            </span>
          </div>

          {/* <div className="register-inputs-div">
            <div className="input-outer-div">
             
             
            </div>

            <div className="input-outer-div">
              
            </div>
          </div> */}
          <div style={{ width: '300px' }}>
            <div className="row">
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    // size="small"
                    variant="outlined"
                    className="input-field"
                    autoComplete="off"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleCustomerRegisterInput}
                    // inputProps={{
                    //   'data-field': 'firstname',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['firstname'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.firstname,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.firstname}</span>
              </div>
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    // size="small"
                    variant="outlined"
                    className="input-field"
                    autoComplete="off"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleCustomerRegisterInput}
                    // inputProps={{
                    //   'data-field': 'lastname',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['lastname'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.lastname,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.lastname}</span>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Phone Number"
                    className="input-field"
                    // size="small"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    inputmode="numeric"
                    pattern="[0-9\s]{13,19}"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    // onInput={validateNumberInput(this)}
                    name="phone"
                    value={values.phone}
                    onChange={handleCustomerRegisterInput}
                    inputProps={{
                      maxLength: 10,
                      // 'data-field': 'phone',
                      // autoComplete: 'off',
                      // ref: (input) => (inputRefs.current['phone'] = input), // Store the ref in a ref object
                      // selectionstart: values.cursorposition.phone,
                    }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.phone}</span>
              </div>
            </div>

            {/* <div className="input-outer-div"></div> */}
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    className="input-field"
                    // size="small"
                    variant="outlined"
                    autoComplete="off"
                    name="username"
                    value={values.username}
                    onChange={handleCustomerRegisterInput}
                    // inputProps={{
                    //   'data-field': 'username',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['username'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.username,
                    // }}
                  />
                </FormControl>
                <span className="input-error">{values.errors.username}</span>
              </div>
            </div>
            {/* <div className="input-outer-div"></div> */}

            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    className="input-field"
                    label="Password"
                    // size="small"
                    variant="outlined"
                    autoComplete="off"
                    type={show === true ? 'text' : 'password'}
                    onChange={handleCustomerRegisterInput}
                    name="password"
                    value={values.password}
                    // inputProps={{
                    //   'data-field': 'password',
                    //   autoComplete: 'off',
                    //   ref: (input) => (inputRefs.current['password'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.password,
                    // }}
                  ></TextField>
                </FormControl>
                <span className="input-error">{values.errors.password}</span>
                <span
                  className="show-hide-button"
                  onMouseDown={() => setShow(true)}
                  onMouseUp={() => setShow(false)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  {show === true && values.password.length > 0
                    ? 'Hide'
                    : 'Show'}
                </span>
              </div>
            </div>
            {/* <div className="input-outer-div">
          
          </div> */}

            <div className="row">
              <div
                className="col-md-12"
                style={{ marginBottom: '24px', position: 'relative' }}
              >
                <FormControl fullWidth>
                  <TextField
                    className="input-field"
                    label="Re-Enter Password"
                    id="outlined-size-small-ok"
                    // size="small"
                    variant="outlined"
                    autoComplete="off"
                    type={reshow === true ? 'text' : 'password'}
                    onChange={handleCustomerRegisterInput}
                    name="confirmpassword"
                    value={values.confirmpassword}
                    // inputProps={{
                    //   'data-field': 'password',
                    //   autoComplete: 'off',
                    //   ref: (input) =>
                    //     (inputRefs.current['confirmpassword'] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.confirmpassword,
                    // }}
                  ></TextField>
                </FormControl>
                <span className="input-error">
                  {values.errors.confirmpassword}
                </span>
                <span
                  className="show-hide-button"
                  onMouseDown={() => setReShow(true)}
                  onMouseUp={() => setReShow(false)}
                  onTouchStart={handleTouchStartRe}
                  onTouchEnd={handleTouchEndRe}
                >
                  {reshow === true && values.confirmpassword.length > 0
                    ? 'Hide'
                    : 'Show'}
                </span>
              </div>
            </div>
            {/* <div className="input-outer-div"></div> */}
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <div>
                    <ReCAPTCHA
                      // style={{transform: "scale(1)"}}
                      sitekey={process.env.REACT_APP_CAPTCHAKEY}
                      onChange={handleRecaptcha}
                    />
                  </div>
                </FormControl>
                <span className="input-error">{values.errors.recaptcha}</span>
              </div>
            </div>
            {/* <div className="input-outer-div">
             
            </div> */}

            <QButton onClickHandle={handleCustomerRegister} name={'Register'} />
          </div>
        </FormControl>

        {/* sign in section */}
        <div className="">
          {/* <span className="sign-in-section-head">
            <span>
              <hr />
            </span>
            <p> Or Register with </p>
            <span>
              <hr />
            </span>
          </span> */}
          {/* <Button variant="contained" className="customer-login-with-google">
            Register with Google
            <img alignright="true" src={GoogleIcon} alt="login with google" />
          </Button>  */}

          <p className="login-customer-reduirection">
            Already have an account?
            <Link
              className="login-customer-reduirection-link"
              to="/customer-login"
            >
              Login
            </Link>
          </p>
          <p className="login-customer-reduirection">
            Are you a Merchant?
            <Link
              target="_blank"
              className="login-customer-reduirection-link"
              to="https://www.quickvee.com/merchants/login"
            >
              Login
            </Link>
          </p>
        </div>

        {/* <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
          ref={scrollRef}
        >
          {submitmessage && (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={goToTop}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          )}
        </Box> */}
      </form>
    </>
  );
};

export default RegisterCustomerForm;
