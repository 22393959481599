import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_WISHLIST_PRODUCTS, ADD_TO_WISHLIST, DELETE_TO_WISHLIST } from "../../../Constants/Config"

const initialState = {
    loading: false,
    wishlistData: [],
    successMessage: "",
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchWishlistData = createAsyncThunk('wishlist/fetchWishlistData', async (data) => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + GET_WISHLIST_PRODUCTS, data, { headers: { "Content-Type": "multipart/form-data" } })
        if (response.data.status === 200) {
            const filterProducts = response.data.message.product_record_all.filter(product => product !== null);
            return filterProducts
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})
export const addToWishlist = createAsyncThunk('wishlist/addToWishlist', async (data) => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + ADD_TO_WISHLIST, data, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        // console.log(response)
        if (response.data.status === 200) {
            return response.data.wish_data;
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

export const deleteToWishlist = createAsyncThunk('wishlist/deleteToWishlist', async (data) => {

    try {
        const response = await axios.post(BASE_URL_SANDBOX + DELETE_TO_WISHLIST, data, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        if (response.data.status === 200) {
            return {
                productId: data.product_id,
                message: response.data.message
            };
        }

    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        setWishlist: (state, action) => {
            state.wishlistData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWishlistData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchWishlistData.fulfilled, (state, action) => {
            state.loading = false;
            state.wishlistData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchWishlistData.rejected, (state, action) => {
            state.loading = false;
            state.wishlistData = {};
            state.error = action.error.message;
        })

        builder.addCase(addToWishlist.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addToWishlist.fulfilled, (state, action) => {
            state.loading = false;
            if(state?.wishlistData?.length >= 1 ){
                state.wishlistData = [...state?.wishlistData , action?.payload]
            } else{
                state.wishlistData = action?.payload
            }
           
            state.error = ''; // Reset the error message
        });
        
        builder.addCase(addToWishlist.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });






        builder.addCase(deleteToWishlist.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteToWishlist.fulfilled, (state, action) => {
            state.loading = false;
            state.wishlistData = state.wishlistData.filter((item) => item && item.id.toString() !== action.payload.productId);

            state.error = ''; // Reset the error message
        });
        builder.addCase(deleteToWishlist.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });


    }
})

export const { setWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer