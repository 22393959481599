import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseOrders from './CloseOrders';
import OpenOrders from './OpenOrders';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const OrderHistory = ({ mymerchantId, orderMethod }) => {
  const Navigate = useNavigate();
  const [visible, setVisible] = useState('openorders');
  const handleOpenOrder = () => {
    // console.log(handleOpenOrder);
    setVisible('openorders');
  };
  const handleCloseOrder = () => {
    setVisible('closeorders');
  };
  const { isAuthenticated } = useSelector((state) => state.authUser);
  useEffect(() => {
    if (!isAuthenticated) {
      const timeoutId = setTimeout(() => {
        // Check again if isAuthenticated is still false before redirecting
        if (!isAuthenticated) {
          Navigate('/customer-login');
        }
      }, 2000); // Adjust the delay time as needed

      // Clear the timeout if isAuthenticated changes before the timeout expires
      return () => clearTimeout(timeoutId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visible]);

  return (
    <>
      <div className="order-history-main-component">
        <div className="order-history-top-component">
          <h1>My Orders</h1>
          <div className="order-history-button-grp">
            <Button
              className={visible === 'openorders' ? 'openorders' : ''}
              onClick={handleOpenOrder}
            >
              Open Orders
            </Button>
            <Button
              className={visible === 'closeorders' ? 'closeorders' : ''}
              onClick={handleCloseOrder}
            >
              Closed Orders
            </Button>
          </div>
        </div>

        <div className="order-history-bottom-component">
          {visible === 'closeorders' && (
            <CloseOrders
              mymerchantId={mymerchantId}
              orderMethod={orderMethod}
            />
          )}
          {visible === 'openorders' && (
            <OpenOrders mymerchantId={mymerchantId} orderMethod={orderMethod} />
          )}
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
