import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_SANDBOX, SEARCH_PRODUCTS } from '../../../Constants/Config';

const initialState = {
  loading: false,
  searchedProducts: [],
  error: '',
};

export const getSearchedProducts = createAsyncThunk(
  'searchProducts/getSearchedProducts',
  async (data) => {
    try {
      if (data.keyword) {
        const body = {
          title: data.keyword,
          merchant_id: data.merchantId,
          order_method: data.orderType,
        };

        const response = await axios.post(
          BASE_URL_SANDBOX + SEARCH_PRODUCTS,
          body,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.status === 200) {
          return response.data.result ?? [];
        }
      } else {
        return [];
      }
    } catch (e) {
      console.log('error: ', e);
      return [];
    }
  },
);

const searchProducts = createSlice({
  name: 'searchProducts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSearchedProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchedProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.searchedProducts = action.payload;
      state.error = '';
    });
    builder.addCase(getSearchedProducts.rejected, (state, action) => {
      state.loading = false;
      state.searchedProducts = [];
      state.error = action.error.message;
    });
  },
});

export default searchProducts.reducer;
