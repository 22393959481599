import React, { useEffect, useState, useRef, useContext } from 'react';
import Quickvee from '../../Assets/LoginScreen/quickveeLogo.svg';
import { Button, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../redux/features/Authentication/Customer/authUserSlice';
import { setWishlist } from '../../redux/features/Wishlist/wishlistSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import SearchLocation from './SearchLocation';
import { setCartData } from '../../redux/features/Cart/cartSlice';
import { ContextSearch } from '../../Context/SearchContext';
import SearchBox from './SearchBox';
import QuickveeLogo from './../../Assets/Store/quickveeLogo.svg';
import QuickveeLogoSymbol from './../../Assets/Store/QuickveeLogoSymbol.svg';
import userLogo from './../../Assets/Store/Mask Group 5930.svg';
import Cookies from 'js-cookie';
const Header = ({
  filterProducts,
  setFilterProducts,
  checkCategoryProducts,
  setSearchTerm,
  searchTerm,
  setShowSearchResults,
  showSearchResults,
  forMobile,
}) => {
  const dispatch = useDispatch();
  const [showuserData, setShowuserData] = useState(false);
  const [userData, setUserData] = useState();
  const authUserData = useSelector((state) => state.authUser.UserData);
  const { isGuest } = useSelector((state) => state.authUser);
  const [navBar, setNavBar] = useState(true);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleLogout = () => {
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('radius');
    localStorage.removeItem('is_guest');
    localStorage.removeItem('guestDelivery_address');
    localStorage.removeItem('guestAddDeliveryAddress');
    Cookies.remove('userLoginData');
    Cookies.remove('is_guest');
    dispatch(setAuthUser(null));
    dispatch(setGuestLogin(''));
    dispatch(setAuthentication(false));
    dispatch(setAuthUser([]));
    dispatch(setWishlist([]));
    setUserData([]);
    dispatch(setCartData([]));
  };

  const [products, setProducts] = useState([]);
  const searchBoxRef = useRef(null);

  const [showLinkData, setShowLinkData] = useState(false);
  const allStoresDataState = useSelector(
    (state) => state.allstores.allStoresData,
  );

  useEffect(() => {
    if (allStoresDataState) {
      setProducts(
        Array.prototype.concat(
          allStoresDataState.default_category,
          allStoresDataState.default_store,
        ),
      );
    }
  }, [allStoresDataState]);

  const handleLinkDataVisible = (event) => {
    event.stopPropagation();
    setShowLinkData(!showLinkData);
    setShowuserData(false);
  };

  const handleLinkDataVisibleScreen = () => {
    setShowLinkData(false);
  };

  const handleProfileDataVisible = (event) => {
    event.stopPropagation();
    setShowuserData(!showuserData);
    setShowLinkData(false);
  };

  const handleProfileDataVisibleScreen = () => {
    setShowuserData(false);
  };

  useEffect(() => {
    if (authUserData && authUserData.id) {
      setUserData(authUserData);
    }
  }, [authUserData]);

  useEffect(() => {
    document.addEventListener('click', handleProfileDataVisibleScreen);
    document.addEventListener('click', handleLinkDataVisibleScreen);
    return () => {
      document.addEventListener('click', handleProfileDataVisibleScreen);
      document.addEventListener('click', handleLinkDataVisibleScreen);
    };
  }, []);

  const divScroll = () => {
    const mydiv = document.getElementById('home-search-data-box');
    mydiv?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    divScroll();
  }, [showSearchResults]);

  const searchProducts = (keyword) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    return products.filter((product) => {
      const lowerCaseTitle = product?.name.toLowerCase();
      return (
        lowerCaseTitle?.startsWith(lowerCaseKeyword) ||
        lowerCaseTitle?.includes(lowerCaseKeyword)
      );
    });
  };

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 1) {
      setFilterProducts(searchProducts(searchTerm));
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
        setSearchTerm('');
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setNavBar(window.innerWidth >= 769);
    const handleResize = () => {
      setNavBar(window.innerWidth >= 769);
      // setIsMobile(window.innerWidth <= 767)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="header_area">
        <div className="header_area_box">
          <div className="logo_area">
            <Link to="/">
              {navBar ? (
                <img
                  src={QuickveeLogo}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              ) : (
                <img
                  src={QuickveeLogoSymbol}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              )}
            </Link>
          </div>

          <SearchBox
            filterProducts={filterProducts}
            checkCategoryProducts={checkCategoryProducts}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showSearchResults={showSearchResults}
            setShowSearchResults={setShowSearchResults}
            forMobile={forMobile}
          />

          <div className="other-header-items">
            {/* <SearchLocation /> */}
            <div className="links-div">
              <Button
                className="top-cart-bar-button "
                style={{ marginLeft: '5px' }}
                onClick={handleLinkDataVisible}
              >
                Links <KeyboardArrowDownIcon />
              </Button>

              <div
                className="home-page-link-box"
                style={{ display: showLinkData ? 'flex' : 'none' }}
              >
                <Link
                  className="profile-bar-link"
                  target="_blank"
                  to="http://shop.quickvee.com/"
                >
                  Shop Quickvee
                </Link>
                <Link
                  className="profile-bar-link"
                  target="_blank"
                  to="https://info.quickvee.com/"
                >
                  Info Quickvee
                </Link>
              </div>
            </div>

            {userData && userData.id ? (
              <div className="quickvee-customer-login-main">
                <Link
                  className={!isMobile && 'quickvee-customer-login'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: ` 3px ${isMobile ? 0 : 15}px `,
                  }}
                  onClick={handleProfileDataVisible}
                >
                  {isMobile ? (
                    <img
                      style={{ width: '28px' }}
                      src={userLogo}
                      className="mobile-user-logo"
                      alt="userLogo"
                    />
                  ) : (
                    <p> {userData?.name.split(' ')[0]}</p>
                  )}

                  <span className="online-o-page-profile-drop-btn">
                    {!isMobile && <KeyboardArrowDownIcon />}
                  </span>
                </Link>
                <div
                  className="online-o-page-profile-box"
                  style={{ display: showuserData ? 'inline-table' : 'none' }}
                >
                  {isGuest !== 'yes' ? (
                    <>
                      <Link className="profile-bar-link" to="/order/profile">
                        My Profile
                      </Link>
                      <Link
                        className="profile-bar-link"
                        to="/order/order-history"
                      >
                        Order
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                  {/* <Link className="profile-bar-link" to="/order/profile">
                    My Profile
                  </Link>
                  <Link className="profile-bar-link" to="/order/order-history">
                    Order
                  </Link> */}

                  <div
                    className="profile-bar-link profile-bar-link-logout"
                    style={{
                      borderBottom: isGuest !== 'yes' ? 'none' : 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleLogout()}
                    id="Logout-btn"
                  >
                    Logout
                  </div>
                </div>
              </div>
            ) : (
              <Link
                to="/customer-login"
                state={{ merchantId: 'mymerchantId' }}
                className="quickvee-customer-login"
              >
                Log In
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
