import { Button } from '@mui/material'
import React from 'react'

const QButton = ({name , onClickHandle }) => {
  return (
    <div>
      <Button variant="contained" className='customer-btn' onClick={onClickHandle}  >{name}</Button>
    </div>
  )
}

export default QButton
