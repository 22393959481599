import { FormControl, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QButton from '../Main/QButton';
import MerchantLoginFormLogic from './MerchantLoginFormLogic';

const MerchantLoginForm = () => {
  const { handleMerchantLoginInput, values, handleMerchantLogin } =
    MerchantLoginFormLogic();
  const [show, setShow] = useState(false);

  return (
    <>
      <form className="login-customer-form">
        <FormControl className="login-customer-form" autoComplete="off">
          <h1>Merchant Login</h1>
          <span className="sub-heading-from">
            Manage your store efficiently!
          </span>
          <div className="input-outer-div">
            <TextField
              label="Email UserName"
              className="customer-login-input custom-input-field"
              id="outlined-size-small"
              variant="filled"
              size="small"
              autoComplete="off"
              name="username"
              value={values.username}
              onChange={handleMerchantLoginInput}
            />
            <span className="input-error">{values.errors.username}</span>
          </div>
          <div className="input-outer-div">
            <TextField
              className="password-input-field custom-input-field"
              label="Password"
              variant="filled"
              size="small"
              autoComplete="off"
              type={show === true ? 'text' : 'password'}
              onChange={handleMerchantLoginInput}
              name="password"
              value={values.password}
            ></TextField>
            <span className="input-error">{values.errors.password}</span>
            <span
              className="show-hide-button"
              onTouchStart={() => {
                setShow(true);
              }}
              onTouchEnd={() => setShow(false)}
              onMouseDown={() => {
                setShow(true);
              }}
              onMouseUp={() => setShow(false)}
            >
              {' '}
              {show === true && values.password.length > 0
                ? 'Hide'
                : 'Show'}{' '}
            </span>
          </div>
          <QButton
            onClickHandle={handleMerchantLogin}
            values={values}
            name={'Login'}
          />
        </FormControl>

        {/* sign in section */}
        <div className="login-customer-form-footer">
          <p className="login-customer-reduirection">
            Are you a Customer?
            <Link
              className="login-customer-reduirection-link"
              to="/customer-login"
            >
              {' '}
              Login{' '}
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default MerchantLoginForm;
