import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  DELETE_CARD,
  GET_ALL_USER_CARDS,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  userCards: [],
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchAllUserCards = createAsyncThunk(
  'userCards/fetchAllUserCards',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_ALL_USER_CARDS,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      if (response.data.status === 200) {
        // console.log('ghkdhfkg', response.data)
        return response.data.result;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
);

export const deleteSingleCard = createAsyncThunk(
  'userCards/deleteSingleCard',
  async (data) => {
    try {
      const response = await axios.post(BASE_URL_SANDBOX + DELETE_CARD, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // console.log(response)
      if (response.status === 200) {
        return data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
);

const userCardsSlice = createSlice({
  name: 'userCards',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAllUserCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUserCards.fulfilled, (state, action) => {
      state.loading = false;
      state.userCards = action.payload;
      state.error = '';
    });
    builder.addCase(fetchAllUserCards.rejected, (state, action) => {
      state.loading = false;
      state.userCards = {};
      state.error = action.error.message;
    });

    builder.addCase(deleteSingleCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSingleCard.fulfilled, (state, action) => {
      state.loading = false;
      state.userCards = state.userCards.filter(
        (item) => item.key !== action.payload.paymethod_key,
      );
      state.error = '';
    });
    builder.addCase(deleteSingleCard.rejected, (state, action) => {
      state.loading = false;
      state.userCards = [...state.userCards];
      state.error = action.error.message;
    });
  },
});

export default userCardsSlice.reducer;
