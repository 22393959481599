import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, FreeMode } from 'swiper/modules';
import Slider from 'react-slick';
import SingleProduct from '../SingleProduct';

const RecentlyViewedProducts = memo(
  ({ recentlyViewed, isMobile, merchantId, settings }) => {
    // console.log('hi from RecentlyViewedProducts');
    return (
      <>
        {recentlyViewed && recentlyViewed.length > 0 && (
          <div className="quickvee-highest-selling-product-section">
            <span>Recently Viewed Products </span>
            {isMobile ? (
              <Swiper
                slidesPerView={2}
                spaceBetween={8}
                // initialSlide={1}
                // centeredSlides={false}
                modules={[FreeMode, Virtual]}
                freeMode={true}
                scrollbar={{ draggable: true, dragSize: 24 }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  534: {
                    slidesPerView: 2.3,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  769: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                className="media-scroller-upper"
              >
                <div className="media-scroller">
                  {recentlyViewed.map((product, index) => (
                    <SwiperSlide className="media-element">
                      <SingleProduct
                        key={product.productId}
                        product={product.product}
                        categoryId={product.categoryId}
                        merchantId={merchantId}
                      />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            ) : (
              <Slider {...settings}>
                {recentlyViewed.map((product, index) => (
                  <SingleProduct
                    key={product.productId}
                    product={product.product}
                    categoryId={product.categoryId}
                    merchantId={merchantId}
                  />
                ))}
              </Slider>
            )}
          </div>
        )}
      </>
    );
  },
);

export default RecentlyViewedProducts;
