import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import SingleCategory from './SingleCategory';
import { useDispatch } from 'react-redux';
import { fetchAllStoresData } from '../../redux/features/Home/allStoresSlice';
import { fetchHomeStoresData } from '../../redux/features/Home/homeStoresSlice';
import SearchBox from './SearchBox';
import { useNavigate } from 'react-router-dom';
import { ContextSearch } from '../../Context/SearchContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrivacyPolicydetails from './PrivacyPolicydetails';
import TermConditions from './TermConditions';
import AboutDetails from './AboutDetails';
import { useLocation } from 'react-router-dom';

const Main = ({ visible }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:767px)');
  const location = useLocation();
  // console.log(location.pathname)

  const { fetchCategoryProducts, setNoDataFound } = useContext(ContextSearch);
  const [filterProducts, setFilterProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    dispatch(fetchAllStoresData());
    dispatch(fetchHomeStoresData());
  }, []);

  const checkCategoryProducts = async (categoryName) => {
    const responseData = await fetchCategoryProducts(categoryName);

    // console.log('responseData hi: ', responseData);
    if (responseData?.store_products?.length > 0) {
      navigate(
        `/category/${responseData?.smartsearch}?category=${responseData?.smartsearch}`,
      );
      setNoDataFound(false);
    } else {
      setNoDataFound(true);
    }
  };
  useEffect(() => {
    if (location.pathname == '/') {
      localStorage.removeItem('orderRecord');
      localStorage.removeItem('merchant_id');
    }
  }, [location.pathname]);

  return (
    <>
      {/* Main Header */}
      <Header
        filterProducts={filterProducts}
        setFilterProducts={setFilterProducts}
        checkCategoryProducts={checkCategoryProducts}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showSearchResults={showSearchResults}
        setShowSearchResults={setShowSearchResults}
        forMobile={isMobile}
      />

      <SearchBox
        filterProducts={filterProducts}
        checkCategoryProducts={checkCategoryProducts}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showSearchResults={showSearchResults}
        setShowSearchResults={setShowSearchResults}
        forMobile={!isMobile}
      />

      <div
        className={
          visible === 'AboutUs' ||
          visible === 'policy' ||
          visible === 'termconditions'
            ? ''
            : 'home-page-all-sections'
        }
      >
        {visible === 'home' && <Home />}
        {visible === 'category' && <SingleCategory />}
      </div>

      {visible === 'AboutUs' && <AboutDetails />}
      {visible === 'policy' && <PrivacyPolicydetails />}
      {visible === 'termconditions' && <TermConditions />}
      <Footer />
    </>
  );
};

export default Main;
