import { useState } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL_SANDBOX, CHANGE_PASSWORD } from '../../../Constants/Config';

const ChangePasswordFormLogic = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetKey = searchParams.get('vc');
  const { validatePassword, validateConfrimPassword } = Validation();
  const Navigate = useNavigate();
  const [submitmessage, setsubmitmessage] = useState('');
  const [alertStatus, setAlertStatus] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const PassNum = /\d/i;
  const PassUppercase = /^(?=.*[A-Z])/;
  const PassLowercase = /^(?=.*[a-z])/;
  const PassSpecCha = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const PassLeng = /^.{6,}$/i;
  const Space = /^[^\s]+$/i;

  const [values, setValues] = useState({
    password: '',
    confirmpassword: '',
    errors: {
      password: '',
      confirmpassword: '',
    },
    // cursorposition : {
    //   password: 0,
    //   confirmpassword: 0,
    // }
  });
  const CustomerChangePasswordInput = async (event) => {
    let { errors } = values;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    let { cursorposition } = values;

    const newCursorPosition = event.target.selectionStart;
    let myconfString = 'confirm password';
    switch (fieldName) {
      case 'password':
        // cursorposition.password = newCursorPosition;
        // await validatePassword(fieldValue, errors);
        if (fieldName === '') {
          errors.password = 'Please enter password';
        } else if (emoji.test(fieldName)) {
          errors.password = 'Emoji not allowed';
        } else if (!PassUppercase.test(fieldName)) {
          errors.password = 'Password should contain Uppercase letter';
        } else if (!PassLowercase.test(fieldName)) {
          errors.password = 'Password should contain Lowercase letter';
        } else if (!PassNum.test(fieldName)) {
          errors.password = 'Password should contain number';
        } else if (!PassSpecCha.test(fieldName)) {
          errors.password = 'Password should contain Special Character';
        } else if (!PassLeng.test(fieldName)) {
          errors.password = 'Length should be greater or equal to 6';
        } else if (!Space.test(fieldName)) {
          errors.password = 'Space is not allow';
        } else {
          errors.password = '';
        }

        break;
      case 'confirmpassword':
        // cursorposition.confirmpassword = newCursorPosition;
        // await validateConfrimPassword(fieldValue, values.password, myconfString,errors);
        if (fieldName === '') {
          errors.confirmpassword = `Please enter ${myconfString}`;
        } else if (emoji.test(fieldName)) {
          errors.confirmpassword = 'Emoji not allowed';
        } else if (fieldName !== values.password) {
          errors.confirmpassword = 'Not matching with password';
        } else {
          errors.confirmpassword = '';
        }
        break;
      default:
        break;
    }
    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    let { errors } = values;
    let myconfString = 'confirm password';

    await validatePassword(values.password, errors);
    await validateConfrimPassword(
      values.confirmpassword,
      values.password,
      myconfString,
      errors,
    );

    if (errors.password === '' && errors.confirmpassword === '') {
      const data = {
        new_password: values.password,
        confirm_password: values.confirmpassword,
        vc: resetKey,
      };

      // console.log(data)
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + CHANGE_PASSWORD,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        // console.log(response);
        if (response.data.status === false) {
          setsubmitmessage(response.data.message);
          setOpenAlert(true);
        } else {
          setsubmitmessage(response.data.message);
          setAlertStatus(response.data.status);
          setOpenAlert(true);
        }
      } catch (error) {
        return new Error(error);
      }
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  return {
    CustomerChangePasswordInput,
    handleSubmitChangePassword,
    values,
    submitmessage,
    openAlert,
    setOpenAlert,
    setAlertStatus,
    alertStatus,
  };
};

export default ChangePasswordFormLogic;
