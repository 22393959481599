import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_SINGLE_PRODUCT_NEW,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  singleProductData: {},
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchSingleProductData = createAsyncThunk(
  'singleProduct/fetchSingleProductData',
  async (data) => {
    const response = await axios.post(
      BASE_URL_SANDBOX + GET_SINGLE_PRODUCT_NEW,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    if (response.data.status === 200) {
      return response.data.result;
    }
  },
);

const SingleProductSlice = createSlice({
  name: 'singleProduct',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSingleProductData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingleProductData.fulfilled, (state, action) => {
      state.loading = false;
      state.singleProductData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchSingleProductData.rejected, (state, action) => {
      state.loading = false;
      state.singleProductData = {};
      state.error = action.error.message;
    });
  },
});

export default SingleProductSlice.reducer;
