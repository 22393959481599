import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CrossIcon from '../../../Assets/Store/cross.svg';
import DeliveryFeeIcon from '../../../Assets/Store/DeliveryFee.svg';
import DeliveryRangeIcon from '../../../Assets/Store/DeliveryRange.svg';
import DeliveryTimeIcon from '../../../Assets/Store/DeliveryTime.svg';
import MinimumOrderIcon from '../../../Assets/Store/MinimunOrder.svg';
import Validation from '../../../Constants/Validation';
import { Skeleton } from '@mui/material';
import CouponInfo from './../../../Assets/Store/CouponInfo.svg';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
const ViewFullHours = ({ SingleStoresDataState, orderMethod, isMobile }) => {
  const { convertToIndianTime, formatTimeRange } = Validation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const timerSelector = useSelector((state) => state.singleStores);

  const todayDay = (day_name) => {
    const time_zone1 = timerSelector?.storesData?.store_data?.mer_timeZone;
    const utcDateTime = DateTime.utc();
    const localDateTime = utcDateTime.setZone(time_zone1);
    const todayDayName = localDateTime.toLocaleString({ weekday: 'long' });

    let time_zone = null;

    for (const day of businessHourData) {
      if (day.day_name === todayDayName) {
        time_zone = day.day_name;
        break;
      }
    }
    if (time_zone == day_name) {
      return time_zone;
    }
  };

  //     const {open_time , close_time} = SingleStoresDataState?.storesData?.store_data?.time_slot
  // const formatedOpenCloseTime = formatTimeRange()
  const businessHourData =
    SingleStoresDataState?.storesData?.store_data?.time_slot;
  const storeData = SingleStoresDataState?.storesData?.store_data;

  return (
    <>
      <span className="view-full-hour-link" onClick={handleOpen}>
        {isMobile ? <img src={CouponInfo} /> : 'View full Hours'}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-view-full-hours-header"
        aria-describedby="modal-view-full-hours-description"
      >
        <div className="modal-view-full-hours-div">
          <div id="modal-view-full-hours-header">
            <span>Business Hours</span>
            <img
              src={CrossIcon}
              alt="cross-icon"
              onClick={handleClose}
              className="modal-close-icon"
              loading="lazy"
            />
          </div>

          <div
            id="modal-view-full-hours-description"
            className="modal-view-full-hours-description"
          >
            <div className="full-hours-data">
              {SingleStoresDataState?.loading ? (
                <tr>
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={570}
                    height={40}
                    style={{ margin: '10px ' }}
                  />
                </tr>
              ) : (
                businessHourData &&
                businessHourData.map((singleDay, index) => {
                  const formatedOpenCloseTime = formatTimeRange(
                    singleDay?.open_time,
                    singleDay?.close_time,
                  );

                  return (
                    <div
                      key={index}
                      className={
                        todayDay(singleDay?.day_name) ? 'view-hour-today' : ''
                      }
                    >
                      <span
                        className={
                          todayDay(singleDay?.day_name)
                            ? 'view-hour-today-span'
                            : ''
                        }
                        style={
                          singleDay?.is_holiday === '1'
                            ? { color: '#F55353' }
                            : {}
                        }
                      >
                        {singleDay?.day_name.substr(0, 3)}
                      </span>
                      {singleDay?.is_holiday === '1' ? (
                        <p>
                          {' '}
                          <span style={{ color: '#F55353' }}>
                            <div
                              className="single-time-slot"
                              style={
                                index % 2 !== 0
                                  ? { borderLeft: '4px' }
                                  : { borderLeft: '4px' }
                              }
                            >
                              {' '}
                              Closed{' '}
                            </div>
                          </span>{' '}
                        </p>
                      ) : (
                        <p>
                          <span style={{ textTransform: 'uppercase' }}>
                            {formatedOpenCloseTime &&
                              formatedOpenCloseTime?.length >= 1 &&
                              formatedOpenCloseTime.map((item, index) => (
                                <React.Fragment key={index}>
                                  <span
                                    className="single-time-slot"
                                    style={
                                      index % 2 !== 0
                                        ? { borderLeft: '4px' }
                                        : { borderLeft: '4px' }
                                    }
                                  >
                                    {item?.open_time}
                                    <span> - </span> {item?.close_time}
                                  </span>
                                  {formatedOpenCloseTime?.length === 1 ||
                                  index % 2 !== 0 ||
                                  formatedOpenCloseTime?.length - 1 === index
                                    ? ''
                                    : '|'}
                                </React.Fragment>
                              ))}
                          </span>
                        </p>
                      )}
                    </div>
                  );
                })
              )}
            </div>
            {orderMethod === 'pickup' ? (
              <div className="full-hours-data-detail">
                <div className="single-full-hour-data-detail">
                  <img
                    src={DeliveryTimeIcon}
                    alt="Delivery-time-icon"
                    loading="lazy"
                  />
                  <span>
                    {' '}
                    {storeData?.pickup_min_time} - {storeData?.pickup_max_time}{' '}
                    Minutes{' '}
                  </span>
                  <p>Pickup Time</p>
                </div>
              </div>
            ) : (
              <div className="full-hours-data-detail">
                <div className="single-full-hour-data-detail">
                  <img
                    src={DeliveryFeeIcon}
                    alt="Delivery-fee-icon"
                    loading="lazy"
                  />
                  <span>
                    ${' '}
                    {storeData &&
                    storeData?.delivery_fee &&
                    parseFloat(storeData?.delivery_fee) > 0
                      ? storeData?.delivery_fee
                      : '0.00'}{' '}
                  </span>
                  <p>Delivery Fee</p>
                </div>
                <div className="single-full-hour-data-detail">
                  <img
                    src={DeliveryTimeIcon}
                    alt="Delivery-time-icon"
                    loading="lazy"
                  />
                  <span>
                    {' '}
                    {storeData?.deliver_min_time} -{' '}
                    {storeData?.deliver_max_time} Minutes{' '}
                  </span>
                  <p>Delivery Time</p>
                </div>
                <div className="single-full-hour-data-detail">
                  <img
                    src={DeliveryRangeIcon}
                    alt="Delivery-range-icon"
                    loading="lazy"
                  />
                  <span> {storeData?.max_delivery_radius} Miles</span>
                  <p>Delivery Range</p>
                </div>
                <div className="single-full-hour-data-detail">
                  <img
                    src={MinimumOrderIcon}
                    alt="minimum-order-icon"
                    loading="lazy"
                  />
                  <span>$ {storeData?.min_delivery_amt} </span>
                  <p>Minimum Order</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewFullHours;
