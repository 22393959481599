import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_ALL_COUPON_LIST } from "../../../Constants/Config"

const initialState = {
    loading: false,
    couponListData: [],
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchCouponListData = createAsyncThunk('couponList/fetchCouponListData', async (data) => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_COUPON_LIST, data, { headers: { "Content-Type": "multipart/form-data" } })
        // console.log(response)
        if (response.data.status === 200) {
            const myresponse = {
                result: response.data.result,
                message: ""
            }
            return myresponse
        }
        else if (response.data.status === 400) {
            const myresponse = {
                result: "",
                message: response.data.message
            }
            return myresponse
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})



const couponListSlice = createSlice({
    name: 'couponList',
    initialState,
    // reducers: {
        
    // },

    extraReducers: (builder) => {
        builder.addCase(fetchCouponListData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchCouponListData.fulfilled, (state, action) => {
            state.loading = false;
            state.couponListData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchCouponListData.rejected, (state, action) => {
            state.loading = false;
            state.couponListData = {};
            state.error = action.error.message;
        })


    }
})


// export const { removeCoupon } = couponListSlice.actions;
export default couponListSlice.reducer