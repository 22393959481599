import { configureStore } from '@reduxjs/toolkit';
import singleStoresReducer from '../features/SingleStore/singleStoresSlice';
import singleStoreProductDataReducer from '../features/SingleStore/singleStoreProductDataSlice';
import authUserReducer from '../features/Authentication/Customer/authUserSlice';
import singleCategoryReducer from '../features/SingleStore/singleCategorySlice';
import variantReducer from '../features/SingleStore/variantSlice';
import wishlistReducer from '../features/Wishlist/wishlistSlice';
import singleProductReducer from '../features/singleProduct/singleProductSlice';
import featuredProductsReducer from '../features/singleProduct/featuredProductsSlice';
import highestSellingProductsReducer from '../features/singleProduct/highestSellingProductsSlice';
import cartReducer from '../features/Cart/cartSlice';
import variantCartReducer from '../features/Cart/variantCartSlice';
import allstoresReducer from '../features/Home/allStoresSlice';
import homestoresReducer from '../features/Home/homeStoresSlice';
import accountInfoReducer from '../features/Account/accountInfoSlice';
import searchCategoryReducer from '../features/SearchCategory/searchCategorySlice';
import storecreditlistReducer from '../features/StoreCreditList/scHistorySlice';
import loyaltyPointReducer from '../features/LoyaltyPoint/loyaltyPointSlice';
import orderSummeryReducer from '../features/orderSummeryHistory/orderSummerySlice';
import couponReducer from '../features/Coupon/couponSlice';
import couponListReducer from '../features/Coupon/couponListSlice';
import taxDetailReducer from '../features/Payment/taxDetailSlice';
import allStatesReducer from '../features/Home/allStatesSlice';
import userCardReducer from '../features/Account/userCardsSlice';
import deliveryAddressReducer from '../features/Account/deliveryAddressSlice';
import lpscPaymentReducer from '../features/LPSC_Payment/lpscPayment';
import cartProductDetailsReducer from '../features/Cart/cartProductDetailsSlice';
import searchProductsReducer from '../features/SingleStore/searchProducts';

const store = configureStore({
  reducer: {
    authUser: authUserReducer,
    singleStores: singleStoresReducer,
    storeProductData: singleStoreProductDataReducer,
    singleCategory: singleCategoryReducer,
    wishlist: wishlistReducer,
    singleProduct: singleProductReducer,
    featuredProducts: featuredProductsReducer,
    highestSellingProducts: highestSellingProductsReducer,
    cart: cartReducer,
    cartProducts: cartProductDetailsReducer,
    variantCart: variantCartReducer,
    variant: variantReducer,
    allstores: allstoresReducer,
    homeStores: homestoresReducer,
    accountInfo: accountInfoReducer,
    searchCategory: searchCategoryReducer,
    searchProducts: searchProductsReducer,
    storecreditlist: storecreditlistReducer,
    loyaltyPoint: loyaltyPointReducer,
    orderSummeryList: orderSummeryReducer,
    coupon: couponReducer,
    couponList: couponListReducer,
    taxDetail: taxDetailReducer,
    allStatesData: allStatesReducer,
    userCards: userCardReducer,
    deliveryAddress: deliveryAddressReducer,
    lpscPayment: lpscPaymentReducer,
  },
  // middleware:(getDefaultMiddleware)=> getDefaultMiddleware().concat(logger),
});

export default store;
