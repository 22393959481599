import React from 'react';
import Quickvee from '../../../Assets/LoginScreen/quickveeLogo.svg';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import LoginCustomerForm from '../Customer/LoginCustomerForm';
import ForgotPasswordForm from '../Customer/ForgotPasswordForm';
import RegisterCustomerForm from '../Customer/RegisterCustomerForm';
import MerchantLoginForm from '../Retailer/MerchantLoginForm';
import ChangePasswordForm from '../Customer/ChangePasswordForm';
import { Link } from 'react-router-dom';
import GuestLoginForm from '../GuestLogin/guestLogin';

const LeftBanner = ({ visible }) => {
  return (
    <div
      className={` login-customer-form ${
        visible === 'register' ? ' my-overflow-hanlde-for-authentication ' : ''
      }`}
    >
      {visible === 'forgot-password' && (
        <Link to="/customer-login" className="back-auth-icons">
          <ArrowBackSharpIcon />
        </Link>
      )}
      {visible === 'changePassword' && (
        <Link to="/forgot-password" className="back-auth-icons">
          {' '}
          <ArrowBackSharpIcon />{' '}
        </Link>
      )}
      <Link to="/">
        <img
          src={Quickvee}
          className="quickvee-logo-authentication"
          alt="Quickvee"
        />
      </Link>
      {visible === 'login' && <LoginCustomerForm />}
      {visible === 'forgot-password' && <ForgotPasswordForm />}
      {visible === 'register' && <RegisterCustomerForm />}
      {visible === 'guest-checkout' && <GuestLoginForm />}
      {visible === 'merchantlogin' && <MerchantLoginForm />}
      {visible === 'changePassword' && <ChangePasswordForm />}
    </div>
  );
};

export default LeftBanner;
