import { useState, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useNavigate } from 'react-router-dom';
import {
  UPDATE_BILLING_INFO,
  BASE_URL_SANDBOX,
} from '../../../Constants/Config';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import { useDispatch, useSelector } from 'react-redux';

const BillingInfoLogic = ({
  handleClose,
  userData,
  selectedImageBase64,
  setSelectedImageBase64,
}) => {
  const Dispatch = useDispatch();
  const { isGuest } = useSelector((state) => state.authUser);
  const {
    validateFirstName,
    validateLastName,
    validateStreetAddress,
    validateZipCode,
    validateCity,
    validateState,
    validateApartment,
    validatePhoneNumber,
    validateIDproof,
    validateDOB,
    validateExpiredate,
    validateImageProof,
    formatExireDate,
    getImageExtension,
    validateIdNumber,
  } = Validation();
  const Navigate = useNavigate();
  const [submitmessage, setsubmitmessage] = useState('');
  const [loading, setLoading] = useState('');
  const [values, setValues] = useState({
    // firstname: "",
    // lastname: "",
    // phone: "",
    streetaddress: '',
    apartmentnumber: '',
    city: '',
    state: '',
    zipcode: '',
    idproof: '',
    DOB: '',
    expiredate: '',
    myfile: '',
    idnumber: '',
    errors: {
      firstname: '',
      lastname: '',
      phone: '',
      streetaddress: '',
      apartmentnumber: '',
      city: '',
      state: '',
      zipcode: '',
      idproof: '',
      DOB: '',
      expiredate: '',
      myfile: '',
      idnumber: '',
    },
    // cursorposition: {
    //   streetaddress: 0,
    //   apartmentnumber: 0,
    //   city: 0,
    //   state: 0,
    //   zipcode: 0,
    //   idproof: 0,
    //   DOB: 0,
    //   expiredate: 0,
    //   myfile: 0,
    //   idnumber: 0,
    // },
  });

  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  const onlySingleSpace = /^\s*[a-zA-Z]+(\s[a-zA-Z]+)*\s*$/;
  const Nameval = /^\s*[a-zA-Z]+(\s+[a-zA-Z]+)*\s*$/;
  const Numberval = /^\d*$/;
  const Characterval = /^[A-Za-z\s]+$/;

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,

      streetaddress:
        userData && userData.a_address_line_1 ? userData.a_address_line_1 : '',
      apartmentnumber:
        userData && userData.a_address_line_2 ? userData.a_address_line_2 : '',
      city: userData && userData.a_city ? userData.a_city : '',
      state: userData && userData.a_state ? userData.a_state : '',
      zipcode: userData && userData.a_zip ? userData.a_zip : '',
      idproof: userData && userData.i_card_type ? userData.i_card_type : '',
      DOB: userData && userData.i_card_dob ? userData.i_card_dob : '',
      expiredate:
        userData && userData.i_card_ex_date ? userData.i_card_ex_date : '',
      myfile:
        userData && userData.i_card_front_img ? userData.i_card_front_img : '',
      idnumber:
        userData && userData.i_card_number ? userData.i_card_number : '',
    }));
  }, [userData]);

  const BillingInfoInput = async (event) => {
    let { errors } = values;
    const fieldName = event.target.name;
    let { cursorposition } = values;
    const newCursorPosition = event.target.selectionStart;

    const fieldValue =
      fieldName === 'phone' || fieldName === 'zipcode'
        ? event.target.value.replace(/[^0-9]/g, '')
        : event.target.value;
    switch (fieldName) {
      case 'firstname':
        if (fieldValue === '') {
          errors.firstname = 'Please enter First Name';
        } else if (emoji.test(fieldValue)) {
          errors.firstname = 'Emoji not allowed';
        } else if (!Nameval.test(fieldValue) && fieldValue) {
          errors.firstname = 'Alphabets only';
        } else if (!onlySingleSpace.test(fieldValue) && fieldValue) {
          errors.firstname = 'Multiple spaces not allowed';
        } else {
          errors.firstname = '';
        }
        break;
      case 'lastname':
        if (fieldValue === '') {
          errors.lastname = '';
        } else if (emoji.test(fieldValue)) {
          errors.lastname = 'Emoji not allowed';
        } else if (!Nameval.test(fieldValue)) {
          errors.lastname = 'Alphabets only';
        } else if (!onlySingleSpace.test(fieldValue) && fieldValue) {
          errors.lastname = 'Multiple spaces not allowed';
        } else {
          errors.lastname = '';
        }
        break;
      case 'phone':
        // await validatePhoneNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.phone = 'Please enter phone number';
        } else if (emoji.test(fieldValue)) {
          errors.phone = 'Emoji not allowed';
        } else if (!PhoneNo.test(fieldValue)) {
          errors.phone = 'Phone no not valid';
        } else {
          errors.phone = '';
        }
        break;
      case 'streetaddress':
        // cursorposition.streetaddress = newCursorPosition;
        // await validateStreetAddress(fieldValue, errors);
        if (fieldValue === '') {
          errors.streetaddress = 'Please enter street address ';
        } else if (emoji.test(fieldValue)) {
          errors.streetaddress = 'Emoji not allowed';
        } else {
          errors.streetaddress = '';
        }
        break;
      case 'apartmentnumber':
        // cursorposition.apartmentnumber = newCursorPosition;
        // await validateApartment(fieldValue, errors);
        if (fieldValue === '') {
          errors.apartmentnumber = 'Please enter Suite/Apartment Number ';
        } else if (emoji.test(fieldValue)) {
          errors.apartmentnumber = 'Emoji not allowed';
        } else {
          errors.apartmentnumber = '';
        }
        break;
      case 'city':
        // cursorposition.city = newCursorPosition;
        // await validateCity(fieldValue, errors);
        if (fieldValue === '') {
          errors.city = 'Please enter city ';
        } else if (emoji.test(fieldValue)) {
          errors.city = 'Emoji not allowed';
        } else if (!Characterval.test(fieldValue)) {
          errors.city = 'City  contain character';
        } else {
          errors.city = '';
        }
        break;
      case 'state':
        // cursorposition.state = newCursorPosition;
        // await validateState(fieldValue, errors);
        if (fieldValue === '') {
          errors.state = 'Please enter state ';
        } else if (emoji.test(fieldValue)) {
          errors.state = 'Emoji not allowed';
        } else {
          errors.state = '';
        }
        break;
      case 'zipcode':
        // cursorposition.zipcode = newCursorPosition;
        // await validateZipCode(fieldValue, errors);
        if (fieldValue === '') {
          errors.zipcode = 'Please enter zipcode  ';
        } else if (emoji.test(fieldValue)) {
          errors.zipcode = 'Emoji not allowed';
        } else if (fieldValue?.length !== 5) {
          errors.zipcode = 'Max 5 number enter';
        } else if (!Numberval.test(fieldValue)) {
          errors.zipcode = 'Zipcode contain number ';
        } else {
          errors.zipcode = '';
        }
        break;
      case 'idproof':
        await validateIDproof(fieldValue, errors);
        break;

      case 'DOB':
        await validateDOB(fieldValue, errors);
        break;
      case 'expiredate':
        await validateExpiredate(fieldValue, errors);
        break;
      case 'idnumber':
        // cursorposition.idnumber = newCursorPosition;
        // await validateIdNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.idnumber = 'Please enter ID number ';
        } else if (emoji.test(fieldValue)) {
          errors.idnumber = 'Emoji not allowed';
        } else {
          errors.idnumber = '';
        }
        break;
      default:
        break;
    }
    setValues((prevValues) => ({
      errors,

      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  function getFileExtension(filename) {
    const extension = filename.split('.').pop();
    return extension.toLowerCase();
  }
  const handleSubmitBillingInfo = async (e) => {
    e.preventDefault();

    let { errors } = values;
    // validateFirstName(values.firstname, errors);
    // validateLastName(values.lastname, errors);
    // validatePhoneNumber(values.phone, errors);
    validateCity(values.city, errors);
    validateStreetAddress(values.streetaddress, errors);
    validateState(values.state, errors);
    // validateApartment(values.apartmentnumber, errors);
    validateZipCode(values.zipcode, errors);
    validateIDproof(values.idproof, errors);
    validateDOB(values.DOB, errors);
    validateExpiredate(values.expiredate, errors);
    validateImageProof(values.myfile, errors);
    validateIdNumber(values.idnumber, errors);
    if (
      // errors.firstname === "" &&
      // errors.lastname === "" &&
      errors.state === '' &&
      errors.city === '' &&
      errors.zipcode === '' &&
      errors.streetaddress === '' &&
      // errors.phone === "" &&
      // errors.apartmentnumber === "" &&
      errors.idproof === '' &&
      errors.DOB === '' &&
      errors.expiredate === '' &&
      errors.myfile === '' &&
      errors.idnumber === ''
    ) {
      setLoading('Please wait while the Data Update.');
      const imagetype = selectedImageBase64
        ? getImageExtension(selectedImageBase64)
        : getFileExtension(values.myfile);

      let imageResponse = values.myfile; // old image filename

      if (selectedImageBase64) {
        // uploading image
        const res = await axios.post(
          'https://www.quickvees.com/upload.php',
          {
            image_data: selectedImageBase64,
            m_id: 'not available',
            imagetype,
            orderMethod: 'not available',
            cust_id: userData.id,
            source: 'checkout',
          },
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        imageResponse = res.data;
      }

      if (imageResponse !== 'fail') {
        const data = {
          user_id: userData.id,
          f_name: userData && userData.f_name ? userData.f_name : '',
          l_name: userData && userData.l_name ? userData.l_name : '',
          phone_no: userData && userData.phone ? userData.phone : '',
          cc_street_address: values.streetaddress,
          cc_zip_code: values.zipcode,
          city: values.city,
          card_number: values.idnumber,
          suite_address: values.apartmentnumber,
          state: values.state,
          cc_id_card_Type: values.idproof,
          dateOfBirth:
            formatExireDate(values.DOB) !== 'NaN-aN-aN'
              ? formatExireDate(values.DOB)
              : '',
          expiry_date: formatExireDate(values.expiredate),
          img_name: imageResponse,
          new_img: selectedImageBase64 ? selectedImageBase64 : '',
          old_img: values.myfile ? values.myfile : '',
          imagetype,
        };

        try {
          const response = await axios.post(
            BASE_URL_SANDBOX + UPDATE_BILLING_INFO,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
          if (response.data.status && response.data.status === 200) {
            let data = {
              id: userData && userData.id,
              merchant_id: '',
            };
            if (isGuest === 'yes') {
              data.is_guest = 'yes';
            }
            Dispatch(fetchAccoutInfoData(data));
            setSelectedImageBase64('');
            handleClose();
          } else {
            setsubmitmessage(response.data.message);
          }
          setLoading('');
        } catch (error) {
          console.log(error);
          // return new Error(error);
          setLoading('');
        }
      }
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  const uploadImage = async (user_id) => {
    try {
      const data = {
        // image_data,
        // m_id,
        // imagetype,
        // orderMethod,
        cust_id: user_id,
        // source,
      };
      const response = await axios.post(
        'https://www.quickvees.com/upload.php',
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
    } catch (error) {
      console.log(error);
      // return new Error(error);
    }
  };

  return {
    BillingInfoInput,
    handleSubmitBillingInfo,
    values,
    submitmessage,
    setValues,
    loading,
  };
};

export default BillingInfoLogic;
