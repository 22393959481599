import StoreImage from '../../Assets/Store/Store.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../Assets/Products/productDefaultIMage.webp';
import { BASE_URL_SANDBOX } from '../../Constants/Config';
import { useContext } from 'react';
import { ContextSearch } from '../../Context/SearchContext';

const OurStores = () => {
  const { checkOrderMethod } = useContext(ContextSearch);
  const allStoresStateData = useSelector(
    (state) => state.homeStores.homeStoresData.usersdata,
  );

  return (
    <>
      <div className="quickvee-home-stores">
        <h1>Our Stores</h1>
        <div className="container">
          <div className=" row justify-content-start">
            {allStoresStateData &&
              allStoresStateData.map((item, index) => (
                <div className=" col-6 col-xs-4 col-sm-4 col-md-3" key={index}>
                  <Link
                    className="container"
                    to={`/merchant/${
                      item.merchant_id
                    }?orderMethod=${checkOrderMethod(item)}`}
                  >
                    <div className="row justify-content-center">
                      <div
                        style={{ width: '130px' }}
                        className="q-store-image-section "
                      >
                        <img
                          onError={(e) => {
                            e.target.src = ProductDefaultImage;
                          }}
                          src={
                            item.img
                              ? `${BASE_URL_SANDBOX}upload/${item.img}`
                              : ProductDefaultImage
                          }
                          alt="store"
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center ">
                      <div className="q-store-content-section col-12">
                        <span> {item.name} </span>
                        <p>
                          {' '}
                          {item.a_address_line_1.length >= 1
                            ? item.a_address_line_1 + `,`
                            : ''}{' '}
                          {item.a_address_line_2.length >= 1
                            ? item.a_address_line_2 + `,`
                            : ''}{' '}
                          {item.a_city} {item.a_state} {item.a_zip}{' '}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="quickvee-home-all-stores">
          {allStoresStateData &&
            allStoresStateData.map((item, index) => (
              <div className="quickvee-home-single-store" key={index}>
                <Link
                  to={`/merchant/${
                    item.merchant_id
                  }?orderMethod=${checkOrderMethod(item)}`}
                >
                  <div className="q-store-image-section">
                    <img
                      onError={(e) => {
                        e.target.src = ProductDefaultImage;
                      }}
                      src={
                        item.img
                          ? `${BASE_URL_SANDBOX}upload/${item.img}`
                          : ProductDefaultImage
                      }
                      alt="store"
                    />
                  </div>
                  <div className="q-store-content-section">
                    <span> {item.name} </span>
                    <p>
                      {' '}
                      {item.a_address_line_1.length >= 1
                        ? item.a_address_line_1 + `,`
                        : ''}{' '}
                      {item.a_address_line_2.length >= 1
                        ? item.a_address_line_2 + `,`
                        : ''}{' '}
                      {item.a_city} {item.a_state} {item.a_zip}{' '}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
        </div> */}
      </div>
    </>
  );
};

export default OurStores;
