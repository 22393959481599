import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';
import Slider from '@mui/material/Slider';
import Edit_Id_Card from '../../../Assets/MyInformation/editIdCard.svg';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ImageCropModal = ({
  handleSelectImage,
  selectedImage,
  setSelectedImage,
  setOldSelectedImage,
  setImageName,
  userData,
  setSelectedImageBase64,
  payment,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [loadingmsg, setLoadingmsg] = useState(''); // State to manage loading state
  const handleOpen = async () => {
    setOpen(false);
    await handleSelectImage();
    if (selectedImage !== undefined || selectedImage !== null) {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const hanldeEditImage = async () => {
    setSelectedImage('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setOpen(false);
    await handleSelectImage();
    // console.log(selectedImage)
    if (selectedImage !== undefined || selectedImage !== null) {
      setOpen(true);
    }
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const Base64URLImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    setLoadingmsg('Please wait while the image loads.')
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(selectedImage),
        croppedAreaPixels,
        rotation,
      );

      setSelectedImage(croppedImage); //for croppedImage
      // setSelectedImage('');
      setOldSelectedImage(croppedImage);
      const base64String = await Base64URLImage(croppedImage);
      setSelectedImageBase64(base64String);
      setImageName(
        userData && userData.i_card_front_img
          ? userData.i_card_front_img
          : userData.f_name,
      );
      handleClose();
    } catch (e) {
      console.error(e);
    }
    setLoadingmsg('')
    setLoading(false);
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setSelectedImage('');
    handleClose();
  }, []);

  return (
    <div>
      {(selectedImage && selectedImage) ||
      (userData && userData.i_card_front_img && payment === 'account') ? (
          <div style={{ cursor: 'pointer' }} className="button-update-id-card" onClick={hanldeEditImage}>
          Edit <img src={Edit_Id_Card} />{' '}
        </div>
      ) : payment === 'payment' ? (
          <div style={{ cursor: 'pointer' }} className="button-update-id-card" onClick={hanldeEditImage}>
          Edit <img src={Edit_Id_Card} />{' '}
        </div>
      ) : (
            <div style={{ cursor: 'pointer' }}  className="button-update-id-card" onClick={handleOpen}>
          Upload ID Card
        </div>
      )}
      {selectedImage && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="basic-modal " style={{ left: '49.5%' }}>
              <div id="modal-modal-title">
                <span>Crop Image</span>
                <CloseIcon onClick={onClose} className="modal-close-icon" />
              </div>

              <form id="modal-modal-description" className="modal-forms">
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <Cropper
                        image={URL.createObjectURL(selectedImage)}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={3 / 2}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    
                    <div className="crop-image-modal-button">
                      <span className="image-motion-button">Crop</span>
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                      <span className="image-motion-button">Rotate</span>
                      <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(e, rotation) => setRotation(rotation)}
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                      />
                    </div>
                    <div style={{margin: "0.25rem auto"}} >
                      {loading && <CircularProgress />}
                      {loadingmsg && <span className="input-error"  style={{ position: 'relative',top:"-1rem" }}>{loadingmsg}</span>}
                    </div>
                    <div className="modal-footer">
                      <Button className="modal-cancle-btn" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button className="modal-save-btn" onClick={showCroppedImage}>
                        Save
                      </Button>
                    </div>
              </form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ImageCropModal;
