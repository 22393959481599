import React, { useState, useEffect } from 'react';
import SingleCloseOrder from './SingleCloseOrder';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_ALL_CLOSE_ORDERS,
} from '../../../Constants/Config';
import { useSelector } from 'react-redux';
import NoorderImage from '../../../Assets/OrderScreen/NoOrders.svg';
import { Button, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const CloseOrders = ({ mymerchantId, orderMethod }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [dataSource, setdataSource] = useState(Array.from({ length: 6 }));
  const [hasMore, setHasMore] = useState(true);
  const [allcloseorders, setAllCloseOrders] = useState([]);
  const authUserData = useSelector((state) => state.authUser);

  const [offset, setOffset] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [hideMessage, setHideMessage] = useState(false);

  const fectchAllSingleOrder = async () => {
    try {
      const data = {
        id: authUserData.UserData.id,
        limit: 10,
        offset,
      };
      setButtonLoading(true);
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_ALL_CLOSE_ORDERS,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      if (
        response.data.status === 200 &&
        Array.isArray(response.data.message) &&
        response.data.message.length > 0
      ) {
        setAllCloseOrders([...allcloseorders, ...response.data.message]);
        setOffset((prev) => prev + 10);

        if (response.data.message.length < 10) {
          setHasMore(false);
          setHideMessage(true);
        }
      }

      if (response.data.status === 400) {
        setHasMore(false);
      }
    } catch (error) {
      console.log('error: ', error);
      return new Error(error);
    } finally {
      setIsLoading(false);
      setButtonLoading(false);
    }
  };

  const fetchData = async () => {
    await fectchAllSingleOrder();
    const orderLength = allcloseorders && allcloseorders.length;
    // console.log(orderLength)
    if (dataSource.length < orderLength) {
      setTimeout(() => {
        setdataSource(dataSource.concat(Array.from({ length: 5 })));
      }, 1000);
    } else {
      setHasMore(false);
    }
  };
  // useEffect(() => {
  //   fectchAllSingleOrder();
  // }, [authUserData]);
  useEffect(() => {
    if (authUserData && authUserData.UserData && authUserData.UserData.id) {
      fectchAllSingleOrder();
    }
  }, [authUserData]);

  useEffect(() => {
    console.log('allcloseorders: ', allcloseorders);
  }, [allcloseorders]);

  return (
    <>
      {/* <InfiniteScroll
        dataLength={dataSource.length}
        next={fetchData}
        hasMore={hasMore}
        loader={allcloseorders && allcloseorders.length >= 1 ? '' : ''}
        endMessage={
          hasMore ? (
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          ) : (
            ''
          )
        }
      >
        <Grid container justifyContent={'start'} spacing={2}>
          {allcloseorders && allcloseorders.length >= 1 ? (
            allcloseorders.map((order, index) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={order.id}>
                  {isLoading ? (
                    <h1>Loading...</h1>
                  ) : (
                    <SingleCloseOrder Order={order} orderMethod={orderMethod} />
                  )}{' '}
                </Grid>
              );
            })
          ) : (
            <>
              {isLoading ? (
                <div className="no-open-and-close-order">
                  <h1> Loading...</h1>
                </div>
              ) : (
                <div className="no-open-and-close-order">
                  <img src={NoorderImage} alt="No-orders" />
                  <h1>NO ORDER HISTORY</h1>
                  <p className="mb-3">
                    You Don't Have any orders in your History
                  </p>
                  <Link
                    to={
                      mymerchantId
                        ? `/merchant/${mymerchantId}?orderMethod=${orderMethod}`
                        : '/'
                    }
                  >
                    {' '}
                    <Button className="start-shopping-on-order">
                      {' '}
                      Start Shopping{' '}
                    </Button>{' '}
                  </Link>
                </div>
              )}
            </>
          )}
        </Grid>
      </InfiniteScroll> */}
      <Grid container justifyContent={'start'} spacing={2}>
        {allcloseorders && allcloseorders.length >= 1 ? (
          allcloseorders.map((order, index) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={order.id}>
                {isLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <SingleCloseOrder Order={order} orderMethod={orderMethod} />
                )}{' '}
              </Grid>
            );
          })
        ) : (
          <>
            {isLoading ? (
              <div className="no-open-and-close-order">
                <h1> Loading...</h1>
              </div>
            ) : (
              <div className="no-open-and-close-order">
                <img src={NoorderImage} alt="No-orders" />
                <h1>NO ORDER HISTORY</h1>
                <p className="mb-3">
                  You Don't Have any orders in your History
                </p>
                <Link
                  to={
                    mymerchantId
                      ? `/merchant/${mymerchantId}?orderMethod=${orderMethod}`
                      : '/'
                  }
                >
                  {' '}
                  <Button className="start-shopping-on-order">
                    {' '}
                    Start Shopping{' '}
                  </Button>{' '}
                </Link>
              </div>
            )}
          </>
        )}
      </Grid>

      {allcloseorders.length <= 0 ? null : !hasMore ? (
        hideMessage ? null : (
          <p className="mt-2">
            {allcloseorders.length > 0
              ? 'No more orders found!'
              : 'No orders found!'}
          </p>
        )
      ) : allcloseorders.length < 10 ? null : (
        <button
          className="load-more-btn mt-2"
          onClick={fectchAllSingleOrder}
          disabled={isLoading || buttonLoading}
        >
          {isLoading || buttonLoading ? <CircularProgress /> : 'Load more'}
        </button>
      )}
    </>
  );
};

export default CloseOrders;
