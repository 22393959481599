import React, { useState, useEffect, useRef } from 'react';
import { UseSelector } from 'react-redux/es/hooks/useSelector';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import OrderHistoryIcon from '../../../Assets/OrderScreen/OrderHistoryIcon.svg';
import ProfileIcon from '../../../Assets/MyInformation/Account-Info.svg';
import LoyaltyPointIcon from '../../../Assets/OrderScreen/LoyaltyPointsZoneIcon.svg';
import HomeIcon from '../../../Assets/OrderScreen/HomeIcon.svg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cancleBtn from './../../../Assets/Store/cross.svg';
import './../../../Styles/customSingleStore.css';
const SideBar = ({
  mymerchantId,
  orderMethod,
  navBar,
  handleNavClick,
  handleOverlayClickClose,
}) => {
  const location = useLocation();
  let Navigate = useNavigate();
  const currentUrl = location.pathname;
  let homeclass;
  let orderhistoryclass;
  let accountinfoclass;
  let loyaltyclass;
  let storecreditclass;

  if (currentUrl === '/order/order-history') {
    orderhistoryclass = 'orderhistoryclass';
  } else if (currentUrl === '/order/profile') {
    accountinfoclass = 'accountinfoclass';
  } else if (currentUrl === '/order/loyaltypointzone') {
    loyaltyclass = 'accountinfoclass';
  } else if (currentUrl === '/order/storecreditzone') {
    storecreditclass = 'accountinfoclass';
  } else {
    homeclass = 'homeclass';
  }


  const sidebarRef = useRef(null);
  useEffect(() => {
    // Set initial width of the sidebar based on the navBar prop
    sidebarRef.current.style.left = navBar ? '0px' : '-250px';
  }, [navBar]);
  
  let merchant_store_id=localStorage.getItem('merchant_id')
  let order_store_method=localStorage.getItem('orderRecord');
  // console.log(merchant_store_id)merchant_store_id,
  // console.log(order_store_method)
  // const handleNavigateHome=()=>{
  //    if(merchant_store_id !==undefined && merchant_store_id !==null && order_store_method !== undefined && order_store_method !==null)
  //    {
  //     Navigate(`/merchant/${merchant_store_id}?orderMethod=${order_store_method}`)
  //    }else{
  //     Navigate("/")
  //    }
  // }

  return (
    <>
      <div className="order-side-bar-component" ref={sidebarRef}>
        <div className="cancleBtn-div-main-order">
          <div>
            <img
              onClick={() => {
                handleNavClick();
                handleOverlayClickClose();
              }}
              src={cancleBtn}
              alt="cancelbtn"
            />
          </div>
        </div>
        {/* <Link className='order-side-bar-link ' to="/" > <p className='link-home'></p> <span  >Home</span> </Link> */}
        {/* <Link className='order-side-bar-link'><p className='link-reorder'></p><span>Reorder</span> </Link>
        <Link className='order-side-bar-link'><p className='link-wishlist'></p><span>Wishlist</span></Link> */}
        <div>
          <Link
            to={
              merchant_store_id !==undefined && merchant_store_id !==null && merchant_store_id!=="" && order_store_method !== undefined && order_store_method !==null && order_store_method !==""
                ? `/merchant/${merchant_store_id}?orderMethod=${order_store_method}`
                : '/'
            }
            // onClick={handleNavigateHome}
            className={`order-side-bar-link-div ${homeclass}`}
          >
            <img
              src={HomeIcon}
              alt="Home"
              style={{ margin: '0 11px 0 0px ' }}
            />
            Home{' '}
          </Link>

          <Link
            onClick={() => {
              handleNavClick();
              handleOverlayClickClose();
            }}
            to="/order/order-history"
            state={{ merchantId: merchant_store_id,orderMethod:order_store_method }}
            className={`order-side-bar-link-div ${orderhistoryclass}`}
          >
            <img
              src={OrderHistoryIcon}
              alt="Order-History"
              style={{ margin: '0 8px 0 0' }}
            />
            Order History{' '}
          </Link>
          <Link
            onClick={() => {
              handleNavClick();
              handleOverlayClickClose();
            }}
            to="/order/profile"
            state={{ merchantId: merchant_store_id,orderMethod:order_store_method }}
            className={`order-side-bar-link-div ${accountinfoclass}`}
          >
            <img
              src={ProfileIcon}
              alt="Profile"
              style={{ margin: '0 8px 0 0' }}
            />
            Profile{' '}
          </Link>
          {merchant_store_id ? (
            <>
              <Link
                onClick={() => {
                  handleNavClick();
                  handleOverlayClickClose();
                }}
                to="/order/storecreditzone"
                state={{ merchantId: merchant_store_id,orderMethod:order_store_method }}
                className={`order-side-bar-link-div ${storecreditclass}`}
              >
                <img
                  src={LoyaltyPointIcon}
                  alt="Profile"
                  style={{ margin: '0 8px 0 0' }}
                />
                Store Credit Zone{' '}
              </Link>
              <Link
                onClick={() => {
                  handleNavClick();
                  handleOverlayClickClose();
                }}
                to="/order/loyaltypointzone"
                state={{ merchantId: merchant_store_id,orderMethod:order_store_method  }}
                className={`order-side-bar-link-div ${loyaltyclass}`}
              >
                <img
                  src={LoyaltyPointIcon}
                  alt="Profile"
                  style={{ margin: '0 8px 0 0' }}
                />
                Loyalty Points Zone{' '}
              </Link>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
