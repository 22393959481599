import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import {BASE_URL_SANDBOX , GET_HOME_STORES_AND_CATEGORIES } from "../../../Constants/Config"

const initialState = {
    loading: false,
    homeStoresData: [],
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchHomeStoresData = createAsyncThunk('homestores/fetchAllStoresData', async () => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX  + GET_HOME_STORES_AND_CATEGORIES,  { headers: { "Content-Type": "multipart/form-data" } })
        if(response.data.status === 200){
            return response.data.message

        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


const homeStoresSlice = createSlice({
    name: 'homestores',
    initialState,
      extraReducers: (builder) => {
        builder.addCase(fetchHomeStoresData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchHomeStoresData.fulfilled, (state, action) => {
            state.loading = false;
            state.homeStoresData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchHomeStoresData.rejected, (state, action) => {
            state.loading = false;
            state.homeStoresData = {};
            state.error = action.error.message;
        })
               
    }
})


export default homeStoresSlice.reducer