import React from 'react';
import ErrorBannerBackground from './../../Assets/PageNotFoundImages/Dab-Rigs.png';
import ErrorBannerLeft from './../../Assets/PageNotFoundImages/banner_left.webp';
import ErrorBannerRight from './../../Assets/PageNotFoundImages/banner_right.webp';
import PageNotFoundImage from '../../Assets/PageNotFoundImages/404.svg';
const PageNotFound = () => {
  return (
    <div className="error_banner_area">
      <image src={ErrorBannerLeft} />
      <div className="error_banner_left"></div>
      <div className="error_banner_right"></div>
      <div className="error_banner_center ">
        <img src={PageNotFoundImage} alt="404" />
        <p>
          The page you are looking for has been moved or does not exist anymore.
        </p>
        <p>
          Go back to <a href="https://quickve.com/">www.quickvee.com</a>
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
