import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import DeliveryAddressLogic from './DeliveryAddressLogic';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const DeliveryAddress = ({ checkradius, setDeliveryAddress }) => {
  const { isGuest } = useSelector((state) => state.authUser);
  const location = useLocation();
  const currentURL = location.pathname;
  const inputRefs = useRef({});
  const deliveryGuestAddress = useSelector(
    (state) => state.deliveryAddress.addresses,
  );
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setsubmitmessage('');
    values.errors.firstname = '';
    values.errors.lastname = '';
    values.errors.state = '';
    values.errors.city = '';
    values.errors.phone = '';
    values.errors.streetaddress = '';
    values.errors.apartmentnumber = '';
    values.errors.city = '';
    values.errors.zipcode = '';
  };
  const handleOnChangeSubmitMessage = () => {
    // console.log('onChangeSubmitMessage');
    setsubmitmessage('');
  };
  const {
    DeliveryAddressInput,
    handleSubmitDeliveryAddress,
    values,
    submitmessage,
    setsubmitmessage,
    open,
    setOpen,
    handlekeydown,
    setValues,
    isLoading,
  } = DeliveryAddressLogic({ checkradius, setDeliveryAddress });

  // setTimeout(() => {
  //   setsubmitmessage('');
  // }, 8000);

  useEffect(() => {
    if (isGuest === 'yes' && currentURL !== '/order/profile') {
      if (deliveryGuestAddress && deliveryGuestAddress.length >= 1) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isGuest === 'yes' && currentURL !== '/order/profile') {
      if (
        (deliveryGuestAddress && deliveryGuestAddress.length >= 1) ||
        (localStorage.getItem('guestAddDeliveryAddress') !== '' &&
          localStorage.getItem('guestAddDeliveryAddress') !== null)
      ) {
        // console.log(localStorage.getItem('guestAddDeliveryAddress'));
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  const [checkdelivery, setCheckDelivery] = React.useState(true);

  const handleBillingAddressSame = () => {
    setCheckDelivery(!checkdelivery);
    // console.log(checkdelivery);
    // console.log(values);
    if (checkdelivery) {
      let deliveryAddress = JSON.parse(
        localStorage.getItem('guestDelivery_address'),
      );
      setValues({
        ...values,
        firstname: deliveryAddress?.f_name,
        lastname: deliveryAddress?.l_name,
        phone: deliveryAddress?.phone_no,
        streetaddress: deliveryAddress?.cc_street_address,
        apartmentnumber: deliveryAddress?.suite_address,
        city: deliveryAddress?.city,
        state: deliveryAddress?.state,
        zipcode: deliveryAddress?.cc_zip_code,
      });
    }
  };

  return (
    <>
      <div>
        {isGuest !== 'yes' ? (
          <div
            style={{ cursor: 'pointer' }}
            className="modal-main-button"
            onClick={handleOpen}
          >
            Add New Address
          </div>
        ) : (
          ''
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={isGuest === 'yes'}
          BackdropProps={
            isGuest === 'yes' ? { style: { pointerEvents: 'none' } } : {}
          }
        >
          <div className="basic-modal">
            <div id="modal-modal-title">
              <span>Add Address</span>
              {isGuest !== 'yes' ? (
                <CloseIcon onClick={handleClose} className="modal-close-icon" />
              ) : (
                ''
              )}
            </div>

            <div
              className="billing-checkbox-copy"
              style={{ padding: '15px', width: '100%', paddingBottom: '0px' }}
            >
              <div className="row">
                <div className="colxs-12 col-sm-12 col-md-12">
                  {isGuest === 'yes' ? (
                    <FormControlLabel
                      label="Delivery address is same as Billing Address."
                      control={<Checkbox onClick={handleBillingAddressSame} />}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <form
              id="modal-modal-description"
              className="modal-forms new__input"
            >
              <div className="row">
                <div
                  className="colxs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="First Name"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      autoComplete="off"
                      name="firstname"
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.firstname}
                      // inputProps={{
                      //   'data-field': 'firstname',
                      //   autoComplete: 'off',
                      //   ref: (input) =>
                      //     (inputRefs.current['firstname'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.firstname,
                      // }}
                    />
                  </FormControl>
                  <span className="input-error">{values.errors.firstname}</span>
                </div>
                <div
                  className="colxs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label=" Last Name"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      autoComplete="off"
                      name="lastname"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.lastname}
                      // inputProps={{
                      //   'data-field': 'lastname',
                      //   autoComplete: 'off',
                      //   ref: (input) => (inputRefs.current['lastname'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.lastname,
                      // }}
                    />
                  </FormControl>
                  <span className="input-error">{values.errors.lastname}</span>
                </div>
              </div>

              {/* <div className="delivery-address-update-form">
                <FormControl>
                  <div className="modal-form-single-item">
                    <TextField
                      label="First Name"
                      className="modal-form-single-input"
                      id="outlined-size-small"
                      variant="filled"
                      size="small"
                      autoComplete="off"
                      name="firstname"
                      onChange={(e)=>{DeliveryAddressInput(e);submitmessage && handleOnChangeSubmitMessage()}}
                      value={values.firstname}
                      inputProps={{
                        'data-field': 'firstname',
                        autoComplete: 'off',
                        ref: (input) =>
                          (inputRefs.current['firstname'] = input), // Store the ref in a ref object
                        selectionstart: values.cursorposition.firstname,
                      }}
                    />
                    <span className="input-error">
                      {values.errors.firstname}
                    </span>
                  </div>
                </FormControl>
                <FormControl>
                  <div className="modal-form-single-item">
                    <TextField
                      label=" Last Name"
                      className="modal-form-single-input"
                      id="outlined-size-small"
                      variant="filled"
                      size="small"
                      autoComplete="off"
                      name="lastname"
                      // onChange={DeliveryAddressInput}
                      onChange={(e)=>{DeliveryAddressInput(e);submitmessage && handleOnChangeSubmitMessage()}}
                      value={values.lastname}
                      inputProps={{
                        'data-field': 'lastname',
                        autoComplete: 'off',
                        ref: (input) => (inputRefs.current['lastname'] = input), // Store the ref in a ref object
                        selectionstart: values.cursorposition.lastname,
                      }}
                    />
                    <span className="input-error">
                      {values.errors.lastname}
                    </span>
                  </div>
                </FormControl>
              </div> */}

              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      // style={{marginBottom:"15px"}}
                      label="Phone Number"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      name="phone"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.phone}
                      inputProps={{
                        maxLength: 10,
                        // 'data-field': 'phone',
                        // autoComplete: 'off',
                        // ref: (input) => (inputRefs.current['phone'] = input), // Store the ref in a ref object
                        // selectionstart: values.cursorposition.phone,
                      }}
                    />
                  </FormControl>
                  <span className="input-error">{values.errors.phone}</span>
                </div>
              </div>
              {/* <FormControl style={{ marginBottom: '15px' }}>
                <div className="modal-form-single-item">
                  <TextField
                    // style={{marginBottom:"15px"}}
                    label="Phone Number"
                    className="modal-form-single-input"
                    id="outlined-size-small"
                    variant="filled"
                    size="small"
                    name="phone"
                    // onChange={DeliveryAddressInput}
                    onChange={(e) => {
                      DeliveryAddressInput(e);
                      submitmessage && handleOnChangeSubmitMessage();
                    }}
                    value={values.phone}
                    inputProps={{
                      maxLength: 10,
                      'data-field': 'phone',
                      autoComplete: 'off',
                      ref: (input) => (inputRefs.current['phone'] = input), // Store the ref in a ref object
                      selectionstart: values.cursorposition.phone,
                    }}
                  />
                  <span className="input-error">{values.errors.phone}</span>
                </div>
              </FormControl> */}

              <div className="row">
                <div
                  className="colxs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Street Address"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      autoComplete="off"
                      name="streetaddress"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.streetaddress}
                      // inputProps={{
                      //   'data-field': 'streetaddress',
                      //   autoComplete: 'off',
                      //   ref: (input) =>
                      //     (inputRefs.current['streetaddress'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.streetaddress,
                      // }}
                    />
                  </FormControl>
                  <span className="input-error">
                    {values.errors.streetaddress}
                  </span>
                </div>

                <div
                  className="colxs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label=" Suite/Apartment Number"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      autoComplete="off"
                      name="apartmentnumber"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.apartmentnumber}
                      // inputProps={{
                      //   'data-field': 'apartmentnumber',
                      //   autoComplete: 'off',
                      //   ref: (input) =>
                      //     (inputRefs.current['apartmentnumber'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.apartmentnumber,
                      // }}
                    />
                  </FormControl>
                  <span className="input-error">
                    {/* {values.errors.apartmentnumber} */}
                  </span>
                </div>
              </div>
              {/* <div className="delivery-address-update-form">
                <FormControl>
                  <div className="modal-form-single-item">
                    <TextField
                      label="Street Address"
                      className="modal-form-single-input"
                      id="outlined-size-small"
                      variant="filled"
                      size="small"
                      autoComplete="off"
                      name="streetaddress"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.streetaddress}
                      inputProps={{
                        'data-field': 'streetaddress',
                        autoComplete: 'off',
                        ref: (input) =>
                          (inputRefs.current['streetaddress'] = input), // Store the ref in a ref object
                        selectionstart: values.cursorposition.streetaddress,
                      }}
                    />
                    <span className="input-error">
                      {values.errors.streetaddress}
                    </span>
                  </div>
                </FormControl>
                <FormControl>
                  <div className="modal-form-single-item">
                    <TextField
                      label=" Suite/Apartment Number"
                      className="modal-form-single-input"
                      id="outlined-size-small"
                      variant="filled"
                      size="small"
                      autoComplete="off"
                      name="apartmentnumber"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.apartmentnumber}
                      inputProps={{
                        'data-field': 'apartmentnumber',
                        autoComplete: 'off',
                        ref: (input) =>
                          (inputRefs.current['apartmentnumber'] = input), // Store the ref in a ref object
                        selectionstart: values.cursorposition.apartmentnumber,
                      }}
                    />
                    <span className="input-error">
                      {values.errors.apartmentnumber}
                    </span>
                  </div>
                </FormControl>
              </div> */}
              <div className="row" style={{ position: 'relative' }}>
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="City"
                      className="input-field"
                      id="outlined-size-small"
                      variant="outlined"
                      // size="small"
                      autoComplete="off"
                      name="city"
                      // onChange={DeliveryAddressInput}
                      onChange={(e) => {
                        DeliveryAddressInput(e);
                        submitmessage && handleOnChangeSubmitMessage();
                      }}
                      value={values.city}
                      // inputProps={{
                      //   'data-field': 'city',
                      //   autoComplete: 'off',
                      //   ref: (input) => (inputRefs.current['city'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.city,
                      // }}
                    />
                    <span style={{ left: '0' }} className="input-error">
                      {values.errors.city}
                    </span>
                  </FormControl>
                </div>
                <div
                  className=" col-sm-12 col-md-6"
                  style={{
                    position: 'relative',
                  }}
                >
                  <div className="row">
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                      style={{
                        position: 'relative',
                        marginBottom: '24px',
                      }}
                    >
                      <FormControl
                        className="form-select-formcontrol"
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          State
                        </InputLabel>
                        <Select
                          className="select-OnFocus"
                          labelId="demo-simple-select-label"
                          variant="outlined"
                          id="demo-simple-select"
                          name="state"
                          label="State"
                          // onChange={DeliveryAddressInput}
                          onChange={(e) => {
                            DeliveryAddressInput(e);
                            submitmessage && handleOnChangeSubmitMessage();
                          }}
                          value={values.state}
                        >
                          {Array.isArray(StateList) &&
                            StateList.length >= 1 &&
                            StateList.map((item, index) => (
                              <MenuItem key={index} value={item.State}>
                                {item.State}
                              </MenuItem>
                            ))}
                        </Select>
                        <span style={{ left: '0' }} className="input-error">
                          {values.errors.state}
                        </span>
                      </FormControl>
                    </div>
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                      style={{
                        position: 'relative',
                        marginBottom: '24px',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          label="Zip Code"
                          className="input-field"
                          id="outlined-size-small"
                          variant="outlined"
                          // size="small"
                          autoComplete="off"
                          name="zipcode"
                          maxlength="5"
                          onKeyDown={handlekeydown}
                          // onChange={DeliveryAddressInput}
                          onChange={(e) => {
                            DeliveryAddressInput(e);
                            submitmessage && handleOnChangeSubmitMessage();
                          }}
                          value={values.zipcode}
                          inputProps={{
                            maxLength: 5,
                            // 'data-field': 'zipcode',
                            // autoComplete: 'off',
                            // ref: (input) =>
                            //   (inputRefs.current['zipcode'] = input), // Store the ref in a ref object
                            // selectionstart: values.cursorposition.zipcode,
                          }}
                        />
                        <span style={{ left: '0' }} className="input-error">
                          {values.errors.zipcode}
                        </span>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              {submitmessage ? (
                <div className="row" style={{ position: 'relative' }}>
                  <div className="col-md-12">
                    <span
                      className=""
                      style={{
                        fontFamily: 'QuicksandSemiBold',
                        fontSize: '11px',
                        color: '#fa0000',
                      }}
                    >
                      {submitmessage}
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="modal-footer">
                {isGuest !== 'yes' ? (
                  <Button className="modal-cancle-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  className="modal-save-btn"
                  onClick={handleSubmitDeliveryAddress}
                  disabled={isLoading}
                  style={isGuest === 'yes' ? { margin: '0 auto' } : {}}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeliveryAddress;
