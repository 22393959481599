import { useState } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import {
  ACCOUNT_CHANGE_PASSWORD,
  BASE_URL_SANDBOX,
} from '../../../Constants/Config';
import { useSelector } from 'react-redux';

const ChangePasswordLogic = ({ handleClose }) => {
  const { validatePassword, validateConfrimPassword, validateOldPassword } =
    Validation();
  const [submitmessage, setsubmitmessage] = useState('');
  const authUserData = useSelector((state) => state.authUser);
  const [values, setValues] = useState({
    oldpassword: '',
    password: '',
    confirmpassword: '',
    errors: {
      oldpassword: '',
      password: '',
      confirmpassword: '',
    },
    // cursorposition: {
    //   oldpassword: 0,
    //   password: 0,
    //   confirmpassword: 0,
    // },
  });

  const PassNum = /\d/i;
  const PassUppercase = /^(?=.*[A-Z])/;
  const PassLowercase = /^(?=.*[a-z])/;
  const PassSpecCha = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const PassLeng = /^.{6,}$/i;
  const Space = /^[^\s]+$/i;

  const ChangePasswordInput = async (event) => {
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    let myconfString = 'confirm password';

    const newCursorPosition = event.target.selectionStart;

    switch (fieldName) {
      case 'oldpassword':
        // cursorposition.oldpassword = newCursorPosition;
        // await validateOldPassword(fieldValue, errors);
        if (fieldValue === '') {
          errors.oldpassword = 'Please enter old password';
        } else if (emoji.test(fieldValue)) {
          errors.oldpassword = 'Emoji not allowed';
        } else {
          errors.oldpassword = '';
        }
        break;
      case 'password':
        // cursorposition.password = newCursorPosition;
        // await validatePassword(fieldValue, errors);
        if (fieldValue === '') {
          errors.password = 'Please enter password';
        } else if (emoji.test(fieldValue)) {
          errors.password = 'Emoji not allowed';
        } else if (!PassUppercase.test(fieldValue)) {
          errors.password = 'Password should contain Uppercase letter';
        } else if (!PassLowercase.test(fieldValue)) {
          errors.password = 'Password should contain Lowercase letter';
        } else if (!PassNum.test(fieldValue)) {
          errors.password = 'Password should contain number';
        } else if (!PassSpecCha.test(fieldValue)) {
          errors.password = 'Password should contain Special Character';
        } else if (!PassLeng.test(fieldValue)) {
          errors.password = 'Length should be greater or equal to 6';
        } else if (!Space.test(fieldValue)) {
          errors.password = 'Space is not allow';
        } else {
          errors.password = '';
        }
        break;
      case 'confirmpassword':
        // cursorposition.confirmpassword = newCursorPosition;
        // await validateConfrimPassword(
        //   fieldValue,
        //   values.password,
        //   myconfString,
        //   errors,
        // );
        if (fieldValue === '') {
          errors.confirmpassword = `Please enter ${myconfString}`;
        } else if (emoji.test(fieldValue)) {
          errors.confirmpassword = 'Emoji not allowed';
        } else if (fieldValue !== values.password) {
          errors.confirmpassword = 'Not matching with password';
        } else {
          errors.confirmpassword = '';
        }
        break;
      default:
        break;
    }
    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  const handleSubmitChangePassword = async (event) => {
    event.preventDefault();
    // let { errors } = values;
    // let myconfString = "re-enter password";

    // await validatePassword(values.password, errors);

    // await validateOldPassword(values.oldpassword, errors);

    // await validateConfrimPassword(
    //   values.confirmpassword,
    //   values.password,
    //   myconfString,
    //   errors
    // );
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    let myconfString = 'confirm password';

    const newCursorPosition = event.target.selectionStart;

    switch (fieldName) {
      case 'oldpassword':
        cursorposition.oldpassword = newCursorPosition;
        await validateOldPassword(fieldValue, errors);
        break;
      case 'password':
        cursorposition.password = newCursorPosition;
        await validatePassword(fieldValue, errors);
        break;
      case 'confirmpassword':
        cursorposition.confirmpassword = newCursorPosition;
        await validateConfrimPassword(
          fieldValue,
          values.password,
          myconfString,
          errors,
        );
        break;
      default:
        break;
    }
    setValues((prevValues) => ({
      errors,
      cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
    if (values.oldpassword === '') {
      errors.oldpassword = 'Please enter old password';
    }
    if (
      errors.oldpassword === '' &&
      errors.password === '' &&
      errors.confirmpassword === ''
    ) {
      const data = {
        id: authUserData.UserData.id,
        old_password: values.oldpassword,
        new_password: values.password,
      };

      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + ACCOUNT_CHANGE_PASSWORD,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        // console.log(response);
        if (response.data.status && response.data.status === 200) {
          // console.log(data)
          setValues((prevValues) => ({
            ...prevValues,
            oldpassword: '',
            password: '',
            confirmpassword: '',
            errors: {
              oldpassword: '',
              password: '',
              confirmpassword: '',
            },
          }));
          handleClose();
        } else {
          setsubmitmessage(response.data.message);
        }
      } catch (error) {
        return new Error(error);
      }
    }
    // setValues((prevState) => ({
    //   ...prevState,
    //   errors,
    // }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitChangePassword(e);
    }
  };

  return {
    ChangePasswordInput,
    handleSubmitChangePassword,
    values,
    submitmessage,
    handleKeyDown,
    setsubmitmessage,
  };
};

export default ChangePasswordLogic;
