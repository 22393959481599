import { Dialog } from '@mui/material';
import React, { useEffect, forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import RemoveCartIcon from './../../../Assets/Payment/RemoveCart.svg';
import DeleteIcon from '../../../Assets/Payment/delete.svg';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const DeleteAddressPopup = ({
  setDeliveryAddress,
  item,
  handleDeleteAddress,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <img
        onClick={(e) => {
          //   deleteCard(cardItem);
          handleClickOpen(e);
          e.stopPropagation(e)
        }}
        src={DeleteIcon}
        width={'20px'}
        height={'20px'}
        alt="quickvee-delivery-address"
      />{' '}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div-card"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img src={RemoveCartIcon} alt="No-products" />
            {/* {orderPlaceApiResponse?.status === false ? (
              <h1> {orderPlaceApiResponse?.reason}</h1>
            ) : (
              <h1> Please Select Delivery Address</h1>
            )} */}
            {/* <p>Please Select Delivery Address</p> */}
            <span>Are you sure you want to delete address?</span>
          </div>

          <div className="payment-modal-button">
            <button
              onClick={(e) => {
                handleClose(e);
                e.stopPropagation()
              }}
            >
              Cancel
            </button>
            <button
              style={{ background: ' #FF4040', color: '#fff' }}
              onClick={(e) => {
                handleClose();
                setDeliveryAddress(() => null);
                handleDeleteAddress(item);
                e.stopPropagation();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteAddressPopup;
