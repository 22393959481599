import React, { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import alreadyInCart from '../../../Assets/Payment/alreadyInCart.svg';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlreadyInCartModal = ({ isAlreadyInCart, setIsAlreadyInCart }) => {
  const handleClose = () => {
    setIsAlreadyInCart(() => false);
  };

  return (
    <>
      <Dialog
        open={isAlreadyInCart}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img src={alreadyInCart} alt={``} loading="lazy" />
            <span>This Product is already added in Cart!</span>
          </div>
          <div className="payment-modal-button justify-content-center mt-3">
            <button onClick={handleClose}>Ok</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AlreadyInCartModal;
