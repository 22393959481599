import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import QuickveeLogo from '../../../Assets/LoginScreen/quickveeLogo.svg';
import DropDownIcon from '../../../Assets/OrderScreen/dropdown.svg';
import QuickveeLogoSymbol from './../../../Assets/Store/Mask Group 5941.svg';
import userLogo from './../../../Assets/Store/Mask Group 5930.svg';
// import OrderSearch from "./OrderSearch";
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import { setWishlist } from '../../../redux/features/Wishlist/wishlistSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import { useMediaQuery } from '@mui/material';
import Mask_Group from './../../../Assets/Store/Mask Group 5912.svg';
import Cookies from 'js-cookie';
const TopBar = ({
  mymerchantId,
  handleNavClick,
  navBar,
  orderMethod,
  handleOverlayClickOpen,
  isOvelay,
  handleOverlayClickClose,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [showuserData, setShowuserData] = useState(false);
  const [userData, setUserData] = useState();
  const authUserData = useSelector((state) => state.authUser.UserData);

  const page = location.state?.page || '';
  const isMobile = useMediaQuery('(max-width:768px)');
  const { isGuest } = useSelector((state) => state.authUser);

  const handleLogout = () => {
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('is_guest');
    localStorage.removeItem('guestDelivery_address');
    localStorage.removeItem('guestAddDeliveryAddress');
    Cookies.remove('userLoginData');
    Cookies.remove('is_guest');
    dispatch(setAuthUser(null));
    dispatch(setGuestLogin(''));
    dispatch(setAuthentication(false));
    dispatch(setAuthUser(null));
    dispatch(setWishlist([]));
    setUserData([]);
    dispatch(setCartData([]));
    const cartKey = `cart_${mymerchantId}`;
    localStorage.removeItem(cartKey);
    // console.log(orderMethod)

    if (
      location?.state?.merchantId !== '' &&
      location?.state?.merchantId !== undefined &&
      location?.state?.merchantId !== null
    ) {
      Navigate(
        `/merchant/${location.state.merchantId}?orderMethod=${orderMethod}`,
      );
    } else {
      Navigate(`/`);
    }
  };

  const handleProfileDataVisible = (event) => {
    event.stopPropagation();
    setShowuserData(!showuserData);
  };

  const handleProfileDataVisibleScreen = () => {
    setShowuserData(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleProfileDataVisibleScreen);
    return () => {
      document.addEventListener('click', handleProfileDataVisibleScreen);
    };
  }, []);

  useEffect(() => {
    if (authUserData && authUserData.id) {
      setUserData(authUserData);
    }
  }, [authUserData]);
  return (
    <>
      {isOvelay ? (
        <div
          onClick={() => {
            handleOverlayClickClose();
            handleNavClick();
          }}
          className="overlay"
        ></div>
      ) : (
        ''
      )}
      <div className="main-order-top-bar">
        <div className="menu-icon-div">
          {/* <div
            onClick={() => {
              handleNavClick();
              handleOverlayClickOpen();
            }}
            className="menu-icon-div-inner"
          >
            <div className="menu-icon"></div>
            <div className="menu-icon"></div>
            <div className="menu-icon"></div>
          </div> */}
          {isMobile ? (
            <img
              className="menu-toogle"
              onClick={() => {
                handleNavClick();
                handleOverlayClickOpen();
              }}
              src={Mask_Group}
              alt="Mask_Group"
            />
          ) : (
            ''
          )}

          <Link
            to={
              mymerchantId
                ? `/merchant/${mymerchantId}?orderMethod=${orderMethod}`
                : '/'
            }
          >
            {isMobile ? (
              <>
                {' '}
                <img
                  src={QuickveeLogoSymbol}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              </>
            ) : (
              <img
                src={QuickveeLogo}
                alt="quickvee"
                className="quickvee-logo-order-top-bar"
              />
            )}
          </Link>
        </div>

        {/* <Link to={mymerchantId ? `/merchant/${mymerchantId}?orderMethod=pickup` : "/"} >
          <img src={QuickveeLogo} alt="quickvee" className="quickvee-logo-order-top-bar" />
        </Link> */}
        {/* <OrderSearch /> */}
        {/* <Button className="top-bar-button">
          {userData && userData.name} <KeyboardArrowDownIcon />
        </Button> */}
        {userData && userData.id ? (
          <div className="quickvee-customer-login-main">
            <div
              className="quickvee-customer-login profile-page-user-data-button"
              style={{
                background: '#fff',
                cursor: 'pointer',
              }}
              onClick={handleProfileDataVisible}
            >
              {isMobile ? (
                <div className="profile-page-user-data-button">
                  <img
                    style={{ width: '36px' }}
                    src={userLogo}
                    className="mobile-user-logo"
                    alt="userLogo"
                  />
                </div>
              ) : (
                <p style={{ cursor: 'pointer' }}>
                  {' '}
                  {userData.name.split(' ')[0]}
                </p>
              )}

              <span className="my-profile-drop-btn">
                {isMobile ? '' : <img src={DropDownIcon} alt="drop-down" />}
              </span>
            </div>
            <div
              className="online-o-page-profile-box"
              style={{ display: showuserData ? 'inline-table' : 'none' }}
            >
              {isGuest !== 'yes' ? (
                <>
                  <Link
                    className="profile-bar-link"
                    to="/order/profile"
                    state={{
                      merchantId: mymerchantId,
                      orderMethod: orderMethod,
                    }}
                  >
                    My Profile
                  </Link>
                  <Link
                    className="profile-bar-link"
                    to="/order/order-history"
                    state={{
                      merchantId: mymerchantId,
                      orderMethod: orderMethod,
                    }}
                  >
                    Order
                  </Link>
                </>
              ) : (
                ''
              )}
              <div
                className="profile-bar-link profile-bar-link-logout"
                style={{ borderBottom: 'none', cursor: 'pointer' }}
                onClick={() => handleLogout()}
                id="Logout-btn"
              >
                Logout
              </div>
            </div>
          </div>
        ) : (
          <Link
            to="/customer-login"
            state={{
              merchantId: mymerchantId,
              orderMethod: orderMethod,
              page: page,
            }}
            className="quickvee-customer-login"
          >
            Log In
          </Link>
        )}
      </div>
    </>
  );
};

export default TopBar;
