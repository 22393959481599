import React from 'react';
import Banner from '../../Assets/Store/bannerD1.jpg';
import MobileBanner from './../../Assets/Home/bannerM1.webp'
import { useMediaQuery } from '@mui/material';
const HomeBanner = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <>
      <div className="quicvee-home-banner">

        <img
          src={isMobile ? MobileBanner : Banner}
          alt="home-banner"
          className="quickvee-home-banner-image"
        />
      </div>
    </>
  );
};

export default HomeBanner;
