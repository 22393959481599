import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import {  BASE_URL_SANDBOX, GET_CUSTOMER_ACCOUNT_INFO } from "../../../Constants/Config"

const initialState = {
    loading: false,
    accountInfoData: {},
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchAccoutInfoData = createAsyncThunk('accountInfo/fetchAccoutInfoData', async (data) => {
    const response = await axios.post(BASE_URL_SANDBOX + GET_CUSTOMER_ACCOUNT_INFO, data, { headers: { "Content-Type": "multipart/form-data" } })
    if (response.data.status === 200) {
        return response.data
    }

})

const accountInfoSlice = createSlice({
    name: 'accountInfo',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAccoutInfoData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchAccoutInfoData.fulfilled, (state, action) => {
            state.loading = false;
            state.accountInfoData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchAccoutInfoData.rejected, (state, action) => {
            state.loading = false;
            state.accountInfoData = {};
            state.error = action.error.message;
        })
    }
})


export default accountInfoSlice.reducer