import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import EditDeliveryAddressLogic from './EditDeliveryAddressLogic';
import Edit from '../../../Assets/MyInformation/Edit.svg';
import { useSelector } from 'react-redux';

const EditDeliveryAddress = ({ address, checkradius,setDeliveryAddress ,handleSelectAddress}) => {
  const inputRefs = useRef({});
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setsubmitmessage('');
    values.errors.firstname = '';
    values.errors.lastname = '';
    values.errors.state = '';
    values.errors.city = '';
    values.errors.phone = '';
    values.errors.streetaddress = '';
    values.errors.apartmentnumber = '';
    values.errors.city = '';
    values.errors.zipcode = '';
  };
  const {
    DeliveryAddressInput,
    handleSubmitDeliveryAddress,
    values,
    submitmessage,
    setsubmitmessage,
    handlekeydown,
    isLoading,
  } = EditDeliveryAddressLogic({ handleClose, address, checkradius ,setDeliveryAddress,handleSelectAddress });

  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);
  return (
    <>
      <div 
      // style={{marginRight:'-8px',}}
      >
        <Button
          style={{height: '0', }}
          className="modal-main-button edit-delivery-address-button"
          // onClick={handleOpen}
          onClick={(e) => {
            handleOpen();
            // e.stopPropagation();
            // handleSelectAddress(address)
            // setDeliveryAddress(address);
          }}
        >
          {/* <img src={Edit} alt="edit" /> */}
            <p style={{fontSize:'16px',color:'#0a64f9'}}>Edit</p>
            </Button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div id="modal-modal-title">
              <span>Edit Address</span>
              <CloseIcon onClick={handleClose} className="modal-close-icon" />
            </div>

            <form id="modal-modal-description" className="modal-forms">
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div>
                      <TextField
                        label="First Name"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="firstname"
                        onChange={DeliveryAddressInput}
                        value={values.firstname}
                        // inputProps={{
                        //   'data-field': 'firstname',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['firstname'] = input), // Store the ref in a ref object
                        //    selectionstart: values.cursorposition.firstname,
                        // }}
                      />
                    </div>
                   
                    
                  </FormControl>
                  {/* <input type='text' onChange={DeliveryAddressInput}
                        value={values.firstname}
                        className="input-field"
                        id="outlined-size-small"
                        name="firstname"
                        /> */}
                  <span className="input-error">{values.errors.firstname}</span>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div className="modal-form-single-item">
                      <TextField
                        label=" Last Name"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="lastname"
                        onChange={DeliveryAddressInput}
                        value={values.lastname}
                        // inputProps={{
                        //   'data-field': 'lastname',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['lastname'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.lastname,
                        // }}
                      />
                    </div>
                  </FormControl>
                  <span className="input-error">{values.errors.lastname}</span>
                </div>
              </div>
              {/* <div className="delivery-address-update-form"></div> */}
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div>
                      <TextField
                        label="Phone Number"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        name="phone"
                        onChange={DeliveryAddressInput}
                        value={values.phone}
                        inputProps={{
                          maxLength: 10,
                          // 'data-field': 'phone',
                          // autoComplete: 'off',
                          // ref: (input) => (inputRefs.current['phone'] = input), // Store the ref in a ref object
                          // selectionstart: values.cursorposition.phone,
                        }}
                      />
                    </div>
                  </FormControl>
                  <span className="input-error">{values.errors.phone}</span>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div className="modal-form-single-item">
                      <TextField
                        label="Street Address"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="streetaddress"
                        onChange={DeliveryAddressInput}
                        value={values.streetaddress}
                        // inputProps={{
                        //   'data-field': 'streetaddress',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['streetaddress'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.streetaddress,
                        // }}
                      />
                    </div>
                  </FormControl>
                  <span className="input-error">
                    {values.errors.streetaddress}
                  </span>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div className="modal-form-single-item">
                      <TextField
                        label=" Suite/Apartment Number"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="apartmentnumber"
                        onChange={DeliveryAddressInput}
                        value={values.apartmentnumber}
                        // inputProps={{
                        //   'data-field': 'apartmentnumber',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['apartmentnumber'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.apartmentnumber,
                        // }}
                      />
                    </div>
                  </FormControl>
                  <span className="input-error">
                    {/* {values.errors.apartmentnumber} */}
                  </span>
                </div>
              </div>
              {/* <div className="delivery-address-update-form"></div> */}

              <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{ marginBottom: '24px', position: 'relative' }}
                >
                  <FormControl fullWidth>
                    <div>
                      <TextField
                        label="City"
                        className="input-field"
                        id="outlined-size-small"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="city"
                        onChange={DeliveryAddressInput}
                        value={values.city}
                        // inputProps={{
                        //   'data-field': 'city',
                        //   autoComplete: 'off',
                        //   ref: (input) => (inputRefs.current['city'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.city,
                        // }}
                      />
                     
                    </div>
                  </FormControl>
                  <span className="input-error">{values.errors.city}</span>
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{ position: 'relative' }}
                >
                  <div className="row">
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                      style={{ marginBottom: '24px', position: 'relative' }}
                    >
                      <FormControl className='form-select-formcontrol' fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          State
                        </InputLabel>
                        <Select
                         className='select-OnFocus'
                          labelId="demo-simple-select-label"
                          // className="modal-form-single-input the-state-input-in-delivery"
                          id="demo-simple-select"
                          name="state"
                          label="State"
                          onChange={DeliveryAddressInput}
                          value={values.state}
                        >
                          {Array.isArray(StateList) &&
                            StateList.length >= 1 &&
                            StateList.map((item, index) => (
                              <MenuItem key={index} value={item.State}>
                                {item.State}
                              </MenuItem>
                            ))}
                        </Select>
                        
                      </FormControl>
                      <span className="input-error">
                          {values.errors.state}
                        </span>
                    </div>
                    <div
                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                      style={{ marginBottom: '24px', position: 'relative' }}
                    >
                      <FormControl fullWidth>
                        <div>
                          <TextField
                            label="Zip Code"
                            className="input-field"
                            id="outlined-size-small"
                            variant="outlined"
                            // size="small"
                            autoComplete="off"
                            name="zipcode"
                            maxlength="5"
                            onKeyDown={handlekeydown}
                            onChange={DeliveryAddressInput}
                            value={values.zipcode}
                            inputProps={{
                              maxLength: 5,
                              // 'data-field': 'zipcode',
                              // autoComplete: 'off',
                              // ref: (input) =>
                              //   (inputRefs.current['zipcode'] = input), // Store the ref in a ref object
                              // selectionstart: values.cursorposition.zipcode,
                            }}
                          />
                          
                        </div>
                      </FormControl>
                      <span className="input-error">
                            {values.errors.zipcode}
                          </span>
                    </div>
                    
                  </div>


                </div>
                      {submitmessage ? (
                        <div className="col-xs-12 col-sm-12 col-md-6"   style={{ marginBottom: '24px', position: 'relative' }}>
                          <div className="row">
                            <span className="input-error error-on-submit" >
                              {submitmessage}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    
              </div>
              {/* <div className="delivery-address-update-form delivery-address-update-form-city-zipCode">
                <div className="delivery-city-state-update-inputs"></div>
              </div> */}
             
              <div className="modal-footer">
                <Button className="modal-cancle-btn" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className="modal-save-btn"
                  disabled={isLoading}
                  onClick={handleSubmitDeliveryAddress}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditDeliveryAddress;
