import React from "react";
import LeftBanner from "./LeftBanner";
import RightBanner from "./RightBanner";

const MainAuthentication = ({visible}) => {
  return (
    <>
  
        <div className="main-authentication-component">
        <LeftBanner visible={visible} />
        <RightBanner />
        </div>
    
    </>
  );
};

export default MainAuthentication;
