import React from "react";
// import Banner from "../../../Assets/LoginScreen/Login Banner 1-01@2x.png";
const RightBanner = () => {
  return (
    <div className="wrapper " >
     {/* <img src={Banner} className="resize" /> */}
    </div>
  );
};

export default RightBanner;
