import { useEffect } from 'react';
import MapIcon from '../../../Assets/Payment/Map.svg';
import EditDeliveryAddress from '../../Account/AccountInfo/EditDeliveryAddress';
import DeleteAddressPopup from './DeleteAddressPopup';
import { useSelector } from 'react-redux';
const DeliveryAddressCard = ({
  item,
  index,
  deliveryAddress,
  handleSelectAddress,
  setDeliveryAddress,
  handleDeleteAddress,
  guest = false,
  length,
}) => {
  let defaultsetAddress = (data) => {
    setTimeout(() => {
      setDeliveryAddress(data);
    }, 500);
  };
  const { isGuest } = useSelector((state) => state.authUser);
  useEffect(() => {
    if (isGuest === 'yes' && localStorage.getItem('radius') !== null) {
      let guestDeliveryLocal = JSON.parse(
        localStorage.getItem('guestAddDeliveryAddress'),
      );
      // console.log(guestDeliveryLocal?.address?.d_f_name)
      let guestAddressData = {
        radiusData: JSON.parse(localStorage.getItem('radius')),
        address: {
          d_f_name: guestDeliveryLocal?.address?.d_f_name,
          d_l_name: guestDeliveryLocal?.address?.d_l_name,
          d_mobile: guestDeliveryLocal?.address?.d_mobile,
          a_address_line_1: guestDeliveryLocal?.address?.a_address_line_1,
          a_address_line_2: guestDeliveryLocal?.address?.a_address_line_2,
          a_city: guestDeliveryLocal?.address?.a_city,
          a_state: guestDeliveryLocal?.address?.a_state,
          a_zip: guestDeliveryLocal?.address?.a_zip,
          id: guestDeliveryLocal?.address?.id,
        },
        billing: 'no',
      };
      defaultsetAddress(guestAddressData);
    }
  }, []);
  // console.log("length ",length)
  return (
    <div
      className={`col-${length === 1 ? 12 : 6} col-xs-6 col-sm-6 col-md-6`}
      style={{ padding: '11px', position: 'relative' }}
    >
      <div
        style={{
          position: 'absolute',
          top: '1.5rem',
          right: guest ? '2rem' : '4rem',
        }}
      >
        <EditDeliveryAddress
          address={item}
          checkradius={true}
          setDeliveryAddress={setDeliveryAddress}
          handleSelectAddress={handleSelectAddress}
        />
      </div>
      <div
        item
        // xs={12}
        // sm={6}
        key={index}
        className="payment-delivery-single-delivery-address cursor-pointer"
        style={
          deliveryAddress?.address?.id === item.id
            ? {
                border: '1px solid #0A64F9',
                boxShadow: '0 4px 8px #88b3fb',
              }
            : { border: '1px solid #D3D3D3' }
        }
        onClick={() => {
          guest
            ? handleSelectAddress(item)
            : item.in_radius && handleSelectAddress(item);
        }}
      >
        <div className="top-payment-delivery-address">
          <img src={MapIcon} alt="quickvee-delivery-address" />
          {/* <img src={EditIcon} alt="quickvee-delivery-address" /> */}
          <div className="d-flex align-item-center">
            <div className="mx-4">
              {/* <EditDeliveryAddress address={item} checkradius={true} setDeliveryAddress={setDeliveryAddress}/> */}
            </div>
            <div>
              {!guest && (
                <DeleteAddressPopup
                  setDeliveryAddress={setDeliveryAddress}
                  item={item}
                  handleDeleteAddress={handleDeleteAddress}
                />
              )}
            </div>
          </div>
        </div>
        <div className="middle-payment-delivery-address">
          <span className="fs-6">
            {item.d_f_name || item.cc_f_name
              ? item.d_f_name || item.cc_f_name
              : ''}{' '}
            {item.d_l_name || item.cc_l_name
              ? item.d_l_name || item.cc_l_name
              : ''}
          </span>
          <div className="d-flex flex-wrap">
            {guest ? (
              <p className="fs-6">
                {item.a_address_line_1 ? item.a_address_line_1 + ',' : ''}{' '}
                {item.a_address_line_2 ? item.a_address_line_2 + ',' : ''}{' '}
                {item.a_address_line_3 ? item.a_address_line_3 + '.' : ''}
                {item.city ? item.city + ',' : ''}{' '}
                {item.state ? item.state + ',' : ''}{' '}
                {item.cc_zip_code ? item.cc_zip_code : ''}
              </p>
            ) : (
              <>
                <span className="fs-6 fw-light">
                  {item.a_address_line_1 ? item.a_address_line_1 + ',' : ''}{' '}
                  {item.a_address_line_2 ? item.a_address_line_2 + ',' : ''}{' '}
                  {item.a_address_line_3 ? item.a_address_line_3 + '.' : ''}{' '}
                  {item.a_city ? item.a_city + ',' : ''}{' '}
                  {item.a_state ? item.a_state + ',' : ''}{' '}
                  {item.a_zip ? item.a_zip : ''}{' '}
                </span>
              </>
            )}
          </div>
          {guest ? (
            <span>
              {' '}
              Miles{' '}
              {JSON.parse(localStorage.getItem('radius')) && // Check if localStorage item is truthy
              !isNaN(JSON.parse(localStorage.getItem('radius')).distance) // Check if distance is a valid number
                ? parseFloat(
                    JSON.parse(localStorage.getItem('radius')).distance,
                  ).toFixed(2)
                : ''}
            </span>
          ) : (
            <span>Miles {Number(item.miles).toFixed(2)} </span>
          )}
        </div>
        {!guest && (
          <div className="bottom-payment-delivery-address d-flex justify-content-end">
            {item.in_radius ? (
              ''
            ) : (
              <span className="w-100 p-1 mt-1">
                This address is out of delivery radius
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryAddressCard;
