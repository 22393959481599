import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BASE_URL_SANDBOX,
  LOGIN_CUSTOMER_NEW,
  GOOGLE_LOGIN_CUSTOMER,
} from '../../../Constants/Config';
import { useDispatch } from 'react-redux';
import {
  setAuthUser,
  setAuthentication,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { useSelector } from 'react-redux';
import UseCookieStorage from '../../../Constants/UseCookieStorage';
import Cookies from 'js-cookie';

const LoginCustomerFormLogic = () => {
  const AuthLogin = useSelector((state) => state?.authUser?.isAuthenticated);
  const Navigate = useNavigate();
  const { validateEmail, validatePasswordBlank } = Validation();
  const [submitmessage, setsubmitmessage] = useState('');
  const scrollRef = useRef(null);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  const mymerchantId = location.state?.merchantId || '';
  const orderMethod = location.state?.orderMethod || '';
  const page = location.state?.page || '';

  const [values, setValues] = useState({
    username: '',
    password: '',
    errors: {
      username: '',
      password: '',
    },
    // cursorposition: {
    //   username: 0,
    //   password: 0,
    // },
  });
  const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;

  const handleloginInput = async (event) => {
    event.preventDefault();
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    // const newCursorPosition = event.target.selectionStart;

    switch (fieldName) {
      case 'username':
        // cursorposition.username = newCursorPosition;
        // await validateEmail(fieldValue, errors);
        if (fieldValue === '') {
          errors.username = 'Please enter email';
        } else if (emoji.test(fieldValue)) {
          errors.username = 'Emoji not allowed';
        } else if (!EmailReg.test(fieldValue)) {
          errors.username = 'Please enter valid email';
        } else {
          errors.username = '';
        }
        break;
      case 'password':
        // cursorposition.password = newCursorPosition;
        // await validatePasswordBlank(fieldValue, errors);
        if (fieldValue === '') {
          errors.password = 'Please enter password';
        } else if (emoji.test(fieldValue)) {
          errors.password = 'Emoji not allowed';
        } else if (!Space.test(fieldValue)) {
          errors.password = 'Space is not allow';
        } else {
          errors.password = '';
        }
        break;
      default:
        break;
    }

    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  const handleScrollClick = async () => {
    if (scrollRef !== '') {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: async (response) => {
  //   }
  // });

  //   useEffect(() => {
  //  console.log(mymerchantId)
  //   }, [mymerchantId])
  //
  // ================================================
  let merchant_store_id = localStorage.getItem('merchant_id');
  let store_method_id = localStorage.getItem('orderRecord');
  const userData = Cookies.get('userLoginData');
  useEffect(() => {
    const handleBackNavigation = () => {
      const currentPath = window.location.pathname;
      if (currentPath === '/customer-login' && !!userData) {
        if (
          merchant_store_id !== '' &&
          store_method_id !== '' &&
          merchant_store_id !== null &&
          merchant_store_id !== undefined &&
          store_method_id !== undefined
        ) {
          Navigate(
            `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`,
          );
        } else {
          Navigate('/');
        }
      }
    };

    handleBackNavigation(); // Initial check on component mount

    const popstateListener = () => {
      handleBackNavigation();
    };

    window.addEventListener('popstate', popstateListener);

    return () => {
      window.removeEventListener('popstate', popstateListener);
    };
  }, [store_method_id, merchant_store_id]);

  //   useEffect(() => {
  //   const handleBackNavigation = () => {
  //     const currentPath = window.location.pathname;
  //     console.log(currentPath)

  //     console.log(currentPath)
  //     // console.log( localStorage.getItem('userLoginData'))AuthLogin localStorage.getItem('userLoginData') !==""
  //     if(currentPath === "/customer-login" && localStorage.getItem('userLoginData') !=="" && localStorage.getItem('userLoginData') !==null)
  //     {
  //       console.log('hehehehe')
  //       // Navigate('/')
  //       // //  Navigate(-1)
  //       if(mymerchantId !=="")
  //       {
  //         Navigate(`/merchant/${mymerchantId}?orderMethod=${orderMethod}`)

  //       }else{
  //         Navigate('/')
  //       }

  //     }

  //   };
  //   handleBackNavigation();
  //   window.addEventListener('popstate', handleBackNavigation);

  //   return () => {
  //     window.removeEventListener('popstate', handleBackNavigation);
  //   };
  // }, []);
  // ================================================

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          },
        );
        // console.log('response: ', response);
        // console.log('res: ', res);

        if (res.status === 200) {
          const data = {
            given_name: res.data.given_name,
            family_name: res.data.family_name,
            email: res.data.email,
            auth_user_id: res.data.sub,
          };
          const loginREsponse = await axios.post(
            BASE_URL_SANDBOX + GOOGLE_LOGIN_CUSTOMER,
            data,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          );
          if (loginREsponse?.data?.status === 200) {
            dispatch(
              setAuthUser(
                JSON.parse(JSON.stringify(loginREsponse?.data?.result)),
              ),
            );
            UseCookieStorage(
              'userLoginData',
              JSON.stringify(loginREsponse?.data?.result),
              new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
            );
            dispatch(setAuthentication(true));
            if (
              mymerchantId !== '' &&
              orderMethod !== '' &&
              page === 'onlineordering'
            ) {
              Navigate(`/merchant/${mymerchantId}?orderMethod=${orderMethod}`);
            } else if (
              mymerchantId !== '' &&
              orderMethod !== '' &&
              page === 'cart'
            ) {
              Navigate(
                `/payment-cart/${mymerchantId}?orderMethod=${orderMethod}`,
              );
            } else if (
              merchant_store_id !== null &&
              merchant_store_id !== undefined &&
              store_method_id !== undefined &&
              store_method_id !== null
            ) {
              Navigate(
                `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`,
              );
            } else {
              Navigate('/');
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  //   console.log(merchant_store_id)
  // console.log(store_method_id)
  const handleUserLogin = async (e) => {
    e.preventDefault();
    let { errors } = values;

    await validateEmail(values.username, errors);
    await validatePasswordBlank(values.password, errors);

    if (errors.username === '' && errors.password === '') {
      const data = {
        username: values.username.toLowerCase(),
        password: values.password,
      };

      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + LOGIN_CUSTOMER_NEW,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        // console.log(response)

        if (response?.data?.status === 200) {
          dispatch(
            setAuthUser(JSON.parse(JSON.stringify(response?.data?.record))),
          );
          UseCookieStorage(
            'userLoginData',
            JSON.stringify(response?.data?.record),
            new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
          );
          dispatch(setAuthentication(true));

          if (
            mymerchantId !== '' &&
            orderMethod !== '' &&
            page === 'onlineordering'
          ) {
            Navigate(`/merchant/${mymerchantId}?orderMethod=${orderMethod}`);
          } else if (
            mymerchantId !== '' &&
            orderMethod !== '' &&
            page === 'cart'
          ) {
            Navigate(
              `/payment-cart/${mymerchantId}?orderMethod=${orderMethod}`,
            );
          } else if (
            merchant_store_id !== null &&
            merchant_store_id !== undefined &&
            store_method_id !== undefined &&
            store_method_id !== null
          ) {
            Navigate(
              `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`,
            );
          } else {
            Navigate('/');
          }
        } else {
          await handleScrollClick();
          setsubmitmessage(response?.data?.message);
          setOpenAlert(true);
        }
      } catch (error) {
        setsubmitmessage('Internal Server Error');
        return new Error(error);
      }
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Call your API here using the inputValue
      handleUserLogin(e);
    }
  };

  return {
    handleKeyDown,
    handleloginInput,
    values,
    handleUserLogin,
    submitmessage,
    openAlert,
    setOpenAlert,
    scrollRef,
    setsubmitmessage,
    loginWithGoogle,
  };
};

export default LoginCustomerFormLogic;
