import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_COUPON_DETAIL } from '../../../Constants/Config';

const initialState = {
  loading: false,
  couponData: '',
  errorMessage: '',
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchCouponData = createAsyncThunk(
  'coupon/fetchCouponData',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_COUPON_DETAIL,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      // console.log(response)
      if (response.data.status === 200) {
        const myresponse = {
          result: response.data.result,
          message: '',
        };
        return myresponse;
      } else if (response.data.status === 400) {
        const myresponse = {
          result: '',
          message: response.data.message,
        };
        return myresponse;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
);

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    removeCoupon: (state, action) => {
      state.couponData = action.payload;
    },
    removerErrorMessage: (state, action) => {
      state.errorMessage = '';
    },
    removeCouponNew: (state, action) => {
      state.couponData = "";
    },
    
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCouponData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCouponData.fulfilled, (state, action) => {
      state.loading = false;
      state.couponData = action.payload.result;
      state.errorMessage = action.payload.message;
      state.error = '';
    });
    builder.addCase(fetchCouponData.rejected, (state, action) => {
      state.loading = false;
      state.couponData = {};
      state.error = action.error.message;
    });
  },
});

export const { removeCoupon, removerErrorMessage,removeCouponNew } = couponSlice.actions;
export default couponSlice.reducer;
