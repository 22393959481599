import React, { useState, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import WislistIcon from '../../Assets/Payment/Wishlist.svg';
import RemoveCartIcon from '../../Assets/Payment/RemoveCart.svg';
import DeleteIcon from '../../Assets/Payment/delete.svg';
import CrossIcon from '../../Assets/Payment/cross.svg';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertModal = ({
  MoveWishlist,
  RemoveAllCart,
  moveallproductToWishlist,
  deletallProductFromCart,
  removeSingleFromCart,
  handleDeleteCartProduct,
  User,
  merchantId,
}) => {
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:480px)');

  const handleClickOpen = () => {
    if (MoveWishlist) {
      if (User) {
        // console.log(User);
        setOpen(true);
      } else {
        Navigate('/customer-login', {
          state: {
            merchantId: merchantId,
          },
        });
      }
    } else if (RemoveAllCart || removeSingleFromCart) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (MoveWishlist) {
      moveallproductToWishlist();
      deletallProductFromCart();
    }
    if (RemoveAllCart) {
      deletallProductFromCart();
    }
    if (removeSingleFromCart) {
      handleDeleteCartProduct();
    }
    handleClose();
  };

  return (
    <>
      {MoveWishlist || RemoveAllCart ? (
        <span className="q-payment-selected-wishlist">
          {RemoveAllCart ? (
            <img
              src={DeleteIcon}
              alt="delete"
              width={20}
              style={{ margin: '0px 0px 0px 17px' }}
              onClick={handleClickOpen}
            />
          ) : (
            <span onClick={handleClickOpen}>Move All to Wishlist</span>
          )}
        </span>
      ) : (
        <>
          {isMobile ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleClickOpen}
              src={DeleteIcon}
              alt="remove from cart"
            />
          ) : (
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleClickOpen}
              src={CrossIcon}
              alt="remove from cart"
            />
          )}
        </>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div"
      >
        <div className="payment-modal">
          <div className="payment-modal-content">
            <img
              src={MoveWishlist ? WislistIcon : RemoveCartIcon}
              alt={`wishlist-icon`}
              loading="lazy"
            />
            {MoveWishlist ? (
              <span>
                Are You Sure You Want To Move <br /> All Items From Your Cart To
                Your Wishlist?{' '}
              </span>
            ) : RemoveAllCart ? (
              <span>
                {' '}
                Are You Sure You Want To <br /> Remove All Items From Your Cart?{' '}
              </span>
            ) : (
              <span>
                Are You Sure You Want To <br /> Remove This Product From Cart?
              </span>
            )}
          </div>
          <div className="payment-modal-button">
            <button onClick={handleClose}>Cancel</button>
            <button
              onClick={handleSubmit}
              style={
                MoveWishlist
                  ? { background: ' #7A7A7A', color: '#fff' }
                  : { background: ' #FF4040', color: '#fff' }
              }
            >
              {MoveWishlist ? 'Move' : 'Remove'}{' '}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AlertModal;
