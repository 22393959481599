import React,{useEffect}  from 'react'

import HomeCategories from './HomeCategories'
import OurStores from './OurStores'
import HomeBanner from './HomeBanner'


const Home = () => {

    useEffect(()=>{
        (document.body.style.overflow = 'auto');
    })
    
    return (
        <>
            <div>
                
                <HomeCategories />
                <HomeBanner />
                <OurStores />
            </div>

        </>
    )
}

export default Home