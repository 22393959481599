import React, { useState, useEffect, useMemo } from 'react';
import BasicInfo from '../AccountInfo/BasicInfo';
import ChangePassword from '../AccountInfo/ChangePassword';
import BillingInfo from '../AccountInfo/BillingInfo';
import DeliveryAddress from '../AccountInfo/DeliveryAddress';
import EditDeliveryAddress from '../AccountInfo/EditDeliveryAddress';
import Map from '../../../Assets/MyInformation/Map.svg';
import Delete from '../../../Assets/MyInformation/Delete.svg';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Grid, useMediaQuery } from '@mui/material';
import DeliveryAddressLogic from '../AccountInfo/DeliveryAddressLogic';
import {
  BASE_URL_SANDBOX,
  GET_CUSTOMER_ACCOUNT_INFO,
} from '../../../Constants/Config';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import Validation from '../../../Constants/Validation';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import DeleteAddressPopup from '../../Payment/Cart/DeleteAddressPopup';
import Footer from '../../MainHome/Footer';
import { format } from 'date-fns';

const AccountInfo = () => {
  const formatDatePassword = (inputDate) => {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Extract the month, day, and year from the date object
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    if (!month || !day || !year) return '';

    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;

    // Return the formatted date
    return formattedDate;
  };

  const isMobile = useMediaQuery('(max-width:480px)');
  const Navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [userAddresses, setUserAddresses] = useState();
  const authUserData = useSelector((state) => state.authUser);
  const { UserData, isAuthenticated } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const accountInfoDataState = useSelector((state) => state.accountInfo);

  const { checkIfImageExists } = Validation();
  const { isGuest } = useSelector((state) => state.authUser);

  //console.log(userAddresses);
  useEffect(() => {
    if (
      !accountInfoDataState.loading &&
      accountInfoDataState &&
      accountInfoDataState.accountInfoData
    ) {
      setUserData(accountInfoDataState.accountInfoData.message);
      setUserAddresses(accountInfoDataState.accountInfoData.address);
    }
  }, [
    accountInfoDataState,
    accountInfoDataState.loading,
    accountInfoDataState.accountInfoData,
  ]);

  function formatDate(inputDate) {
    // Step 1: Parse the input date string into a JavaScript Date object
    const dateObject = new Date(inputDate);

    // Step 2: Format the Date object into the desired output format
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = dateObject.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  const { handleDeleteAddress, handleDefaultAddress } = DeliveryAddressLogic({
    checkradius: false,
    setDeliveryAddress,
  });

  useEffect(() => {
    if (UserData && UserData.id) {
      dispatch(
        fetchAccoutInfoData({
          id: UserData.id,
          merchant_id: '',
        }),
      );
      window.scrollTo(0, 0);
    } else if (!isAuthenticated) {
      const timeoutId = setTimeout(() => {
        // Check again if isAuthenticated is still false before redirecting
        if (!isAuthenticated) {
          Navigate('/');
        }
      }, 2000); // Adjust the delay time as needed

      return () => clearTimeout(timeoutId);
      // Clear the timeout if isAuthenticated changes before the timeout expires
    } else {
    }
  }, [UserData]);

  return (
    <>
      <div className="account-info-main-component">
        <div className="account-info-welcome-header">
          <h1>
            Hello, {userData && userData.f_name ? userData.f_name : ''}{' '}
            {userData && userData.l_name ? userData.l_name : ''} <br />{' '}
            <span>Welcome Back!</span>
          </h1>

          <p>{userData && userData.email ? userData.email : ''}</p>
        </div>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="account-info-subheading">
              <p>
                Protecting your account information is our utmost priority. Rest
                assured that we maintain strict security measures for safeguard
                of your data.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div variant="outlined" className="account-info">
              <div className="account-info-top-section">
                <span>Basic Info</span>
                <BasicInfo userData={userData && userData} />
              </div>
              <div className="account-info-bottom-section">
                <div className="account-info-single">
                  <span>Name</span>
                  {userData ? (
                    <span>
                      {' '}
                      {userData.f_name} {userData.l_name}{' '}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div
                  style={{ borderBottom: 'unset' }}
                  className="account-info-single pb-0"
                >
                  <span>Mobile Number </span>
                  {userData ? <span> {userData.phone} </span> : <span>-</span>}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div variant="outlined" className="account-info">
              {isMobile ? (
                <Grid container className="account-info-top-section ">
                  <Grid item xs={8} className="account-password-top-section">
                    <Grid container>
                      <Grid item xs={12}>
                        <span className="password-title-accountinfo">
                          Password{' '}
                        </span>
                      </Grid>

                      {userData?.created_date_time &&
                      userData?.created_date_time?.split(' ')[0] !==
                        '0000-00-00' ? (
                        <Grid>
                          <Grid item xs={12}>
                            {/* <p className="last-change-password-p">
                              Last Changed{' '}
                              {userData?.created_date_time
                                ? formatDatePassword(
                                    userData?.created_date_time,
                                  )
                                : ''}{' '}
                            </p> */}
                            <p className="last-change-password-p">
                              Last Changed{' '}
                              {userData?.created_date_time
                                ? format(
                                    new Date(userData?.created_date_time),
                                    'MM/dd/yyyy',
                                  )
                                : ''}{' '}
                            </p>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    justifySelf={'end'}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    xs={4}
                  >
                    <ChangePassword />
                  </Grid>
                </Grid>
              ) : (
                <Grid container className="account-info-top-section ">
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    md={4}
                    className="account-password-top-section"
                  >
                    <span>Password </span>
                  </Grid>
                  {userData?.created_date_time &&
                  userData?.created_date_time?.split(' ')[0] !==
                    '0000-00-00' ? (
                    <Grid item xs={5} sm={5} md={4}>
                      <p>
                        Last Changed{' '}
                        {userData?.created_date_time
                          ? format(
                              new Date(userData?.created_date_time),
                              'MM/dd/yyyy',
                            )
                          : ''}{' '}
                      </p>
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    justifySelf={'end'}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    xs={4}
                    md={4}
                  >
                    <ChangePassword />
                  </Grid>
                </Grid>
              )}

              <div className="account-info-bottom-section"></div>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div variant="outlined" className="account-info">
              <div className="account-info-top-section">
                <span>Billing Info</span>

                <BillingInfo userData={userData && userData} />
              </div>
              {userData && userData.a_address_line_1 ? (
                <div className="account-info-bottom-section">
                  <div className="account-info-single">
                    <span>Address</span>
                    <span>
                      {userData.a_address_line_1
                        ? userData.a_address_line_1 + ' '
                        : ''}
                      {userData.a_address_line_2
                        ? userData.a_address_line_2 + ', '
                        : ''}
                      {userData.a_city ? userData.a_city + ', ' : ''}
                      {userData.a_state ? userData.a_state + ', ' : ''}
                      {userData.a_zip ? userData.a_zip : ''}
                    </span>
                  </div>

                  <div
                    style={{ borderBottom: 'unset' }}
                    className="account-info-single"
                  >
                    <span>Card Info</span>
                    <span>
                      {userData.i_card_type ? userData.i_card_type + ', ' : ''}
                      <span className="card-info-important">
                        {' '}
                        Card Number :{' '}
                      </span>
                      {userData.i_card_number
                        ? userData.i_card_number + ' ,'
                        : ''}
                      <span className="card-info-important">
                        {' '}
                        Expiration Date :{' '}
                      </span>
                      {userData.i_card_ex_date
                        ? format(
                            new Date(userData.i_card_ex_date),
                            'MM/dd/yyyy',
                          )
                        : ''}

                      {/* add dob selected display add by priya */}
                      {userData.i_card_dob !== '' &&
                      userData.i_card_dob !== 'NaN-aN-aN' ? (
                        <>
                          {' '}
                          , <span className="card-info-important">DOB: </span>
                          <span
                            className="card-info-important"
                            style={{ color: '#000000' }}
                          >
                            {userData.i_card_dob
                              ? format(
                                  new Date(userData.i_card_dob),
                                  'MM/dd/yyyy',
                                )
                              : ''}
                          </span>
                        </>
                      ) : null}
                    </span>
                  </div>

                  <div
                    style={{ borderBottom: '0' }}
                    className="account-info-single"
                  >
                    <span className="span-before-driving-licence-image"></span>
                    {userData && userData.image_url ? (
                      <img
                        className="driving-licence-image"
                        src={userData?.image_url} // Condition 1: Update image
                        alt="Driver License"
                      />
                    ) : userData && userData.i_card_front_img ? (
                      <img
                        className="driving-licence-image"
                        src={`${BASE_URL_SANDBOX}upload/customer/id_proof/${userData?.image_url}`} // Condition 2: Old image
                        alt="Driver License"
                      />
                    ) : (
                      '' // Condition 3: No image
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div variant="outlined" className="account-info">
              <div className="account-info-top-section">
                <span>Delivery Address </span>
                <DeliveryAddress checkradius={false} />
              </div>

              <div
                className="row account-info-bottom-section "
                style={{ width: 'unset' }}
                // container
                // gap={0}
                // justifyContent={'start'}
                // className="account-info-bottom-section "
              >
                {userAddresses && userAddresses.length >= 1 ? (
                  userAddresses.map((address, index) => {
                    return (
                      <div
                        // onClick={() => handleDefaultAddress(address.id)}
                        className="col-sm-6 col-md-6"
                        style={{ padding: '10px' }}
                      >
                        <div
                          // item
                          // xs={12}
                          // sm={12}
                          // md={4}
                          // lg={4}
                          // xl={4}
                          className="single-delivery-address"
                          key={index}
                          style={
                            // address.is_default === '1'
                            //   ? { border: '1px solid #0A64F9 ',boxShadow: "rgb(136, 179, 251) 0px 4px 8px" }
                            //   :
                            { border: '1px solid #D3D3D3 ' }
                          }
                        >
                          <div className="single-delivery-address-header">
                            <img src={Map} alt="map" />
                            <div className="d-flex gap-3 align-items-center ">
                              <EditDeliveryAddress
                                address={address}
                                checkradius={false}
                              />
                              <DeleteAddressPopup
                                setDeliveryAddress={setDeliveryAddress}
                                item={address}
                                handleDeleteAddress={handleDeleteAddress}
                                DeleteIcon={Delete}
                                className="delete-address-icon"
                              />
                            </div>
                          </div>
                          <div className="single-delivery-address-content">
                            <p className="receiver-name">
                              {address.d_f_name ? address.d_f_name : ''}{' '}
                              {address.d_l_name ? address.d_l_name : ''}{' '}
                            </p>
                            <p className="receiver-address">
                              {' '}
                              {address.a_address_line_1
                                ? address.a_address_line_1
                                : ''}{' '}
                              {address.a_address_line_2
                                ? address.a_address_line_2 + ','
                                : ''}{' '}
                              {address.a_address_line_3
                                ? address.a_address_line_3 + '.'
                                : ''}
                            </p>
                            <p className="receiver-zipcode">
                              {' '}
                              {address.a_city ? address.a_city + ',' : ''}{' '}
                              {address.a_state ? address.a_state + ',' : ''}{' '}
                              {address.a_zip ? address.a_zip : ''}{' '}
                            </p>
                          </div>
                          <div className="single-delivery-address-footer">
                            {/* <Button
                            className="btn-remove-delivery-address"
                            onClick={() => handleDeleteAddress(address)}
                          >
                            {' '}
                            <span className="remove-delivery-address">
                              Remove
                            </span>{' '}
                            <img
                              src={Delete}
                              className="delete-address-icon"
                              alt="delete"
                            />{' '}
                          </Button> */}
                            {/* {address.is_default === '1' ? (
                            <Button className="default-address">Default</Button>
                          ) : (
                            <Button
                              className="set-default-address"
                              onClick={() => handleDefaultAddress(address.id)}
                            >
                              Set as Default
                            </Button>
                          )} */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <center>No addresses found</center>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AccountInfo;
