import React, { useState, useEffect } from 'react';
import DrodownIcon from '../../Assets/Products/DropdownIcon.svg';
import MobileDropDown from './MobileDropDown';
import { useMediaQuery } from '@mui/material';
const DropDown = ({ onFilterChange, categorysId }) => {
  const [showDiv, setShowDiv] = useState(true);
  const [selectFilter, setSelectFilter] = useState();
  const isMobile = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    onFilterChange(selectFilter);
  }, [selectFilter]);
  // console.log(selectFilter);
  const handleClickSearchBar = () => {
    setShowDiv(!showDiv);
  };
  return (
    <>
      {isMobile ? (
        <MobileDropDown
          setSelectFilter={setSelectFilter}
          selectFilter={selectFilter}
        />
      ) : (
        <div className="select-box">
          <div
            className="select-box__current"
            tabIndex="1"
            onClick={() => handleClickSearchBar()}
          >
            <div className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id="0"
                value="1"
                name="filter"
                defaultChecked="true"
                disabled
              />
              <p className="select-box__input-text">Sort By</p>
            </div>
            <div className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id="1"
                value="d_htl"
                name="filter"
                onChange={(e) => setSelectFilter(e.target.value)}
              />
              <p className="select-box__input-text">Discount High to Low</p>
            </div>
            <div className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id="2"
                value="p_lth"
                name="filter"
                onChange={(e) => setSelectFilter(e.target.value)}
              />
              <p className="select-box__input-text">Price Low to High</p>
            </div>
            <div className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id="3"
                value="p_htl"
                name="filter"
                onChange={(e) => setSelectFilter(e.target.value)}
              />
              <p className="select-box__input-text">Price High to Low</p>
            </div>
            <div className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id="4"
                value="new_ar"
                name="filter"
                onChange={(e) => setSelectFilter(e.target.value)}
              />
              <p className="select-box__input-text">New Arrivals</p>
            </div>

            {/* <img
              className="select-box__icon"
              src={DrodownIcon}
              alt="Arrow Icon"
              aria-hidden="true"
            /> */}
            <div
              className="select-box__icon"
              src={DrodownIcon}
              alt="Arrow Icon"
              aria-hidden="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                //   xmlns:xlink="http://www.w3.org/1999/xlink"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_3424"
                      data-name="Rectangle 3424"
                      width="28"
                      height="28"
                      transform="translate(1211 -3447)"
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_5972"
                  data-name="Mask Group 5972"
                  transform="translate(-1211 3447)"
                  clipPath="url(#clipPath)"
                >
                  <g
                    id="Group_8217"
                    data-name="Group 8217"
                    transform="translate(-138.3 -1112.558)"
                  >
                    <line
                      id="Line_587"
                      data-name="Line 587"
                      x2="19.34"
                      transform="translate(1352 -2328.5)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_588"
                      data-name="Line 588"
                      x2="10.744"
                      transform="translate(1352 -2320.979)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_589"
                      data-name="Line 589"
                      x2="6.447"
                      transform="translate(1352 -2313.458)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_590"
                      data-name="Line 590"
                      y2="10.744"
                      transform="translate(1370.265 -2323.128)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_9210"
                      data-name="Path 9210"
                      d="M1364-2331.429l4.839,4.839,4.839-4.839"
                      transform="translate(1.421 14.206)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          {showDiv && (
            <ul className="select-box__list">
              <li>
                <label
                  className="select-box__option sort-by"
                  htmlFor="0"
                  aria-hidden="false"
                >
                  Sort By:
                </label>
              </li>
              <li>
                <label
                  className="select-box__option"
                  htmlFor="1"
                  aria-hidden="true"
                >
                  Discount High to Low
                </label>
              </li>
              <li>
                <label
                  className="select-box__option"
                  htmlFor="2"
                  aria-hidden="true"
                >
                  Price Low to High
                </label>
              </li>
              <li>
                <label
                  className="select-box__option"
                  htmlFor="3"
                  aria-hidden="true"
                >
                  Price High to Low
                </label>
              </li>
              {/* <li>
            <label
              className="select-box__option"
              htmlFor="4"
              aria-hidden="true"
            >
              New Arrivals
            </label>
          </li> */}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default DropDown;
