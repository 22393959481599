import { useState } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { BASE_URL, MERCHANT_LOGIN } from '../../../Constants/Config';

const MerchantLoginFormLogic = () => {
  const { validateEmail, validatePassword } = Validation();
  const [values, setValues] = useState({
    username: '',
    password: '',
    errors: {
      username: '',
      password: '',
    },
  });

  const handleMerchantUsername = async (username) => {
    try {
      let data = { username };
      const response = await axios.post(BASE_URL + MERCHANT_LOGIN, data);
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMerchantLoginInput = async (event) => {
    let { errors } = values;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    switch (fieldName) {
      case 'username':
        let valid = await validateEmail(fieldValue, errors);
        if (valid.username.length === 0) {
          await handleMerchantUsername(fieldName, fieldValue);
        }
        break;
      case 'password':
        await validatePassword(fieldValue, errors);
        break;
      default:
        break;
    }

    setValues((prevValues) => ({
      errors,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  const handleMerchantLogin = async (e) => {
    e.preventDefault();
    let { errors } = values;

    await validateEmail(values.username, errors);
    await validatePassword(values.password, errors);
    if (errors.username === '' && errors.password === '') {
      const data = {
        username: values.username.toLowerCase(),
        password: values.password,
      };
      try {
        const response = await axios.post(
          'https://react.quickvee.com/ReactCI/Findstore/chk_variant',
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        //  console.log(response)
      } catch (error) {
        return new Error(error);
      }
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  return { handleMerchantLoginInput, values, handleMerchantLogin };
};

export default MerchantLoginFormLogic;
