import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_TAX_DETAIL } from '../../../Constants/Config';

const initialState = {
  loading: false,
  taxDetailData: [],
  successMessage: '',
  error: '',
};

// Generate pening , fulfilled and rejected action type
export const fetchTaxDetailData = createAsyncThunk(
  'taxDetail/fetchWishlistData',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_TAX_DETAIL,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      if (response.data.status === 200) {
        const myresponse = {
          message: response.data.message,
          result: response.data?.result,
        };
        return myresponse;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
);

const taxDetailSlice = createSlice({
  name: 'taxDetail',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTaxDetailData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTaxDetailData.fulfilled, (state, action) => {
      state.loading = false;
      state.taxDetailData = action.payload?.result;
      state.successMessage = action.payload?.message;
      state.error = '';
    });
    builder.addCase(fetchTaxDetailData.rejected, (state, action) => {
      state.loading = false;
      state.taxDetailData = {};
      state.error = action.error.message;
    });
  },
});

export default taxDetailSlice.reducer;
