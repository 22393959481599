import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_WITH_GUESTS, BASE_URL_SANDBOX } from '../../../Constants/Config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import UseCookieStorage from '../../../Constants/UseCookieStorage';

export default function GuestLogicForm() {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  // Destructure the state object and directly access the merchantId property
  const { merchantId, orderMethod } = location.state || {};

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  });
  const [error, setError] = useState({
    firstnameError: '',
    lastnameError: '',
    emailError: '',
    phoneError: '',
  });
  const [merchant_id, setMerchantId] = useState('');
  const [submitmessage, setsubmitmessage] = useState('');
  const scrollRef = useRef(null);
  const [openAlert, setOpenAlert] = useState(false);
  const { UserData } = useSelector((state) => state.authUser);
  const { isGuest } = useSelector((state) => state.authUser);
  const onlySingleSpace = /^\s*[a-zA-Z]+(\s[a-zA-Z]+)*\s*$/;
  const Nameval = /^\s*[a-zA-Z]+(\s+[a-zA-Z]+)*\s*$/;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  useEffect(() => {
    if (isGuest === 'yes') {
      setFormData({
        ...formData,
        firstname: UserData?.f_name,
        lastname: UserData?.l_name,
        email: UserData?.email,
        phone: UserData?.phone,
      });
    }
  }, []);

  const handleScrollClick = async () => {
    if (scrollRef !== '') {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onhandleChange = (e) => {
    const { name, value } = e.target;
    let errorRecord = { ...error };
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let Charegex = /^[A-Za-z]*$/;

    if (name === 'firstname') {
      errorRecord = {
        ...errorRecord,
        firstnameError:
          // value.trim() === ''
          //   ? `Please enter First Name`
          //   : !Charegex.test(value)
          //   ? `Alphabets only`
          //   : '',

          value === ''
            ? 'Please enter First Name'
            : emoji.test(value)
            ? 'Emoji not allowed'
            : !Nameval.test(value) && value
            ? 'Alphabets only'
            : !onlySingleSpace.test(value) && value
            ? 'Multiple spaces not allowed'
            : '',
      };
    }
    if (name === 'lastname') {
      errorRecord = {
        ...errorRecord,
        lastnameError:
          // value.trim() === ''
          //   ? `Please enter Last Name`
          //   :
          // !Charegex.test(value) ? `Alphabets only` : '',
          value === ''
            ? ''
            : emoji.test(value)
            ? 'Emoji not allowed'
            : !Nameval.test(value) && value
            ? 'Alphabets only'
            : !onlySingleSpace.test(value) && value
            ? 'Multiple spaces not allowed'
            : '',
      };
    }
    if (name === 'email') {
      errorRecord = {
        ...errorRecord,
        emailError:
          value.trim() === ''
            ? `Please enter Email`
            : !emailRegex.test(value)
            ? `Please provide a valid Email`
            : '',
      };
    }
    if (name === 'phone') {
      errorRecord = {
        ...errorRecord,
        phoneError:
          value.trim() === ''
            ? `Please enter phone`
            : value.length !== 10
            ? `Phone no not valid`
            : '',
      };
    }

    setError(errorRecord);
    setFormData((prevState) => ({
      ...prevState,
      // ...formData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let errorRecord = { ...error };
    let formIsValid = false;
    // formData.firstname,formData.lastname,formData.email,formData.phone
    if (formData.firstname == '') {
      errorRecord.firstnameError = 'Please enter First Name';
      formIsValid = true;
    }
    // if (formData.lastname == '') {
    //   errorRecord.lastnameError = 'Please enter Last Name';
    //   formIsValid = true;
    // }
    if (formData.email == '') {
      errorRecord.emailError = 'Please enter email';
      formIsValid = true;
    }
    if (formData.phone == '') {
      errorRecord.phoneError = 'Please enter phone';
      formIsValid = true;
    }
    setError(errorRecord);
    if (formIsValid == false) {
      return true;
    } else {
      return false;
    }
  };
  let CurrentValidate = (error) => {
    // console.log(error)
    if (
      error.firstnameError == '' &&
      error.lastnameError == '' &&
      error.phoneError == '' &&
      error.emailError == ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleGuestDataSubmit = async (e) => {
    e.preventDefault();

    let isValidate = validateForm();
    let isCurrValidate = CurrentValidate(error);

    if (isValidate) {
      if (isCurrValidate) {
        let packet = {
          merchant_id: merchantId,
          guest: 'yes',
          firstname: formData.firstname.trim(),
          lastname: formData.lastname.trim(),
          email: formData.email,
          phone: formData.phone,
        };
        // https://sandbox.quickvee.com/CustomerLoginReact/register
        // console.log(BASE_URL_SANDBOX+LOGIN_WITH_GUESTS)
        let response = await axios.post(
          BASE_URL_SANDBOX + LOGIN_WITH_GUESTS,
          packet,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        // console.log(orderMethod)
        if (response.data.status == 200) {
          UseCookieStorage(
            'is_guest',
            'yes',
            new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
          );
          UseCookieStorage(
            'userLoginData',
            JSON.stringify(response?.data?.result),
            new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
          );
          dispatch(
            setAuthUser(JSON.parse(JSON.stringify(response?.data?.result))),
          );
          dispatch(setGuestLogin('yes'));
          dispatch(setAuthentication(true));
          Navigate(`/payment-cart/${merchantId}?orderMethod=${orderMethod}`);
        } else {
          // console.log(response);
          await handleScrollClick();
          setsubmitmessage('User already Exist!');
          setOpenAlert(true);
        }
      }
    }
  };
  // ==================================
  const keyEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setFormData((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));

      handleGuestDataSubmit(event);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyEnter);
    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [formData]);

  useEffect(() => {
    const handleBackNavigation = () => {
      const currentPath = window.location.pathname;
      if (currentPath === '/guest-checkout' && isGuest === 'yes') {
        Navigate(`/payment-cart/${merchantId}?orderMethod=${orderMethod}`);
      }
    };
    handleBackNavigation();
    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);
  // ================================================

  return {
    handleGuestDataSubmit,
    onhandleChange,
    formData,
    error,
    setMerchantId,
    submitmessage,
    setsubmitmessage,
    openAlert,
    setOpenAlert,
    scrollRef,
    keyEnter,
  };
}
