import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_VARIENTS_DETAIL,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  variantCartData: [],
  error: '',
};

// Generate pending, fulfilled, and rejected action type
export const fetchVariantCartData = createAsyncThunk(
  'variantCart/fetchVariantCartData',
  async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_VARIENTS_DETAIL,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      if (response.data.status === 200) {
        return response.data.result;
      }
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
);

const variantCartSlice = createSlice({
  name: 'variantCart',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchVariantCartData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchVariantCartData.fulfilled, (state, action) => {
      state.loading = false;
      // checking if product is already in variantCartData.. if not then adding it in...
      const existingItemIndex = state.variantCartData.findIndex(
        (item) =>
          item?.product_id === action.payload?.product_id &&
          item?.id === action.payload?.id,
      );

      if (existingItemIndex === -1) {
        // If item doesn't exist, add it to the array
        state.variantCartData.push(action.payload);
      } else {
        // If item exists, update it
        state.variantCartData = [...state.variantCartData].map((item) =>
          item?.product_id === action.payload?.product_id &&
          item?.id === action.payload?.id
            ? { ...action.payload }
            : item,
        );
      }
      state.error = '';
    });
    builder.addCase(fetchVariantCartData.rejected, (state, action) => {
      state.loading = false;
      state.variantCartData = [];
      state.error = action.error.message;
    });
  },
});

export default variantCartSlice.reducer;
