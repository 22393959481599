import React, { forwardRef, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import outOfStockImage from '../../../Assets/Payment/out-of-stock-products.svg';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const OutOfStockProductsModal = ({
  cartData,
  productsNotValid,
  setProductsNotValid,
}) => {
  const handleClose = () => {
    setProductsNotValid(() => false);
    localStorage.removeItem('outOfStockProducts');
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('outOfStockProducts');
    };
  }, []);

  const productsOutOfStock = useMemo(() => {
    const data = localStorage.getItem('outOfStockProducts');
    const parsedData = data ? JSON.parse(data) : [];

    return parsedData;
  }, [productsNotValid, cartData]);

  return (
    <>
      <Dialog
        open={productsNotValid}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="payment-modal-outer-div"
      >
        <div className="payment-modal out-of-stock-products">
          <div className="payment-modal-content out-of-stock-products">
            <img src={outOfStockImage} alt={``} loading="lazy" />
            {productsOutOfStock &&
              productsOutOfStock.map((title, index) => (
                <span>{`${index + 1}) ${title} is Sold Out!`}</span>
              ))}
          </div>
          <div className="payment-modal-button justify-content-center mt-4">
            <button onClick={handleClose}>Ok</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OutOfStockProductsModal;
