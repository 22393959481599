import { createContext, useState } from 'react';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_SINGLE_CATEGORY_SEARCH_DATA,
} from '../Constants/Config';

export const ContextSearch = createContext();

const SearchContext = ({ children }) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [ageConfirmation, setAgeConfirmation] = useState(false);
  const [showAgeConfirmationModal, setShowAgeConfirmationModal] =
    useState(false);

  const fetchCategoryProducts = async (category) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + GET_SINGLE_CATEGORY_SEARCH_DATA,
        { smartsearch: category },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const responseData = response?.data?.message;

      return responseData;
    } catch (error) {
      console.log('error occured: ', error);
    }
  };

  const checkOrderMethod = (item) => {
    const isPickup = item?.is_pickup === 'Yes';
    const isDelivery = item?.is_deliver === 'Yes';

    return !isPickup && isDelivery ? 'delivery' : 'pickup';
  };

  return (
    <ContextSearch.Provider
      value={{
        noDataFound,
        setNoDataFound,
        fetchCategoryProducts,
        checkOrderMethod,
        ageConfirmation,
        setAgeConfirmation,
        showAgeConfirmationModal,
        setShowAgeConfirmationModal,
      }}
    >
      {children}
    </ContextSearch.Provider>
  );
};

export default SearchContext;
