import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const MobileDropDown = ({ setSelectFilter, selectFilter }) => {
  return (
    <>
      <Dropdown className="dropdown-container">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            //   xmlns:xlink="http://www.w3.org/1999/xlink"
            width="28"
            height="28"
            viewBox="0 0 28 28"
          >
            <defs>
              <clipPath id="clipPath">
                <rect
                  id="Rectangle_3424"
                  data-name="Rectangle 3424"
                  width="28"
                  height="28"
                  transform="translate(1211 -3447)"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="1"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_5972"
              data-name="Mask Group 5972"
              transform="translate(-1211 3447)"
              clipPath="url(#clipPath)"
            >
              <g
                id="Group_8217"
                data-name="Group 8217"
                transform="translate(-138.3 -1112.558)"
              >
                <line
                  id="Line_587"
                  data-name="Line 587"
                  x2="19.34"
                  transform="translate(1352 -2328.5)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_588"
                  data-name="Line 588"
                  x2="10.744"
                  transform="translate(1352 -2320.979)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_589"
                  data-name="Line 589"
                  x2="6.447"
                  transform="translate(1352 -2313.458)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_590"
                  data-name="Line 590"
                  y2="10.744"
                  transform="translate(1370.265 -2323.128)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_9210"
                  data-name="Path 9210"
                  d="M1364-2331.429l4.839,4.839,4.839-4.839"
                  transform="translate(1.421 14.206)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className="DropDown-menu-container">
          <Dropdown.Item
            style={{
              backgroundColor: selectFilter === 'd_htl' ? '#D6EAF8' : '',
            }}
            value="d_htl"
            onClick={(e) => {
              setSelectFilter('d_htl');
            }}
          >
            Discount High to Low
          </Dropdown.Item>
          <Dropdown.Item
            style={{
              backgroundColor: selectFilter == 'p_lth' ? '#D6EAF8' : '',
            }}
            value="p_lth"
            onClick={(e) => {
              setSelectFilter('p_lth');
            }}
          >
            Price Low to High
          </Dropdown.Item>
          <Dropdown.Item
            style={{
              backgroundColor: selectFilter === 'p_htl' ? '#D6EAF8' : '',
            }}
            value="p_htl"
            onClick={(e) => {
              setSelectFilter('p_htl');
            }}
          >
            Price High to Low
          </Dropdown.Item>
          <Dropdown.Item
            style={{
              backgroundColor: selectFilter === 'new_ar' ? '#D6EAF8' : '',
            }}
            value="new_ar"
            onClick={(e) => {
              setSelectFilter('new_ar');
            }}
          >
            New Arrivals
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default MobileDropDown;
