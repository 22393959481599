// export function formatNumber(input) {
//   if (!input) return '0.00';

//   // Parse input as a number
//   const num = parseFloat(input);

//   // Round to 3 decimal places and convert to string
//   let rounded = num.toFixed(3);

//   // Remove unnecessary trailing zeros
//   rounded = rounded.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');

//   return rounded;
// }

export function formatNumber(input) {
  if (!input) return '0.00';

  // Parse input as a number
  const num = parseFloat(input);

  // Round to 3 decimal places
  let rounded = num.toFixed(3);

  // Ensure the result has at least two decimal places
  rounded = rounded.replace(/(\.\d{2})0+$/, '$1'); // Remove trailing zeros after the second decimal place
  rounded = rounded.replace(/(\.\d{1})$/, '$10'); // Add a zero if there's only one digit after the decimal

  return rounded;
}

const isGreaterThan10 = (num) => (+num >= 10 ? num : `0${num}`);

export const formatDate = (inputDate, type) => {
  const parsedTime = new Date(inputDate);
  if (isNaN(parsedTime.getTime())) {
    return <div>Error: Invalid time format</div>;
  }

  const day = parsedTime.getDate();
  const month = parsedTime.toLocaleString('default', { month: 'short' });
  const year = parsedTime.getFullYear();
  let hours = parsedTime.getHours();
  const minutes = parsedTime.getMinutes();
  const seconds = parsedTime.getSeconds();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  let formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
    hours,
  )}:${isGreaterThan10(minutes)}:${isGreaterThan10(seconds)} ${meridiem}`;

  if (type === 'expiry-date') {
    formattedTime = `${month} ${day}, ${year}`;
  }

  if (type === 'future-order') {
    formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
      hours,
    )}:${isGreaterThan10(minutes)} ${meridiem}`;
  }
  return formattedTime;
};

export function formatDateTimeOnly(dateTime, usingFor) {
  const date = new Date(dateTime);

  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format
  hours = String(hours).padStart(2, '0'); // Add leading zero

  if (usingFor === 'createdDateInSaveOrder') {
    // console.log('Date: ', date);
    const seconds = date.getSeconds();
    const hoursIn24Format = date.getHours();
    return `${year}-${month}-${day} ${hoursIn24Format}:${minutes}:${seconds}`;
  } else {
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }
}

export const formatCurrency = (amount) => {
  const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return amount < 0 ? `-${formattedAmount}` : formattedAmount;
};

export const getRoundValue = (value) =>
  value && !isNaN(value) ? (Math.round(value * 100) / 100).toFixed(2) : 0;

export function prioritizeNumber(numbers) {
  // Helper functions to classify numbers
  const isDecimal = (num) => !Number.isInteger(num);
  const isOdd = (num) => Math.abs(num % 2) === 1;
  const isEven = (num) => num % 2 === 0;

  // Prioritize by categories
  const oddDecimals = numbers.filter(
    (num) => isDecimal(num) && isOdd(Math.floor(num)),
  );
  const oddWholeNumbers = numbers.filter(
    (num) => !isDecimal(num) && isOdd(num),
  );
  const evenDecimals = numbers.filter(
    (num) => isDecimal(num) && isEven(Math.floor(num)),
  );
  const evenWholeNumbers = numbers.filter(
    (num) => !isDecimal(num) && isEven(num),
  );

  // Return the first available number from each category in order of priority
  if (oddDecimals.length > 0) return oddDecimals[0];
  if (oddWholeNumbers.length > 0) return oddWholeNumbers[0];
  if (evenDecimals.length > 0) return evenDecimals[0];
  if (evenWholeNumbers.length > 0) return evenWholeNumbers[0];

  // Return null if no numbers are provided
  return 0;
}

export function adjustPrices(
  products = [],
  diffAmount = 0,
  type,
  discountAppliedMore = false,
) {
  console.log('diff amount: ', diffAmount, type);

  // if discountAppliedMore is true then increase the products price and decrease the discount value

  // Sort products by price in descending order
  const sortedProducts = [...products].sort((a, b) => b.price - a.price);

  // Work with the absolute value for calculation
  let remainingDiff = Math.abs(diffAmount);
  console.log('remaining diff: ', remainingDiff);

  // Adjust the prices and maintain the original product structure
  const adjustedProducts = sortedProducts.map((product, index) => {
    if (remainingDiff === 0) return product;
    // console.log('product: ', product);

    const splitAmount = parseFloat(
      (remainingDiff / (sortedProducts.length - index)).toFixed(2),
    );
    console.log('splitAmount: ', splitAmount, sortedProducts.length, index);

    // Deduct split amount from the current price
    const deduction = Math.min(splitAmount, remainingDiff);
    console.log('deduction: ', deduction);
    // const newPrice =
    //   diffAmount < 0
    //     ? getRoundValue(product.price - deduction)
    //     : getRoundValue(product.price + deduction);

    // const newPrice = getRoundValue(
    //   type === 'lpDiscountAmount'
    //     ? product.price + deduction
    //     : product.price - deduction,
    // );

    const newPrice = getRoundValue(
      discountAppliedMore
        ? product.price + deduction
        : product.price - deduction,
    );
    console.log('newPrice: ', newPrice);

    // Update the remaining difference
    remainingDiff -= deduction;
    console.log('remainingDiff: ', remainingDiff);

    console.log('product price: ', product.price);
    console.log('product[type]: ', product[type], type);

    const typePrice =
      product.price -
      parseFloat(parseFloat(newPrice).toFixed(2)) +
      parseFloat(product[type]);
    console.log('type price: ', typePrice);

    // debugger;
    // Return the updated product with adjusted price
    return {
      ...product,
      // oldPrice: product.price,
      // price: parseFloat(newPrice.toFixed(2)),
      pennyFixedPrice: parseFloat(parseFloat(newPrice).toFixed(2)),
      [type]: parseFloat(parseFloat(typePrice).toFixed(2)),
    };
  });

  return adjustedProducts;
}

export const deductCouponDiscount = (itemPrice, percentageDeducCoupon) => {
  const isCouponApplied =
    parseFloat(percentageDeducCoupon) > 0
      ? parseFloat(percentageDeducCoupon)
      : 0;
  console.log('isCouponApplied: ', isCouponApplied, itemPrice);
  const a = itemPrice - (itemPrice * isCouponApplied) / 100;
  // return parseFloat(parseFloat(a).toFixed(2));
  return parseFloat(getRoundValue(a));
};

export const deductLoyaltyPointsDiscount = (
  itemPrice,
  percentageDeducCoupon,
  percentageDeducLoy,
) => {
  const a = deductCouponDiscount(itemPrice, percentageDeducCoupon);

  const isLPApplied =
    parseFloat(percentageDeducLoy) > 0 ? parseFloat(percentageDeducLoy) : 0;
  const b = a - (a * isLPApplied) / 100;

  // return parseFloat(parseFloat(b).toFixed(2));
  return parseFloat(getRoundValue(b));
  // return parseFloat(b);
};
