import React, { useMemo, useState } from 'react';
import Cart from './Cart';
import BillingInfo from './BillingInfo';
import DeliveryPayment from './DeliveryPayment';
import { useSelector } from 'react-redux';

export default function PaymentStepper({
  merchantId,
  orderMethod,
  setVisible,
  visible,
  activeHours,
}) {
  const [isVisited, setisVisited] = useState(false);
  const SingleStoreDataState = useSelector((state) => state.singleStores);

  const storeInfo = useMemo(() => {
    return SingleStoreDataState?.storesData?.store_data;
  }, [SingleStoreDataState]);

  const stepperStatus = 'active-payment-stepper-status';
  const stepperRange = 'active-payment-stepper-range';

  // const [stepperStatus, setStepperStatus] = useState("active-payment-stepper-status")
  // const [stepperRange, setStepperRange] = useState("active-payment-stepper-range")
  const handleActiveTab = (tabname) => {
    const visitTab = (status) => {
      sessionStorage.setItem('orderStatus', status);
      setVisible(status);
    };

    if (tabname === 'myPayment') {
      visitTab(tabname);
    } else if (visible === 'myBillingInfo' && tabname === 'myPayment') {
      visitTab(tabname);
    } else if (visible === 'myDeliveryPayment' && tabname === 'myBillingInfo') {
      visitTab(tabname);
    } else if (visible === 'myDeliveryPayment' && tabname === 'myPayment') {
      visitTab(tabname);
    }
  };

  return (
    <div className="div-for-payment-header-and-content">
      <div className="quickvee-payement-stepper">
        <span
          className={
            visible === 'myPayment' ||
            visible === 'myBillingInfo' ||
            visible === 'myDeliveryPayment'
              ? 'active-payment-component'
              : ''
          }
          onClick={() => {
            handleActiveTab('myPayment');
          }}
        >
          Cart
        </span>
        <p className={visible === 'myPayment' ? stepperStatus : ''}></p>
        <hr
          className={
            visible === 'myBillingInfo' || visible === 'myDeliveryPayment'
              ? stepperRange
              : ''
          }
        />
        <span
          className={
            visible === 'myBillingInfo' || visible === 'myDeliveryPayment'
              ? 'active-payment-component'
              : ''
          }
          onClick={() => {
            handleActiveTab('myBillingInfo');
          }}
        >
          Billing info
        </span>
        <p className={visible === 'myBillingInfo' ? stepperStatus : ''}></p>
        <hr className={visible === 'myDeliveryPayment' ? stepperRange : ''} />
        <span
          className={
            visible === 'myDeliveryPayment' ? 'active-payment-component' : ''
          }
          onClick={() => {
            handleActiveTab('myDeliveryPayment');
          }}
        >
          {/* {orderMethod === 'delivery'
            ? ' Delivery & Payment'
            : ' Pickup & Payment'} */}

          {storeInfo?.orderMethod === 'delivery'
            ? 'Delivery & Payment'
            : 'Pickup & Payment'}

          {/* {storeInfo?.is_deliver === 'Yes' &&
          storeInfo?.is_pickup === 'Yes' &&
          orderMethod
            ? orderMethod === 'delivery'
              ? 'Delivery & Payment'
              : 'Pickup & Payment'
            : storeInfo?.is_deliver === 'Yes' && storeInfo?.is_pickup === 'No'
            ? 'Delivery & Payment'
            : storeInfo?.is_deliver === 'No' && storeInfo?.is_pickup === 'Yes'
            ? 'Pickup & Payment'
            : 'Pickup & Payment'} */}
        </span>
        <p className={visible === 'myDeliveryPayment' ? stepperStatus : ''}></p>
      </div>
      <div className="quickvee-payment-component-content">
        {visible === 'myPayment' && (
          <Cart
            setVisible={setVisible}
            visible={visible}
            merchantId={merchantId}
            activeHours={activeHours}
          />
        )}
        {visible === 'myBillingInfo' && (
          <BillingInfo
            setVisible={setVisible}
            visible={visible}
            merchantId={merchantId}
            setisVisited={setisVisited}
            activeHours={activeHours}
            orderMethod={orderMethod}
          />
        )}
        {visible === 'myDeliveryPayment' && (
          <DeliveryPayment setVisible={setVisible} visible={visible} />
        )}
      </div>
    </div>
  );
}
