import { FormControl, TextField, Box } from '@mui/material';
import React from 'react';
import QButton from '../Main/QButton';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import GuestLogicForm from './guestLogicForm';

const GuestLoginForm = () => {
  const {
    handleGuestDataSubmit,
    onhandleChange,
    formData,
    error,
    setOpenAlert,
    setsubmitmessage,
    submitmessage,
    scrollRef,
    openAlert,
    keyEnter,
  } = GuestLogicForm();

  const goToTop = () => {
    setOpenAlert(false);
    setsubmitmessage('');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (submitmessage) {
    setTimeout(() => {
      goToTop();
    }, 10000);
  }
  return (
    <>
      <form className="login-customer-form">
        <FormControl className="login-customer-form " autoComplete="off">
          <h1>Login As Guest</h1>
          <span className="sub-heading-from">Discover shopping delights!</span>
          <div style={{ width: '325px' }}>
            <div className="row">
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    className="input-field"
                    autoComplete="off"
                    name="firstname"
                    value={formData.firstname}
                    onChange={onhandleChange}
                    onKeyDown={keyEnter}
                  />
                </FormControl>
                <span style={{}} className="input-error">{error.firstnameError}</span>
              </div>
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    className="input-field"
                    autoComplete="off"
                    name="lastname"
                    value={formData.lastname}
                    onChange={onhandleChange}
                    onKeyDown={keyEnter}
                  />
                </FormControl>
                <span className="input-error">{error.lastnameError}</span>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    className="input-field"
                    variant="outlined"
                    autoComplete="off"
                    name="email"
                    value={formData.email}
                    onChange={onhandleChange}
                    onKeyDown={keyEnter}
                  />
                </FormControl>
                <span className="input-error">{error.emailError}</span>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{ position: 'relative', marginBottom: '24px' }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="Phone Number"
                    className="input-field"
                    
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    maxLength={10}
                    inputProps={{ maxLength: 10 }}
                    onKeyPress={(e) =>
                      e.charCode < 48 || e.charCode > 57
                        ? e.preventDefault()
                        : ''
                    }
                    name="phone"
                    value={formData.phone}
                    onChange={onhandleChange}
                    onKeyDown={keyEnter}
                  />
                </FormControl>
                <span className="input-error">{error.phoneError}</span>
              </div>
            </div>
          </div>
          {/* <div className="register-inputs-div">
            <div className="input-outer-div  ">
              <TextField
                label="First Name"
                size="small"
                variant="filled"
                className="customer-login-input c-register custom-input-field"
                autoComplete="off"
                name="firstname"
                value={formData.firstname}
                onChange={onhandleChange}
                onKeyDown={keyEnter}
              />
              <span className="input-error">{error.firstnameError}</span>
            </div>

            <div className="input-outer-div">
              <TextField
                label="Last Name"
                size="small"
                variant="filled"
                className="customer-login-input c-register  custom-input-field"
                autoComplete="off"
                name="lastname"
                value={formData.lastname}
                onChange={onhandleChange}
                onKeyDown={keyEnter}
              />
              <span className="input-error">{error.lastnameError}</span>
            </div>
          </div> */}

          {/* <div className="input-outer-div">
            <TextField
              label="Email"
              className="customer-login-input  custom-input-field"
              size="small"
              variant="filled"
              autoComplete="off"
              name="email"
              value={formData.email}
              onChange={onhandleChange}
              onKeyDown={keyEnter}
            />
            <span className="input-error">{error.emailError}</span>
          </div> */}
          {/* <div className="input-outer-div">
            <TextField
              label="Phone Number"
              className="customer-login-input  custom-input-field"
              size="small"
              variant="filled"
              type="text"
              autoComplete="off"
              maxLength={10}
              inputProps={{ maxLength: 10 }}
              onKeyPress={(e) =>
                e.charCode < 48 || e.charCode > 57 ? e.preventDefault() : ''
              }
              name="phone"
              value={formData.phone}
              onChange={onhandleChange}
              onKeyDown={keyEnter}
            />
            <span className="input-error">{error.phoneError}</span>
          </div> */}
            <div className='Log-in-as-Guest-div'> 
            <QButton onClickHandle={handleGuestDataSubmit} name={'continue'} />
            </div>
         
        </FormControl>
        {/* <div className=""></div> */}

        <Box
          sx={{ width: '100%', height: '80px' }}
          className={submitmessage ? 'form-submit-error-message' : ''}
          ref={scrollRef}
        >
          {submitmessage && (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    className="error-close-icon"
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={goToTop}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {submitmessage}
              </Alert>
            </Collapse>
          )}
        </Box>
      </form>
    </>
  );
};

export default GuestLoginForm;
