import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { ContextSearch } from '../../../Context/SearchContext';
import Quickvee from '../../../Assets/LoginScreen/quickveeLogo.svg';

import Modal from 'react-bootstrap/Modal';

const AgeConfirmationModal = () => {
  const { showAgeConfirmationModal, setShowAgeConfirmationModal } =
    useContext(ContextSearch);

  useEffect(() => {
    if (showAgeConfirmationModal) {
      document.body.classList.add('age-confirmation-modal');
    } else {
      if (document.body.classList.contains('age-confirmation-modal')) {
        document.body.classList.remove('age-confirmation-modal');
      }
    }
  }, [showAgeConfirmationModal]);

  const handleClose = () => {
    setShowAgeConfirmationModal(false);
  };

  const handleConfirm = () => {
    const expireInThreeHours = 3 / 24;
    Cookies.set('ageConfirmed', 'true', { expires: expireInThreeHours }); // Set cookie to expire in a year
    handleClose();
  };

  const handleCancel = () => {
    // window.location.href = 'https://www.google.com'; // Redirect to Google
    window.location.href = 'https://www.quickvee.com/privacy-policy'; // Redirect to Google
  };

  return (
    <>
      <Modal
        show={showAgeConfirmationModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="hello-world"
      >
        <Modal.Body className="age-confirmation-modal-body">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={Quickvee}
              alt="Quickvee"
              style={{ height: '50px', width: '130px' }}
            />
          </div>
          <div className="age_popup_content_area">
            <h4>Age Verification</h4>
            <p>
              The products available on Quickvee are{' '}
              <span>
                age-restricted and intended for adults of legal smoking age
                only.
              </span>
            </p>
            <p>
              By Entering our website, you affirm that you are of legal smoking
              age in your jurisdictions and you agree to be age verified
            </p>
          </div>
          <div className="age_popup_button_area">
            <button onClick={handleConfirm} className="age_popup_black_btn">
              Yes, I am 21 year old
            </button>
            <button onClick={handleCancel} className="age_popup_grey_btn">
              No, I am not of Legal Age
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AgeConfirmationModal;
