import { useState, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useNavigate } from 'react-router-dom';
import {
  BASE_URL_SANDBOX,
  UPDATE_BILLING_INFO,
} from '../../../Constants/Config';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import { useDispatch, useSelector } from 'react-redux';

const BillingInfoLogic = ({
  accountData,
  userData,
  selectedImageBase64,
  setVisible,
  setisVisited,
  merchantId,
  orderMethod,
  setSelectedImageBase64,
}) => {
  const dispatch = useDispatch();
  const { isGuest } = useSelector((state) => state.authUser);
  const {
    validateFirstName,
    validateLastName,
    validateStreetAddress,
    validateZipCode,
    validateCity,
    validateState,
    validateIdNumber,
    validateApartment,
    validatePhoneNumber,
    validateIDproof,
    validateDOB,
    validateExpiredate,
    validateImageProof,
    getImageExtension,
    formatExireDate,
  } = Validation();
  const Navigate = useNavigate();
  const [submitmessage, setsubmitmessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    streetaddress: '',
    apartmentnumber: '',
    city: '',
    state: '',
    zipcode: '',
    idproof: '',
    DOB: '',
    expiredate: '',
    myfile: '',
    idnumber: '',
    errors: {
      firstname: '',
      lastname: '',
      phone: '',
      streetaddress: '',
      apartmentnumber: '',
      city: '',
      state: '',
      zipcode: '',
      idproof: '',
      DOB: '',
      expiredate: '',
      myfile: '',
      idnumber: '',
    },
    // cursorposition: {
    //   firstname: 0,
    //   lastname: 0,
    //   phone: 0,
    //   streetaddress: 0,
    //   apartmentnumber: 0,
    //   city: 0,
    //   zipcode: 0,
    //   idnumber: 0,
    // },
  });

  const sessionData = JSON.parse(localStorage.getItem('guestDelivery_address'));

  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  // const Nameval = /^[a-zA-Z]+$/;
  const onlySingleSpace = /^\s*[a-zA-Z]+(\s[a-zA-Z]+)*\s*$/;
  const Nameval = /^\s*[a-zA-Z]+(\s+[a-zA-Z]+)*\s*$/;

  const Numberval = /^\d*$/;
  const Characterval = /^[A-Za-z\s]+$/;

  useEffect(() => {
    function checkImage(url) {
      let image = new Image();
      let bool = false;
      image.onload = function () {
        if (this.width > 0) {
          // console.log('image exists');
          bool = true;
        }
      };
      image.onerror = function () {
        // console.log("image doesn't exist");
        bool = false;
      };
      image.src = url;
      return bool;
    }

    setValues((prevValues) => ({
      ...prevValues,
      firstname: accountData && accountData.f_name ? accountData.f_name : '',
      lastname: accountData && accountData.l_name ? accountData.l_name : '',
      phone: accountData && accountData.phone ? accountData.phone : '',
      streetaddress:
        isGuest !== 'yes'
          ? accountData && accountData.a_address_line_1
            ? accountData.a_address_line_1
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.cc_street_address ?? ''
          : '',
      apartmentnumber:
        isGuest !== 'yes'
          ? accountData && accountData.a_address_line_2
            ? accountData.a_address_line_2
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.suite_address
          : '',
      city:
        isGuest !== 'yes'
          ? accountData && accountData.a_city
            ? accountData.a_city
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.city ?? ''
          : '',
      state:
        isGuest !== 'yes'
          ? accountData && accountData.a_state
            ? accountData.a_state
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.state ?? ''
          : '',
      zipcode:
        isGuest !== 'yes'
          ? accountData && accountData.a_zip
            ? accountData.a_zip
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.cc_zip_code ?? ''
          : '',
      idproof:
        isGuest === 'yes'
          ? ''
          : isGuest !== 'yes'
          ? accountData && accountData.i_card_type
            ? accountData.i_card_type
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.cc_id_card_Type ?? ''
          : '',
      DOB:
        isGuest === 'yes'
          ? ''
          : isGuest !== 'yes'
          ? accountData &&
            accountData.i_card_dob &&
            accountData.i_card_dob !== '0000-00-00'
            ? accountData.i_card_dob
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.dateOfBirth ?? ''
          : '',
      expiredate:
        isGuest === 'yes'
          ? ''
          : isGuest !== 'yes'
          ? accountData &&
            accountData.i_card_ex_date &&
            accountData.i_card_ex_date !== '0000-00-00'
            ? accountData.i_card_ex_date
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.expiry_date ?? ''
          : '',
      myfile:
        isGuest !== 'yes'
          ? accountData &&
            accountData.i_card_front_img &&
            accountData.image_url &&
            checkImage(accountData.image_url)
            ? accountData.i_card_front_img
            : ''
          : isGuest === 'yes' &&
            localStorage.getItem('guestDelivery_address') !== '' &&
            selectedImageBase64
          ? sessionData?.img_name
          : '',
      idnumber:
        isGuest === 'yes'
          ? ''
          : isGuest !== 'yes'
          ? accountData?.i_card_number
            ? accountData?.i_card_number
            : ''
          : localStorage.getItem('guestDelivery_address') !== ''
          ? sessionData?.card_number ?? ''
          : '',
    }));
  }, [accountData, userData]);
  // ===================
  useEffect(() => {
    if (isGuest === 'yes') {
      if (
        localStorage.getItem('imageName') !== '' &&
        localStorage.getItem('imageName') !== null
      ) {
        localStorage.removeItem('imageName');
      }
    }
  }, []);
  // ===================

  function getFileExtension(filename) {
    const extension = filename.split('.').pop();
    return extension.toLowerCase();
  }

  const BillingInfoInput = async (event) => {
    let { errors } = values;
    const fieldName = event.target.name;
    let { cursorposition } = values;
    const newCursorPosition = event.target.selectionStart;
    const fieldValue =
      fieldName === 'phone'
        ? event.target.value.replace(/[^0-9]/g, '')
        : fieldName === 'zipcode'
        ? event.target.value.replace(/\D/g, '').slice(0, 5)
        : event.target.value;

    // console.log('fieldValue: ', fieldValue, fieldName);
    switch (fieldName) {
      case 'firstname':
        if (fieldValue === '') {
          errors.firstname = 'Please enter First Name';
        } else if (emoji.test(fieldValue)) {
          errors.firstname = 'Emoji not allowed';
        } else if (!Nameval.test(fieldValue) && fieldValue) {
          errors.firstname = 'Alphabets only';
        } else if (!onlySingleSpace.test(fieldValue) && fieldValue) {
          errors.firstname = 'Multiple spaces not allowed';
        } else {
          errors.firstname = '';
        }
        break;
      case 'lastname':
        if (fieldValue === '') {
          errors.lastname = '';
        } else if (emoji.test(fieldValue)) {
          errors.lastname = 'Emoji not allowed';
        } else if (!Nameval.test(fieldValue)) {
          errors.lastname = 'Alphabets only';
        } else if (!onlySingleSpace.test(fieldValue) && fieldValue) {
          errors.lastname = 'Multiple spaces not allowed';
        } else {
          errors.lastname = '';
        }
        break;
      case 'phone':
        // cursorposition.phone = newCursorPosition;
        // await validatePhoneNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.phone = 'Please enter phone number';
        } else if (emoji.test(fieldValue)) {
          errors.phone = 'Emoji not allowed';
        } else if (!PhoneNo.test(fieldValue)) {
          errors.phone = 'Phone no not valid';
        } else {
          errors.phone = '';
        }
        break;
      // case 'streetaddress':
      //   // cursorposition.streetaddress = newCursorPosition;
      //   // await validateStreetAddress(fieldValue, errors);
      //   if (fieldValue === '') {
      //     errors.streetaddress = 'Please enter street address ';
      //   } else if (emoji.test(fieldValue)) {
      //     errors.streetaddress = 'Emoji not allowed';
      //   } else {
      //     errors.streetaddress = '';
      //   }
      //   break;
      // case 'apartmentnumber':
      //   // cursorposition.apartmentnumber = newCursorPosition;
      //   // await validateApartment(fieldValue, errors);
      //   if (fieldValue === '') {
      //     errors.apartmentnumber = 'Please enter Suite/Apartment Number ';
      //   } else if (emoji.test(fieldValue)) {
      //     errors.apartmentnumber = 'Emoji not allowed';
      //   } else {
      //     errors.apartmentnumber = '';
      //   }
      //   break;
      // case 'city':
      //   // cursorposition.city = newCursorPosition;
      //   // await validateCity(fieldValue, errors);
      //   if (fieldValue === '') {
      //     errors.city = 'Please enter city ';
      //   } else if (emoji.test(fieldValue)) {
      //     errors.city = 'Emoji not allowed';
      //   } else if (!Characterval.test(fieldValue)) {
      //     errors.city = 'City  contain character';
      //   } else {
      //     errors.city = '';
      //   }
      //   break;
      // case 'state':
      //   await validateState(fieldValue, errors);
      //   break;
      // case 'zipcode':
      //   // cursorposition.zipcode = newCursorPosition;
      //   // await validateZipCode(fieldValue, errors);
      //   if (fieldValue === '') {
      //     errors.zipcode = 'Please enter zipcode  ';
      //   } else if (emoji.test(fieldValue)) {
      //     errors.zipcode = 'Emoji not allowed';
      //   } else if (fieldValue?.length !== 5) {
      //     errors.zipcode = 'Max 5 number enter';
      //   } else if (!Numberval.test(fieldValue)) {
      //     errors.zipcode = 'Zipcode contain number ';
      //   } else {
      //     errors.zipcode = '';
      //   }
      //   break;
      case 'idproof':
        await validateIDproof(fieldValue, errors);
        break;

      case 'DOB':
        await validateDOB(fieldValue, errors);
        break;
      case 'expiredate':
        await validateExpiredate(fieldValue, errors);
        break;
      case 'idnumber':
        // cursorposition.idnumber = newCursorPosition;
        // await validateIdNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.idnumber = 'Please enter ID number ';
        } else if (emoji.test(fieldValue)) {
          errors.idnumber = 'Emoji not allowed';
        } else {
          errors.idnumber = '';
        }
        break;

      default:
        break;
    }
    setValues((prevValues) => ({
      errors,
      ...prevValues,
      // cursorposition,
      [fieldName]: fieldValue,
    }));
  };
  const handleSubmitBillingInfo = async (e) => {
    e.preventDefault();
    let { errors } = values;
    validateFirstName(values.firstname, errors);
    validateLastName(values.lastname, errors);
    validatePhoneNumber(values.phone, errors);
    validateIDproof(values.idproof, errors);
    validateIdNumber(values.idnumber, errors);
    validateDOB(values.DOB, errors);
    validateExpiredate(values.expiredate, errors);
    validateImageProof(values.myfile, errors);

    // if (orderMethod !== 'pickup') {
    //   validateCity(values.city, errors);
    //   validateStreetAddress(values.streetaddress, errors);
    //   validateState(values.state, errors);
    //   validateZipCode(values.zipcode, errors);
    // }

    const imageCondition =
      isGuest === 'yes' &&
      (Boolean(selectedImageBase64) || accountData?.image_url)
        ? true
        : errors.myfile === '';

    const conditions =
      errors.firstname === '' &&
      errors.lastname === '' &&
      // errors.state === '' &&
      // errors.city === '' &&
      // errors.zipcode === '' &&
      // errors.streetaddress === '' &&
      errors.phone === '' &&
      errors.idproof === '' &&
      errors.DOB === '' &&
      errors.expiredate === '' &&
      errors.myfile === '' &&
      errors.idnumber === '';
    // console.log('imageCondition ', imageCondition, 'conditions', conditions);
    if (imageCondition && conditions) {
      try {
        setLoading(true);
        const imagetype = selectedImageBase64
          ? getImageExtension(selectedImageBase64)
          : getFileExtension(values.myfile);

        let imageResponse = values.myfile; // old image filename

        if (selectedImageBase64) {
          // uploading image
          const res = await axios.post(
            'https://www.quickvees.com/upload.php',
            {
              image_data: selectedImageBase64,
              m_id: merchantId,
              imagetype,
              orderMethod,
              cust_id: userData.id,
              source: 'checkout',
            },
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
          imageResponse = res.data;
          // console.log('imageResponse: ', res);
        }
        // console.log(imageResponse)
        localStorage.setItem('imageName', imageResponse);

        // updating user account details if Image Response does not fail
        if (imageResponse !== 'fail') {
          const data = {
            user_id: userData.id,
            f_name: values.firstname.trim(),
            l_name: values.lastname.trim(),
            phone_no: values.phone,
            cc_street_address: values.streetaddress ?? '',
            cc_zip_code: values.zipcode ?? '',
            city: values.city ?? '',
            card_number: values.idnumber,
            suite_address: values.apartmentnumber ?? '',
            state: values.state ?? '',
            cc_id_card_Type: values.idproof,
            dateOfBirth:
              formatExireDate(values.DOB) !== 'NaN-aN-aN'
                ? formatExireDate(values.DOB)
                : '',
            // : '0000-00-00',
            expiry_date: formatExireDate(values.expiredate),
            img_name: imageResponse, //values.myfile,
            new_img: selectedImageBase64 ? selectedImageBase64 : '',
            old_img: values.myfile ? values.myfile : '',
            imagetype,
          };

          localStorage.setItem('guestDelivery_address', JSON.stringify(data));
          // updating user account details
          // console.log('account data: ', data);
          const response = await axios.post(
            BASE_URL_SANDBOX + UPDATE_BILLING_INFO,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
          if (response.data.status && response.data.status === 200) {
            let data = {
              id: userData && userData.id,
              merchant_id: merchantId,
            };
            if (isGuest === 'yes') {
              data.is_guest = 'yes';
            }
            dispatch(fetchAccoutInfoData(data));
            setVisible('myDeliveryPayment');
            setisVisited(true);
            sessionStorage.setItem('orderStatus', 'myDeliveryPayment');
            setSelectedImageBase64('');
          } else {
            setsubmitmessage(response.data.message);
          }
          setLoading(false);
        } else {
          console.log('Error: Image not uploaded!');
        }
      } catch (error) {
        setLoading(false);
        return new Error(error);
      }
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  return {
    BillingInfoInput,
    handleSubmitBillingInfo,
    values,
    submitmessage,
    setValues,
    loading,
  };
};

export default BillingInfoLogic;
