import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { BASE_URL_SANDBOX, GET_ALL_STORE_CREDIT_HISTORY } from "../../../Constants/Config";

const initialState = {
    loading: false,
    schistoryData: [],
    successMessage: "",
    error: '',
}

// Generate pening , fulfilled and rejected action type
export const fetchStoreCreditData = createAsyncThunk('scHistorySlice/fetchStoreCreditData.', async (data) => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_STORE_CREDIT_HISTORY, data, { headers: { "Content-Type": "multipart/form-data" } })
        // console.log(response)
        if (response.data.status === true) {
           return response.data
        } else{
            return []
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})

const scHistorySlice = createSlice({
    name: 'StoreCredithistorylist',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStoreCreditData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchStoreCreditData.fulfilled, (state, action) => {
            state.loading = false;
            state.schistoryData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchStoreCreditData.rejected, (state, action) => {
            state.loading = false;
            state.schistoryData = {};
            state.error = action.error.message;
        })

    }
})


export default scHistorySlice.reducer