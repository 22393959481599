import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import {  BASE_URL_SANDBOX, GET_ALL_STORES } from "../../../Constants/Config"

const initialState = {
    loading: false,
    allStoresData: [],
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchAllStoresData = createAsyncThunk('allstores/fetchAllStoresData', async () => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_STORES,  { headers: { "Content-Type": "multipart/form-data" } })

        if(response.status === 200){
            return response.data
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


const allStoresSlice = createSlice({
    name: 'allstores',
    initialState,
      extraReducers: (builder) => {
        builder.addCase(fetchAllStoresData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchAllStoresData.fulfilled, (state, action) => {
            state.loading = false;
            state.allStoresData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchAllStoresData.rejected, (state, action) => {
            state.loading = false;
            state.allStoresData = {};
            state.error = action.error.message;
        })
               
    }
})


export default allStoresSlice.reducer