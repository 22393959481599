import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import DeliveryAmountIcon from '../../../Assets/Payment/DeliveryAmount.svg';

const DeliveryPriceModal = ({ minamount, buttonText, disableCheckout }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <p className="button-for-minamount-on-delivery" onClick={handleClickOpen}>
        Checkout With Delivery ${buttonText}{' '}
      </p> */}

      <Button
        disabled={disableCheckout}
        onClick={handleClickOpen}
        className="button-for-minamount-on-delivery"
      >
        Checkout With Delivery ${buttonText}
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="delivery-amount-modal-cart"
      >
        <div className="delivery-address-not-selected">
          <div className="not-select-empty-delivery-content">
            <img src={DeliveryAmountIcon} alt="No-products" />
            <h1>Sorry, minimum delivery amount is ${minamount}</h1>
            {/* <p>Please Select Delivery Address</p> */}
          </div>
        </div>

        {/* <p className='delivery-amount-modal-cart-text'>Please Select Delivery Address</p> */}

        <button
          onClick={handleClose}
          className="delivery-amount-button-cart-ok"
        >
          Ok
        </button>
      </Dialog>
    </>
  );
};

export default DeliveryPriceModal;
