import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import {  BASE_URL_SANDBOX, GET_ALL_STATE } from "../../../Constants/Config"

const initialState = {
    loading: false,
    allStateList: [],
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchAllStates = createAsyncThunk('allStates/fetchAllStates', async () => {
    try {
        const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_STATE,  { headers: { "Content-Type": "multipart/form-data" } })

        if(response.status === 200){
            // console.log(response.data)
            return response.data.result
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
})


const allStatesSlice = createSlice({
    name: 'allStates',
    initialState,
      extraReducers: (builder) => {
        builder.addCase(fetchAllStates.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchAllStates.fulfilled, (state, action) => {
            state.loading = false;
            state.allStateList = action.payload;
            state.error = '';
        })
        builder.addCase(fetchAllStates.rejected, (state, action) => {
            state.loading = false;
            state.allStateList = {};
            state.error = action.error.message;
        })
               
    }
})


export default allStatesSlice.reducer