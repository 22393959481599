import React from 'react';
import SingleProductListing from '../Products/SingleProductListing';
import { useLocation } from 'react-router-dom';
import SingleCategory from '../StoreCategories/SingleCategory';
// import FeaturedCategories from '../StoreCategories/FeaturedCategories';
// import StoreBanners from '../StoreCategories/StoreBanners';

const SingleStoreAllInformationPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieve data from query parameters
  const merchantId = location.pathname.replace('/merchant/', '');
  const orderMethod = searchParams.get('orderMethod');
  const categorysId = searchParams.get('categoryid');
  const categoryName = searchParams.get('category');

  return (
    <>
      <div className="SingleStoreAllInformationPage">
        {/* <FeaturedCategories />
    <StoreBanners />  */}

        <div className="single-store-single-category-product-listing-section">
          {categorysId === null ? (
            <SingleProductListing
              orderMethod={orderMethod}
              merchantId={merchantId}
            />
          ) : (<div>
            <SingleCategory
              merchantId={merchantId}
              orderMethod={orderMethod}
              categorysId={categorysId}
              categoryName={categoryName}
            />

          </div>

          )}
        </div>
      </div>
    </>
  );
};

export default SingleStoreAllInformationPage;
