import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  GET_FEATURED_PRODUCTS,
} from '../../../Constants/Config';

const initialState = {
  loading: false,
  featuredProductsData: [],
  error: '',
};

export const fetchFeaturedProducts = createAsyncThunk(
  'featuredProducts/fetchFeaturedProducts',
  async (data) => {
    const response = await axios.post(
      BASE_URL_SANDBOX + GET_FEATURED_PRODUCTS,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );

    if (response.data.status === 200) {
      return response.data.result.feature_product_data;
    }
  },
);

const featuredProductsSlice = createSlice({
  name: 'featuredProducts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFeaturedProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFeaturedProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.featuredProductsData = action.payload;
      state.error = '';
    });
    builder.addCase(fetchFeaturedProducts.rejected, (state, action) => {
      state.loading = false;
      state.featuredProductsData = [];
      state.error = action.error.message;
    });
  },
});

export default featuredProductsSlice.reducer;
