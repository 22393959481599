import React, { useState, useEffect } from 'react';
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RemoveIcon from '../../../Assets/Payment/removepoints.svg';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CouponTipSection from './CouponTipSection';
import Checkbox from '@mui/material/Checkbox';
import MapIcon from '../../../Assets/Payment/Map.svg';
import EditIcon from '../../../Assets/Payment/Edit.svg';
// import DeleteIcon from '../../../Assets/Payment/delete.svg';
import MasterCardIcon from '../../../Assets/Payment/Mastercard.svg';
import VisaIcon from '../../../Assets/Payment/visa.svg';
import EditPaymentCardDetail from './EditPaymentCardDetail';
import dayjs, { Dayjs } from 'dayjs';
import DeliveryAddress from '../../Account/AccountInfo/DeliveryAddress';
import EditDeliveryAddress from '../../Account/AccountInfo/EditDeliveryAddress';
import { useSelector, useDispatch } from 'react-redux';
import DeliveryAddressLogic from '../../Account/AccountInfo/DeliveryAddressLogic';
import { deleteSingleCard } from '../../../redux/features/Account/userCardsSlice';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  CHECK_ADDRESS_IN_RANGE,
  GET_DATE_TIME,
} from '../../../Constants/Config';
import { DateTime } from 'luxon';
import { fetchAllUserCards } from '../../../redux/features/Account/userCardsSlice';
import Validation from '../../../Constants/Validation';
// import {ValidateDeliveryDate} from '../../../Constants/Validation'
import { format, parse } from 'date-fns';
import DeleteCardDetails from './DeleteCardDetails';
// =================
import CouponTipSectionLogic from './CouponTipSectionLogic';
import DeleteAddressPopup from './DeleteAddressPopup';
import DeliveryAddressCard from './DeliveryAddressCard';
import usePaymentCard from '../../../hooks/usePaymentCard';
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';
import Loaderfile from '../../Payment/Loader/loaderfile';
import ScreenLoader from '../../../Assets/screenLoader/Quickvee_loader_.gif';
import { TryRounded } from '@mui/icons-material';
const DeliveryPayment = ({ setVisible, visible }) => {
  const { values, setValues } = CouponTipSectionLogic({});
  const dispatch = useDispatch();

  const isMobile = useMediaQuery('(max-width:768px)');
  const authUserData = useSelector((state) => state.authUser.UserData);
  const cardList = useSelector((state) => state?.userCards?.userCards);
  const [availableTime, setAvailableTime] = useState();
  const [deliverySameBilling, setDeliverySameBilling] = useState(false);
  const [selectedDate, setSeletedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [deliveryData, setDeliveryData] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState();
  const deliveryDataState = useSelector((state) => state.accountInfo);
  const storeDataState = useSelector((state) => state.singleStores);
  const { isAuthenticated } = useSelector((state) => state.authUser);
  const { isGuest } = useSelector((state) => state.authUser);
  const [storeData, setStoreData] = useState('');
  const [deliveryAddressError, setDeliveryAddressError] = useState({
    date: '',
    time: '',
    pickupLaterDate: '',
    picklaterTime: '',
  });
  const { handleDeleteAddress } = DeliveryAddressLogic({
    checkradius: true,
    setDeliveryAddress,
  });
  const [paymentDPdata, setpaymentDPdata] = useState('');
  const [IsHolday, setIsHoliday] = React.useState(false);
  const {
    formatCreditCardNumber,
    validateCardNumber,
    validateCardExpiredate,
    validateCvv,
    // ValidateDeliveryDate,
  } = Validation();
  const deliveryGuestAddress = useSelector(
    (state) => state.deliveryAddress.addresses,
  );

  const [cardDetail, setCardDetail] = useState({
    cardnumber: '',
    cvv: '',
    expiredate: '',
    saveFuture: false,
    errors: {
      cardnumber: '',
      cvv: '',
      expiredate: '',
    },
  });

  // Setting Payment Method based on Store cc_payment status
  useEffect(() => {
    const onlyCash = storeData?.store_option?.cc_payment === '0';
    const onlyCard = storeData?.store_option?.cc_payment === '1';
    const bothAccepted = storeData?.store_option?.cc_payment === '2';

    // store is accepting Cash and Card
    if (bothAccepted) {
      setPaymentType('newcash');
      return;
    }

    if (onlyCard) {
      const newpaymentdiv = document.getElementById('addnewpayment');
      newpaymentdiv?.classList?.add('addnewpayment');
      setSelectedCard('');
      setPaymentType('newdebitcard');
      return;
    }

    if (onlyCash) {
      setPaymentType('newcash');
      return;
    }
  }, [storeData]);

  const handleCardInputChange = async (event) => {
    let { errors } = cardDetail;
    const fieldName = event.target.name;
    let fieldValue;

    if (fieldName === 'cardnumber' || fieldName === 'cvv') {
      fieldValue = event.target.value.replace(/[^0-9]/g, '');
    } else if (fieldName === 'saveFuture') {
      fieldValue = event.target.checked;
    } else {
      fieldValue = event.target.value;
    }

    switch (fieldName) {
      case 'cardnumber':
        await validateCardNumber(fieldValue, errors);
        break;
      case 'cvv':
        await validateCvv(fieldValue, errors);
        break;
      case 'expiredate':
        await validateCardExpiredate(fieldValue, errors);
        break;

      default:
        break;
    }

    setCardDetail((prevState) => ({
      errors,
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleExpireDate = (e) => {
    setCardDetail((prevState) => ({
      ...prevState,
      expiredate: dayjs(new Date(e)).format('MM/YYYY'),
      errors: {
        expiredate: '',
      },
    }));
  };

  // setting store data
  useEffect(() => {
    if (!storeDataState.loading) {
      const merchantStoreData = storeDataState?.storesData?.store_data;
      setStoreData(merchantStoreData); // setting store data
    }
  }, [storeDataState]);

  const timerSelector = useSelector((state) => state.singleStores);
  const [activeHours, setActiveHours] = useState(false);
  useEffect(() => {
    timeSlote();
  }, [timerSelector]);

  const timeSlote = () => {
    const time_zone = timerSelector?.storesData?.store_data?.mer_timeZone;
    timerSelector.storesData?.store_data?.time_slot &&
      timerSelector.storesData.store_data.time_slot.forEach((result) => {
        const utcDateTime = DateTime.utc();
        const localDateTime = utcDateTime.setZone(time_zone);
        const dayName = localDateTime.toLocaleString({ weekday: 'long' });
        // console.log(dayName)
        const curr_time = localDateTime.toFormat('HH:mm');
        let is_holiday = false;
        if (dayName === result?.day_name) {
          if (result?.is_holiday == 1) {
            is_holiday = true;
            setIsHoliday(true);
          } else {
            is_holiday = false;
            setIsHoliday(false);
          }
          let open_time = result?.open_time?.split(',');
          let close_time = result?.close_time?.split(',');
          // console.log(open_time)
          // console.log(close_time)
          const convertToMinutes = (time) => {
            const [hours, minutes] = time?.split(':').map(Number);
            return hours * 60 + minutes;
          };

          const currTimeMinutes = convertToMinutes(curr_time);
          let isActive = false;
          open_time.forEach((open, index) => {
            const openTime = open;
            const closeTime = close_time[index];
            const openTimeMinutes = convertToMinutes(openTime);
            const closeTimeMinutes = convertToMinutes(closeTime);
            // console.log(currTimeMinutes)
            if (
              openTimeMinutes <= currTimeMinutes &&
              currTimeMinutes <= closeTimeMinutes
            ) {
              isActive = true;
              return;
            }
          });

          setDeliveryType(
            isActive && !is_holiday ? 'DeliveryNow' : 'DeliveryLater',
          );
          setPickupType(isActive && !is_holiday ? 'pickupNow' : 'pickupLater');
          setActiveHours(isActive);

          // console.log('Yes, it matches the day');
        } else {
          // console.log('No, it does not match the day');
        }
      });
  };

  useEffect(() => {
    if (!deliveryDataState.loading) {
      const address =
        deliveryDataState?.accountInfoData?.address?.length >= 1
          ? deliveryDataState?.accountInfoData?.address?.slice(-4)
          : [];
      setDeliveryData(address);
    }
  }, [deliveryDataState]);

  const [paymentType, setPaymentType] = useState('newcash');
  let order_method = localStorage.getItem('orderRecord');

  const [addCardClass, newAddCardClass] = useState('');
  useEffect(() => {
    if (
      (storeData.store_option?.cash_payment_delivery === '1' &&
        order_method === 'delivery') ||
      (storeData.store_option?.cash_payment_pickup === '1' &&
        order_method === 'pickup' &&
        storeData.store_option?.cc_payment !== '1')
    ) {
      setPaymentType('newcash');
      newAddCardClass('');
    } else {
      if (
        (storeData.store_option?.cash_payment_delivery === '0' &&
          order_method === 'delivery') ||
        (storeData.store_option?.cash_payment_pickup === '0' &&
          order_method === 'pickup' &&
          (storeData.store_option?.cc_payment === '1' ||
            storeData.store_option?.cc_payment === '2'))
      ) {
        setPaymentType('newdebitcard');
        newAddCardClass('addnewpayment');
      }
    }
  }, [storeData?.store_option?.cash_payment_delivery]);

  const [deliveryType, setDeliveryType] = useState('');
  const [pickupType, setPickupType] = useState('');

  const [selectedCard, setSelectedCard] = useState('');

  const handlePyamentMethod = (event, item) => {
    // const oldpaymentdiv = document.getElementById('addoldpayment')
    const newpaymentdiv = document.getElementById('addnewpayment');
    setPaymentType(
      event.target.value === undefined ? item : event.target.value,
    );
    // console.log(event.target.value, item);
    if (event.target.value === 'newdebitcard') {
      newpaymentdiv?.classList?.add('addnewpayment');
      newpaymentdiv?.classList?.remove('removenewpayment');
      setSelectedCard('');
    } else if (event.target.value === 'newcash') {
      setCardDetail(() => ({
        cardnumber: '',
        cvv: '',
        expiredate: '',
        saveFuture: false,
        errors: {
          cardnumber: '',
          cvv: '',
          expiredate: '',
        },
      }));

      newpaymentdiv?.classList?.remove('addnewpayment');
      newpaymentdiv?.classList?.add('removenewpayment');
      setSelectedCard('');
    } else {
      newpaymentdiv?.classList?.remove('addnewpayment');
      newpaymentdiv?.classList?.add('removenewpayment');
    }
  };

  const handleSelectedCard = (item) => {
    setSelectedCard(item);
    setCardDetail({
      cardnumber: '',
      cvv: '',
      expiredate: '',
      saveFuture: false,
      errors: {
        cardnumber: '',
        cvv: '',
        expiredate: '',
      },
    });
  };

  function formatDate(dateString) {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };

    const parts = dateString.split(' ');
    const year = parts[3];
    const month = months[parts[1]];
    let day = parts[2]?.replace(/(?:st|nd|rd|th)/, '');
    if (day < 10) {
      day = '0' + day;
    } else {
      day = day;
    }

    return `${year}/${month}/${day}`;
  }

  useEffect(() => {
    let dateDelivery = selectedDate?.date;
    let formattedDate = '';
    let timeSlot = '';
    if (
      (deliveryType == '' || deliveryType == 'DeliveryNow') &&
      storeData?.orderMethod === 'delivery'
    ) {
      formattedDate = '';
      timeSlot = '';
    } else if (
      (pickupType == '' || pickupType == 'pickupNow') &&
      storeData?.orderMethod === 'pickup'
    ) {
      formattedDate = '';
      timeSlot = '';
    } else if (dateDelivery !== undefined) {
      formattedDate = formatDate(dateDelivery);
      timeSlot = selectedTime;
    }
    setpaymentDPdata({
      newCard: cardDetail,
      selectedCard: selectedCard,
      paymentType: paymentType,
      pickUpLaterDate: formattedDate,
      pickupTime: timeSlot,
    });
    // console.log("selectedCard" , selectedCard)
  }, [
    cardDetail,
    selectedCard,
    selectedDate,
    selectedTime,
    deliveryType,
    pickupType,
  ]);

  const handleDeliverChange = (event) => {
    setDeliveryType(event.target.value);
    setDeliveryAddressError({
      date: '',
      time: '',
      pickupLaterDate: '',
      picklaterTime: '',
    });
  };

  const handlePickupChange = (event) => {
    setPickupType(event.target.value);
    setDeliveryAddressError({
      date: '',
      time: '',
      pickupLaterDate: '',
      picklaterTime: '',
    });
  };

  const handleDate = async (e) => {
    // console.log(e);
    const formattedDate = dayjs(e).format('ddd, MMM D[th] YYYY');
    const formattedDay = dayjs(e).format('dddd');
    // console.log(formattedDay);
    if (formattedDate && formattedDay) {
      setSeletedDate({
        date: formattedDate,
        day: formattedDay,
      });

      const data = {
        merchant_id: storeData?.merchant_id,
        order_method: storeData?.orderMethod,
        day: formattedDay,
        day_date: formattedDate,
      };
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_DATE_TIME,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        if (response.data.status === 200) {
          setAvailableTime(response.data.result);
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const handleTime = (e) => {
    setSelectedTime(e.target.value);
    // console.log(e.target.value)
  };

  const futureDays = parseInt(storeData?.futureOrder);
  const availableDays = dayjs().add(futureDays, 'day');

  const handleChangeBillingAddress = async (event) => {
    if (event.target.checked) {
      try {
        const billingaddress = deliveryDataState?.accountInfoData?.message;
        const addressPayload = {
          merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
          cc_address: billingaddress?.a_address_line_1,
          cc_address_2: billingaddress?.a_address_line_2,
          cc_city: billingaddress?.a_city,
          cc_state: billingaddress?.a_state,
          cc_zip: billingaddress?.a_zip,
        };
        const billingAddress = JSON.parse(
          localStorage.getItem('guestDelivery_address'),
        );
        if (isGuest === 'yes') {
          Object.assign(addressPayload, {
            cc_address: billingAddress.cc_street_address,
            cc_address_2: billingAddress.suite_address,
            cc_city: billingAddress.city,
            cc_state: billingAddress.state,
            cc_zip: billingAddress.cc_zip_code,
          });
        }

        const response = await axios.post(
          BASE_URL_SANDBOX + CHECK_ADDRESS_IN_RANGE,
          addressPayload,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        if (response.data.status === 200) {
          // console.log(response.data)
          setDeliverySameBilling({
            ...addressPayload,
            inRange: response.data.status === 200,
            radiusData: response.data.result || {},
          });

          if (isGuest === 'yes') {
            billingAddress.a_zip = billingAddress.cc_zip_code;
            billingAddress.a_city = billingAddress.city;
            billingAddress.a_state = billingAddress.state;
            billingAddress.a_address_line_1 = billingAddress.cc_street_address;
            billingAddress.a_address_line_2 = billingAddress.suite_address;
            delete billingAddress.cc_zip_code;
            delete billingAddress.city;
            delete billingAddress.state;
            delete billingAddress.cc_street_address;
            delete billingAddress.suite_address;

            let mydata = {
              radiusData: response?.data?.result || {},
              address: billingAddress,
              billing: 'yes',
            };
            setDeliveryAddress(mydata);
          } else {
            let mydata = {
              radiusData: response?.data?.result || {},
              address: billingaddress,
              billing: 'yes',
            };
            setDeliveryAddress(mydata);
          }
        } else {
          setDeliverySameBilling({
            ...addressPayload,
            inRange: false,
            radiusData: {},
          });
        }
      } catch (error) {
        throw error;
      }
    } else {
      setDeliveryAddress(null);
      setDeliverySameBilling(false);
    }
  };

  const handleSelectAddress = async (address) => {
    let address1 = {
      d_f_name: address.cc_f_name,
      d_l_name: address.cc_l_name,
      d_mobile: address.cc_phone_no,
      a_address_line_1: address.cc_street_address,
      a_zip: address.cc_zip_code,
      a_city: address.city,
      a_state: address.state,
      a_address_line_2: address.suite_address,
      userId: address.id,
    };
    try {
      let addressPayload = '';
      if (isGuest !== 'yes') {
        addressPayload = {
          merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
          cc_address: address?.a_address_line_1,
          cc_address_2: address?.a_address_line_2,
          cc_city: address?.a_city,
          cc_state: address?.a_state,
          cc_zip: address?.a_zip,
        };
      } else {
        addressPayload = {
          merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
          cc_address: address?.a_address_line_1,
          cc_address_2: address?.a_address_line_2,
          cc_city: address?.a_city,
          cc_state: address?.a_state,
          cc_zip: address?.a_zip,
        };
      }

      const response = await axios.post(
        BASE_URL_SANDBOX + CHECK_ADDRESS_IN_RANGE,
        addressPayload,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      if (response.data.status === 200) {
        let mydata = {
          radiusData: response?.data?.result || {},
          address: isGuest !== 'yes' ? address : address,
          billing: 'no',
        };
        setDeliveryAddress(mydata);
      }
    } catch (error) {
      throw error;
    }
    setDeliverySameBilling(false);
  };

  useEffect(() => {
    const data_card = {
      user_id: authUserData?.id,
      merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
    };

    if (
      authUserData?.id &&
      storeDataState?.storesData?.store_data?.merchant_id
    ) {
      dispatch(fetchAllUserCards(data_card));
    }
  }, [authUserData]);

  const existingDataString = localStorage.getItem('guestAddDeliveryAddress');

  // Parse existing data from JSON or initialize an empty array
  const existingData = existingDataString ? JSON.parse(existingDataString) : [];

  //  ===============================
  // const [deliveryAddressError,setDeliveryAddressError]=useState({date:'',time:'',pickupLaterDate:'',picklaterTime:''})
  //   const handleCheckDeliveryLater=()=>{
  //       let errorData = {...deliveryAddressError};
  //       let error=false
  //       if (deliveryType === "DeliveryLater" && (!selectedDate || selectedDate === "undefined")) {
  //         errorData.date = "Please select Date ";
  //         error=true
  //       }else{
  //         errorData.date = "";
  //         error=false

  //       }
  //       if(deliveryType === "DeliveryLater" && (!selectedTime || selectedTime === "undefined"))
  //       {
  //         errorData.time = "Please select time";
  //         error=true

  //       }else{
  //         errorData.date = "";
  //         error=false

  //       }
  //       //
  //       if (pickupType === "pickupLater" && (!selectedDate || selectedDate === "undefined")) {
  //         errorData.pickupLaterDate = "Please select Date ";
  //         error=true
  //       }else{
  //         errorData.pickupLaterDate = "";
  //         error=false

  //       }
  //       if(pickupType === "pickupLater" && (!selectedTime || selectedTime === "undefined"))
  //       {
  //         errorData.picklaterTime = "Please select time";
  //         error=true

  //       }else{
  //         errorData.picklaterTime = "";
  //         error=false

  //       }
  //       console.log(errorData)
  //       setDeliveryAddressError(errorData)
  //       if(error==true)
  //       {
  //         return false

  //       }else{
  //         return true

  //       }

  //     };

  const handleCheckDeliveryLater = () => {
    let errorData = { ...deliveryAddressError };
    let error = false;

    if (
      deliveryType === 'DeliveryLater' &&
      storeData?.orderMethod === 'delivery'
    ) {
      if (!selectedDate || selectedDate === 'undefined') {
        errorData.date = 'Please select Date';
        error = true;
      } else {
        errorData.date = '';
      }

      if (!selectedTime || selectedTime === 'undefined') {
        errorData.time = 'Please select time';
        error = true;
      } else {
        errorData.time = '';
      }
    }

    if (pickupType === 'pickupLater' && storeData?.orderMethod === 'pickup') {
      if (!selectedDate || selectedDate === 'undefined') {
        errorData.pickupLaterDate = 'Please select Date';
        error = true;
      } else {
        errorData.pickupLaterDate = '';
      }

      if (!selectedTime || selectedTime === 'undefined') {
        errorData.picklaterTime = 'Please select time';
        error = true;
      } else {
        errorData.picklaterTime = '';
      }
    }

    setDeliveryAddressError(errorData);
    return !error;
  };

  // ===================================

  const timerSelectorDayValue = useSelector((state) => state.singleStores);
  const isDateDisabled = (date) => {
    const dayOfWeek = dayjs(date).day();
    const time_zone1 = timerSelector?.storesData?.store_data?.time_slot;
    const holidayDayCodes = [];

    Array.isArray(time_zone1) &&
      time_zone1?.map((result) => {
        if (result.is_holiday == 1) {
          holidayDayCodes.push(result.day_code);
        }
      });
    return holidayDayCodes.includes(dayOfWeek.toString());
  };

  const deleteCard = (cardItem) => {
    const data = {
      paymethod_key: cardItem?.key,
      merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
      cid: authUserData?.id,
    };

    dispatch(deleteSingleCard(data));
  };
  // console.log(deliveryType);
  // console.log(pickupType);

  // addnewpayment,newdebitcard
  useEffect(() => {
    // console.log("Updated paymentType:", paymentType);    isGuest!=="yes" &&
    if (paymentType == '') {
      setPaymentType('newdebitcard');
      newAddCardClass('addnewpayment');
    }
  }, [paymentType]);

  const [loading, setLoading] = useState(false);
  const [placingOrderLoader, setPlacingOrderLoader] = useState(false);

  const setPositionLoader = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    zIndex: '9',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  return (
    <>
      {deliveryDataState?.loading ? (
        <div className="loaderarea">
          <Loaderfile />
        </div>
      ) : (
        <>
          <div className="payment-stepper-cart">
            <>
              <div className="payment-stepper-customer-detail-section">
                <div className="payment-stepper-single-billing-info-detail">
                  {storeData?.orderMethod === 'delivery' ? (
                    <>
                      <div className="payment-delivery-section">
                        <span>Delivery Option</span>

                        <div style={{ marginBottom: '20px' }}>
                          <RadioGroup
                            className="delivery-payment-radio-button"
                            defaultValue={'DeliveryNow'}
                            value={deliveryType}
                            onChange={handleDeliverChange}
                          >
                            {activeHours && !IsHolday ? (
                              <FormControlLabel
                                className="lablePickup"
                                style={
                                  deliveryType === 'DeliveryNow' ||
                                  deliveryType === undefined
                                    ? { opacity: '1' }
                                    : { opacity: '0.5' }
                                }
                                onChange={handleDeliverChange}
                                value="DeliveryNow"
                                control={<Radio />}
                                label="Delivery Now"
                              />
                            ) : (
                              ''
                            )}

                            {
                              // console.log(storeData.future_ordering)
                              storeData.future_ordering == 1 ? (
                                <FormControlLabel
                                  className="lablePickup"
                                  style={
                                    deliveryType === 'DeliveryLater'
                                      ? { opacity: '1' }
                                      : { opacity: '0.5' }
                                  }
                                  onChange={handleDeliverChange}
                                  value="DeliveryLater"
                                  control={<Radio />}
                                  label="Delivery Later"
                                />
                              ) : (
                                ''
                              )
                            }
                          </RadioGroup>
                        </div>
                        <div
                          className={`select-delivery-hour-and-date ${
                            deliveryType === 'DeliveryNow'
                              ? 'DeliveryNow-active'
                              : ''
                          } ${
                            deliveryType === 'DeliveryLater'
                              ? 'DeliveryLater-active'
                              : ''
                          } `}
                        >
                          <div>
                            <div className="row">
                              <div
                                className="col-6 col-xs-6 col-sm-6 col-md-6"
                                style={{
                                  position: 'relative',
                                  marginBottom: '24px',
                                }}
                              >
                                <div>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      fullWidth
                                      components={['DatePicker']}
                                    >
                                      <MobileDatePicker
                                        // format="LL"
                                        label="Choose Date"
                                        slotProps={{
                                          textField: {
                                            variant: 'outlined',
                                            readOnly: true,
                                          },
                                        }}
                                        className="custom-date-picker-width"
                                        disablePast
                                        maxDate={availableDays}
                                        // onChange={(e) => handleDate(e)}
                                        onChange={handleDate}
                                        name="deliverydate"
                                        //  value={selectedDate}
                                        value={dayjs(
                                          new Date(Date.parse(selectedDate)),
                                        )}
                                        shouldDisableDate={isDateDisabled}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                                <span className="input-error">
                                  {deliveryAddressError.date}
                                </span>
                              </div>

                              <div
                                className="col-6 col-xs-6 col-sm-6 col-md-6"
                                style={{
                                  position: 'relative',
                                  marginBottom: '24px',
                                  paddingRight: '0',
                                }}
                              >
                                <div>
                                  <FormControl
                                    className="form-select-formcontrol"
                                    fullWidth
                                  >
                                    <InputLabel id="demo-simple-select-filled-label ">
                                      Choose Time
                                    </InputLabel>
                                    <Select
                                      className="select-OnFocus"
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select"
                                      label="Choose Time"
                                      name="deliverytime"
                                      onChange={(e) => handleTime(e)}
                                      value={selectedTime}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {availableTime &&
                                        availableTime.map((item, index) => (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <span className="input-error">
                                  {deliveryAddressError.time}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* <div className="choose-date-on-payment">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        
                        <DemoContainer components={['DatePicker']}>
                          <MobileDatePicker
                            format="LL"
                            label="Choose Date"
                            slotProps={{
                              textField: { variant: 'outlined', readOnly: true },
                            }}
                            className="custom-date-picker-width"
                            disablePast
                            maxDate={availableDays}
                            // onChange={(e) => handleDate(e)}
                            onChange={handleDate}
                            name="deliverydate"
                            //  value={selectedDate}
                            value={dayjs(new Date(Date.parse(selectedDate)))}
                            shouldDisableDate={isDateDisabled}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <span className="input-error">
                        {deliveryAddressError.date}aaa
                      </span>
                    </div> */}

                          {/* <div>
                      <FormControl
                        variant="filled"
                        className="choose-time-on-payment"
                      >
                        <InputLabel id="demo-simple-select-filled-label ">
                          Choose Time
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          className="modal-form-single-input "
                          name="deliverytime"
                          onChange={(e) => handleTime(e)}
                          value={selectedTime}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {availableTime &&
                            availableTime.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                        <span className="input-error">
                          {deliveryAddressError.time}
                        </span>
                      </FormControl>
                    </div> */}
                        </div>
                        <hr
                          style={{
                            background: '#D3D3D3',
                            borderWidth: '0px',
                            margin: '0px 0 20px',
                            height: '1px',
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="payment-delivery-section">
                        <span>Pickup Option </span>
                        <div style={{ marginBottom: '20px' }}>
                          <RadioGroup
                            defaultValue={'pickupLater'}
                            value={pickupType}
                            onChange={handlePickupChange}
                          >
                            {activeHours && !IsHolday ? (
                              <FormControlLabel
                                className="lablePickup"
                                style={
                                  pickupType === 'pickupNow' ||
                                  deliveryType === undefined
                                    ? { opacity: '1' }
                                    : { opacity: '0.5' }
                                }
                                onChange={handlePickupChange}
                                value="pickupNow"
                                control={<Radio />}
                                label="Pickup Now"
                              />
                            ) : (
                              ''
                            )}

                            <FormControlLabel
                              className="lablePickup"
                              style={
                                pickupType === 'pickupLater'
                                  ? { opacity: '1' }
                                  : { opacity: '0.5' }
                              }
                              onChange={handlePickupChange}
                              value="pickupLater"
                              control={<Radio />}
                              label="Pickup Later"
                            />
                          </RadioGroup>
                        </div>

                        <div
                          className={`select-delivery-hour-and-date
                    
                     ${
                       pickupType === 'pickupLater'
                         ? 'DeliveryLater-active'
                         : ''
                     }${pickupType === 'pickupNow' ? 'DeliveryNow-active' : ''} 
                     `}
                        >
                          <div>
                            <div className={`row`}>
                              <div
                                className="col-6 col-xs-6 col-sm-6 col-md-6"
                                style={{
                                  position: 'relative',
                                  marginBottom: '24px',
                                }}
                              >
                                <div>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      fullWidth
                                      components={['DatePicker']}
                                    >
                                      <MobileDatePicker
                                        // format="LL"
                                        label="Choose Date"
                                        slotProps={{
                                          textField: {
                                            variant: 'outlined',
                                            readOnly: true,
                                          },
                                        }}
                                        className="custom-date-picker-width"
                                        disablePast
                                        maxDate={availableDays}
                                        onChange={(e) => handleDate(e)}
                                        name="pickupdate"
                                        // value={selectedDate}
                                        value={dayjs(
                                          new Date(Date.parse(selectedDate)),
                                        )}
                                        shouldDisableDate={isDateDisabled}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                  <span className="input-error">
                                    {deliveryAddressError.pickupLaterDate}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="col-6 col-xs-6 col-sm-6 col-md-6"
                                style={{
                                  position: 'relative',
                                  marginBottom: '24px',
                                  paddingRight: '0',
                                }}
                              >
                                <div>
                                  <FormControl
                                    fullWidth
                                    className="form-select-formcontrol"
                                  >
                                    <InputLabel id="demo-simple-select-filled-label ">
                                      Choose Time
                                    </InputLabel>
                                    <Select
                                      className="select-OnFocus"
                                      labelId="demo-simple-select-filled-label"
                                      // className="modal-form-single-input "
                                      id="demo-simple-select"
                                      label="Choose Time"
                                      name="pickuptime"
                                      onChange={(e) => handleTime(e)}
                                      value={selectedTime}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {availableTime &&
                                        availableTime.map((item, index) => (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    <span className="input-error">
                                      {deliveryAddressError.picklaterTime}
                                    </span>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          style={{
                            background: '#D3D3D3',
                            borderWidth: '0px',
                            margin: '0px 0 20px',
                            height: '1px',
                          }}
                        />
                      </div>
                    </>
                  )}

                  {storeData?.orderMethod === 'delivery' ? (
                    <div className="payment-delivery-address-section">
                      <div className="head-section-of-payment-delivery-address">
                        <span>Delivery Address </span>

                        <DeliveryAddress
                          checkradius={true}
                          setDeliveryAddress={setDeliveryAddress}
                        />
                      </div>

                      <div className="middle-section-of-payment-delivery-address">
                        {isGuest !== 'yes' ? (
                          <p>
                            Please add new or select delivery address. Only
                            addresses within the delivery radius can be
                            selected.
                          </p>
                        ) : (
                          ''
                        )}
                        {/* <FormControlLabel
                        label="Delivery address is same as Billing Address."
                        control={
                          <Checkbox
                            checked={
                              deliverySameBilling &&
                              deliverySameBilling?.inRange
                            }
                            onChange={handleChangeBillingAddress}
                          />
                        }
                      /> */}
                        {deliverySameBilling?.inRange === false ? (
                          <span className="billingaddrss-error-on-payment">
                            Billing Address is out of delivery radius. Update
                            the billing address or select some another address
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div
                        className="row"
                        style={
                          {
                            // width: deliveryData.length == 1 ? '100%' : 'unset',
                          }
                        }
                        // container
                        // className="bottom-section-of-payment-delivery-address"
                      >
                        {isGuest == 'yes'
                          ? // deliveryGuestAddress !== undefined && Array.isArray(deliveryGuestAddress) ? (
                            //   deliveryGuestAddress.map((result, index) => (

                            //     <div key={index} className='payment-delivery-single-delivery-address' style={
                            //         { border: '1px solid #0A64F9' }

                            //     }  onClick={()=>{  handleSelectAddress(result) }}  >
                            //        <div className='top-payment-delivery-address'>
                            //         <img src={MapIcon} alt="quickvee-delivery-address" />
                            //         {/* <img src={EditIcon} alt="quickvee-delivery-address" /> */}
                            //         <EditDeliveryAddress address={result} checkradius={true}  />

                            //       </div>
                            //     <div key={index} className='payment-delivery-single-delivery-address'>
                            //       <div className='middle-payment-delivery-address'>
                            //         <span>{result.cc_f_name ? result.cc_f_name : ''} {result.cc_l_name ? result.cc_l_name : ''}</span>
                            //         <p>{result.cc_street_address ? result.cc_street_address + ',' : ''} {result.suite_address ? result.suite_address + ',' : ''}
                            //         {result.city ? result.city + ',' : ''} {result.state ? result.state + ',' : ''} {result.cc_zip_code ? result.cc_zip_code + ',' : ''}</p>
                            //         {/* <span>Miles {Number(item.miles).toFixed(2)} </span> */}
                            //       </div>
                            //     </div>
                            //     </div>
                            //   )))
                            //   :''
                            // =================================================================
                            // deliveryGuestAddress !== undefined &&
                            // Array.isArray(deliveryGuestAddress) ? (
                            //   // <div className="row" style={{ width: '100%' }}>
                            //     (deliveryGuestAddress.length > 0
                            //       ? deliveryGuestAddress
                            //       : !Array.isArray(existingData) &&
                            //         existingData !== '' &&
                            //         existingData !== null
                            //       ? [existingData]
                            //       : []
                            //     ).map((result, index) => (
                            //       <div
                            //         className="col-sm-6 col-md-6"
                            //         style={{ padding: '11px' }}
                            //       >
                            //         <div
                            //           item
                            //           // md={6}
                            //           // key={index}
                            //           className="payment-delivery-single-delivery-address cursor-pointer"
                            //           // style={{ border: '1px solid #0A64F9' }}
                            //           style={
                            //             deliveryAddress?.address?.id === result.id
                            //               ? {
                            //                   border: '1px solid #0A64F9',
                            //                   boxShadow: '0 4px 8px #88b3fb',
                            //                 }
                            //               : { border: '1px solid #D3D3D3' }
                            //           }
                            //           onClick={() => handleSelectAddress(result)}
                            //         >
                            //           <div className="top-payment-delivery-address">
                            //             <img
                            //               src={MapIcon}
                            //               alt="quickvee-delivery-address"
                            //             />
                            //             {/* <img src={EditIcon} alt="quickvee-delivery-address" /> */}

                            //             <EditDeliveryAddress
                            //               address={result}
                            //               checkradius={true}
                            //             />
                            //           </div>
                            //           <div
                            //             key={index}
                            //             className="payment-delivery-single-delivery-address"
                            //           >
                            //             <div className="middle-payment-delivery-address">
                            //               <span>
                            //                 {result.cc_f_name ? result.cc_f_name : ''}{' '}
                            //                 {result.cc_l_name ? result.cc_l_name : ''}
                            //               </span>
                            //               <p>
                            //                 {result.cc_street_address
                            //                   ? result.cc_street_address + ','
                            //                   : ''}{' '}
                            //                 {result.suite_address
                            //                   ? result.suite_address + ','
                            //                   : ''}
                            //                 {result.city ? result.city + ',' : ''}{' '}
                            //                 {result.state ? result.state + ',' : ''}{' '}
                            //                 {result.cc_zip_code
                            //                   ? result.cc_zip_code
                            //                   : ''}
                            //               </p>
                            //               {/* <span>Miles {Number(item.miles).toFixed(2)} </span> */}
                            //             </div>
                            //           </div>
                            //         </div>
                            //       </div>
                            //     ))
                            //   // </div>
                            // ) : (
                            //   ''
                            // )

                            deliveryGuestAddress !== undefined &&
                            Array.isArray(deliveryGuestAddress)
                            ? (deliveryGuestAddress.length > 0
                                ? deliveryGuestAddress
                                : !Array.isArray(existingData) &&
                                  existingData !== '' &&
                                  existingData !== null
                                ? [existingData]
                                : []
                              ).map((result, index) => (
                                <>
                                  <DeliveryAddressCard
                                    item={result?.address}
                                    index={index}
                                    deliveryAddress={deliveryAddress}
                                    handleSelectAddress={handleSelectAddress}
                                    setDeliveryAddress={setDeliveryAddress}
                                    handleDeleteAddress={handleDeleteAddress}
                                    guest={deliveryGuestAddress != undefined}
                                    length={deliveryGuestAddress.length}
                                  />
                                </>
                              ))
                            : ''
                          : deliveryData &&
                            deliveryData !== null &&
                            deliveryData.length >= 1
                          ? deliveryData.map((item, index) => (
                              <DeliveryAddressCard
                                item={item}
                                index={index}
                                deliveryAddress={deliveryAddress}
                                handleSelectAddress={handleSelectAddress}
                                setDeliveryAddress={setDeliveryAddress}
                                handleDeleteAddress={handleDeleteAddress}
                                length={deliveryData.length}
                              />
                            ))
                          : ''}
                      </div>
                      <hr
                        style={{
                          background: '#D3D3D3',
                          borderWidth: '0px',
                          margin: '20px 0',
                          height: '1px',
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  <RadioGroup
                    value={paymentType}
                    onChange={(e) => {
                      handlePyamentMethod(e);
                    }}
                  >
                    <div className="payment-delivery-payment-info-section">
                      {storeData?.store_option?.cc_payment === '1' ||
                      storeData?.store_option?.cc_payment === '2' ? (
                        <>
                          <span>Payment</span>
                          <p>
                            We prioritize data safety.
                            {/* Your cards are securely saved
                      here checkout. */}
                          </p>
                          {isGuest !== 'yes' ? (
                            <div className="quickvee-payment-cards-section">
                              {Array.isArray(cardList) &&
                                cardList.map((cardItem, index) => (
                                  <div
                                    onClick={(e) => {
                                      handlePyamentMethod(e, cardItem?.key);
                                      handleSelectedCard(cardItem);
                                      e.stopPropagation();
                                    }}
                                    value={cardItem?.key}
                                    key={index}
                                    className="quickvee-single-payment-card"
                                    style={
                                      paymentType === cardItem?.key
                                        ? {
                                            border: '1px solid #0A64F9',
                                            boxShadow: '0 4px 8px #88b3fb',
                                          }
                                        : { '': '' }
                                    }
                                  >
                                    <div className="single-payment-icon-edit">
                                      <PaymentIcon
                                        type={
                                          cardItem?.card_type.toLowerCase() ===
                                          'master'
                                            ? 'mastercard'
                                            : cardItem?.card_type.toLowerCase()
                                        }
                                        format="flatRounded"
                                        width={50}
                                      />
                                      <DeleteCardDetails
                                        cardItem={cardItem}
                                        authUserData={authUserData}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </div>
                                    <div className="single-payment-detail-select">
                                      <div className="single-payment-detail">
                                        <span>
                                          XXXX XXXX {cardItem?.ccnum4last}
                                        </span>
                                        <p>
                                          <b>Expiry:</b>{' '}
                                          {cardItem?.expires?.replace(
                                            /(\d{2})(\d{2})/,
                                            '$1/$2',
                                          )}{' '}
                                        </p>
                                      </div>
                                      <div className="single-payment-select"></div>
                                      <div className="single-payment-detail-select">
                                        <div className="single-payment-select"></div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            ''
                          )}

                          <div className="quickvee-new-payment-card">
                            <FormControlLabel
                              control={<Radio />}
                              onChange={(e) => handlePyamentMethod(e)}
                              label="New Debit/Credit Card"
                              className="payment-radio-heading"
                              value="newdebitcard"
                            />

                            <div
                              className={`quickvee-new-add-card-detail ${addCardClass}`}
                              id="addnewpayment"
                            >
                              <div className="row">
                                <div
                                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6"
                                  style={{
                                    position: 'relative',
                                    marginBottom: '24px',
                                  }}
                                >
                                  <div>
                                    <FormControl fullWidth>
                                      <TextField
                                        label="Card Number"
                                        className="input-field"
                                        id="outlined-size-small"
                                        variant="outlined"
                                        autoComplete="off"
                                        name="cardnumber"
                                        inputProps={{ maxLength: 19 }}
                                        onChange={(e) =>
                                          handleCardInputChange(e)
                                        }
                                        value={formatCreditCardNumber(
                                          cardDetail.cardnumber,
                                        )}
                                      />
                                    </FormControl>
                                  </div>
                                  <span className="input-error">
                                    {cardDetail.errors.cardnumber}
                                  </span>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
                                  <div className="row">
                                    <div
                                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                                      style={{
                                        position: 'relative',
                                        marginBottom: '24px',
                                      }}
                                    >
                                      <div>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DatePicker
                                            label="Expiry Date"
                                            slotProps={{
                                              textField: {
                                                variant: 'outlined',
                                              },
                                            }}
                                            // className="DatePicker-Debit-creadit-card"
                                            className="custom-date-picker-width"
                                            views={['year', 'month']}
                                            name="expiredate"
                                            value={cardDetail.expiredate}
                                            onChange={(newDate) =>
                                              handleExpireDate(newDate)
                                            }
                                            disablePast
                                            format="MM/YYYY" // Setting the format to MM/YYYY
                                          />
                                        </LocalizationProvider>
                                      </div>
                                      <span className="input-error">
                                        {cardDetail.errors.expiredate}
                                      </span>
                                    </div>
                                    <div
                                      className="col-6 col-xs-6 col-sm-6 col-md-6"
                                      style={{
                                        position: 'relative',
                                        marginBottom: '24px',
                                      }}
                                    >
                                      <div>
                                        <FormControl fullWidth className="cvv">
                                          <TextField
                                            label="CVV"
                                            className="input-field"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            autoComplete="off"
                                            name="cvv"
                                            inputProps={{ maxLength: 4 }}
                                            onChange={(e) =>
                                              handleCardInputChange(e)
                                            }
                                            value={cardDetail.cvv}
                                          />
                                        </FormControl>
                                      </div>
                                      <span className="input-error cvv-input">
                                        {cardDetail.errors.cvv}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {isGuest !== 'yes' ? (
                                <div className="row">
                                  <div className="col-xs-12 col-md-12">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="saveFuture"
                                          onChange={(e) => {
                                            handleCardInputChange(e);
                                          }}
                                        />
                                      }
                                      label="Save Card for future use"
                                      className="payment-card-detail-save-checkbox"
                                    />
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <form className="modal-forms payment-card-details-add-section">
                          <FormControl>
                            <div className="payment-card-details-add-section-single-item payment-card-details-card-num ">
                              <TextField
                                label="Card Number"
                                className="modal-form-single-input"
                                id="outlined-size-small"
                                variant="filled"
                                size="small"
                                autoComplete="off"
                                name="cardnumber"
                                inputProps={{ maxLength: 19 }}
                                onChange={(e) => handleCardInputChange(e)}
                                value={formatCreditCardNumber(
                                  cardDetail.cardnumber,
                                )}
                              />
                            </div>
                            <span className="input-error">
                              {cardDetail.errors.cardnumber}
                            </span>
                          </FormControl>
                          <div className="payment-card-detail-single-item-date-cvv">
                            <div className="" style={{ position: 'relative' }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                  label="Expiry Date"
                                  slotProps={{
                                    textField: { variant: 'filled' },
                                  }}
                                  className="DatePicker-Debit-creadit-card"
                                  views={['year', 'month']}
                                  name="expiredate"
                                  value={cardDetail.expiredate}
                                  onChange={(newDate) =>
                                    handleExpireDate(newDate)
                                  }
                                  disablePast
                                  format="MM/YYYY" // Setting the format to MM/YYYY
                                />
                                <span className="input-error card-expiry-date">
                                  {cardDetail.errors.expiredate}
                                </span>
                              </LocalizationProvider>
                            </div>

                            <FormControl className="cvv">
                              <div className="payment-card-details-add-section-single-item payment-card-details-cvv">
                                <TextField
                                  label="CVV"
                                  className="modal-form-single-input"
                                  id="outlined-size-small"
                                  variant="filled"
                                  size="small"
                                  autoComplete="off"
                                  name="cvv"
                                  inputProps={{ maxLength: 4 }}
                                  onChange={(e) => handleCardInputChange(e)}
                                  value={cardDetail.cvv}
                                />
                              </div>
                              <span className="input-error cvv-input">
                                {cardDetail.errors.cvv}
                              </span>
                            </FormControl>
                          </div>
                          
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="saveFuture"
                                onChange={(e) => {
                                  handleCardInputChange(e);
                                }}
                              />
                            }
                            label="Save Card for future use"
                            className="payment-card-detail-save-checkbox"
                          />
                        </form> */}
                            </div>
                          </div>
                          {/* <hr style={{ background: "#D3D3D3", borderWidth: "0px", margin: "20px 0", height: "1px", width: "100%" }} /> */}
                        </>
                      ) : (
                        ''
                      )}

                      {storeData &&
                        storeData.store_option &&
                        storeData.store_option.cc_payment &&
                        storeData.store_option.cc_payment !== '1' &&
                        ((storeData.store_option?.cash_payment_delivery ==
                          '1' &&
                          order_method == 'delivery') ||
                          (storeData.store_option?.cash_payment_pickup == '1' &&
                            order_method == 'pickup')) && (
                          <div className="quickvee-payment-cash-section">
                            <FormControlLabel
                              value="newcash"
                              onChange={(e) => {
                                handlePyamentMethod(e);
                              }}
                              control={<Radio />}
                              label="Cash"
                              className="payment-radio-heading"
                            />
                          </div>
                        )}
                    </div>
                  </RadioGroup>
                </div>
              </div>

              <div className="payment-stepper-checkout-item">
                <CouponTipSection
                  setVisible={setVisible}
                  visible={visible}
                  userData={isAuthenticated}
                  selectedDeliveryAdderess={deliveryAddress}
                  paymentType={paymentType}
                  paymentDPdata={paymentDPdata}
                  setCardDetail={setCardDetail}
                  cardDetail={cardDetail}
                  ValidateDeliveryDate={handleCheckDeliveryLater}
                  loading={loading}
                  setLoading={setLoading}
                  setPlacingOrderLoader={setPlacingOrderLoader}
                />
              </div>
            </>
          </div>
          {/* placingOrderLoader */}
          {placingOrderLoader && (
            <div className="hide-scroll" style={setPositionLoader}>
              <Loaderfile />
              <p className="payment-error-msg text-center">
                Payment in progress, please wait and avoid
                <br /> refreshing or leaving this page.
              </p>
            </div>
          )}
          {/* placingOrderLoader: {JSON.stringify(placingOrderLoader)} */}
        </>
      )}
    </>
  );
};

export default DeliveryPayment;
