import { useState, useRef } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
// import { useNavigate } from "react-router-dom";
import {
  BASE_URL_SANDBOX,
  FORGET_CUSTOMER_PASSWORD,
} from '../../../Constants/Config';

const ForgotPasswordFormLogic = () => {
  // const Navigate = useNavigate()
  const [submitmessage, setsubmitmessage] = useState('');
  const [alertStatus, setAlertStatus] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { validateEmail } = Validation();

  const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const [values, setValues] = useState({
    username: '',
    errors: {
      username: '',
    },
    // cursorposition : {
    //   username: 0,
    // }
  });
  const handleCustomerForgotPassInput = async (event) => {
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;

    const newCursorPosition = event.target.selectionStart;
    const fieldValue = event.target.value;

    if (fieldName === 'username') {
      // cursorposition.username = newCursorPosition;
      // await validateEmail(fieldValue, errors)
      if (fieldName === '') {
        errors.username = 'Please enter email';
      } else if (emoji.test(fieldValue)) {
        errors.username = 'Emoji not allowed';
      } else if (!EmailReg.test(fieldValue)) {
        errors.username = 'Please enter valid email';
      } else {
        errors.username = '';
      }
    }

    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  const handleCustomerForgotPassword = async (e) => {
    e.preventDefault();
    let { errors } = values;
    await validateEmail(values.username, errors);

    if (errors.username === '') {
      const data = {
        email: values.username.toLowerCase(),
      };
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + FORGET_CUSTOMER_PASSWORD,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        // console.log(response);
        if (response.data.status === false) {
          setsubmitmessage(response.data.message);
          setOpenAlert(true);
        } else {
          setsubmitmessage(response.data.message);
          setAlertStatus(response.data.status);
          setOpenAlert(true);
        }
      } catch (error) {
        return new Error(error);
      }

      // Navigate("/changePassword");
    }
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  return {
    handleCustomerForgotPassInput,
    values,
    handleCustomerForgotPassword,
    setsubmitmessage,
    submitmessage,
    openAlert,
    setOpenAlert,
    setAlertStatus,
    alertStatus,
  };
};

export default ForgotPasswordFormLogic;
