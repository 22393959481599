import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import {  BASE_URL_SANDBOX , GET_SINGLE_STORE_PRODUCT_DATA } from "../../../Constants/Config"

const initialState = {
    loading: false,
    categoryData: {},
    error: '',
}


// Generate pening , fulfilled and rejected action type
export const fetchSingleCategoryData = createAsyncThunk('singleCategory/fetchSingleCategoryData', async (data) => {
    const response = await axios.post( BASE_URL_SANDBOX + GET_SINGLE_STORE_PRODUCT_DATA, data, { headers: { "Content-Type": "multipart/form-data" } })
    if (response.data.status === 200) {
        return response.data.result
    }
})

const singleCategorySlice = createSlice({
    name: 'singleCategory',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSingleCategoryData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchSingleCategoryData.fulfilled, (state, action) => {
            state.loading = false;
            state.categoryData = action.payload;
            state.error = '';
        })
        builder.addCase(fetchSingleCategoryData.rejected, (state, action) => {
            state.loading = false;
            state.categoryData = {};
            state.error = action.error.message;
        })
    }
})


export default singleCategorySlice.reducer